enum PlatformFilterOperator {
  EQUALS = "$eq",
  NOT_EQUAL = "$ne",
  LESS_THAN = "$lt",
  GREATER_THAN = "$gt",
  LESS_THAN_OR_EQUAL = "$lte",
  GREATER_THAN_OR_EQUAL = "$gte",
  CONTAINS = "$in",
  NOT_CONTAINS = "$nin",
  STARTS_WITH = "$sw",
  DOES_NOT_START_WITH = "$nsw",
  ENDS_WITH = "$ew",
  DOES_NOT_END_WITH = "$new",
  GROUP_IN_ALL = "$all",
  GROUP_IN_ANY = "$any",
  INTERVAL = "$interval",
}

export default PlatformFilterOperator;
