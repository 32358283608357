import { notification } from "antd";
import { defineMessages } from "react-intl";
import { put, select, takeEvery } from "redux-saga/effects";

import SortOrder from "@mapmycustomers/shared/enum/SortOrder";
import {
  EntitySupportingRecordRestoration,
  EntityType,
  EntityTypesSupportingRestoration,
} from "@mapmycustomers/shared/types/entity";
import Organization from "@mapmycustomers/shared/types/Organization";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";

import getSuccessNotificationNode from "@app/component/createEditEntity/util/getSuccessNotificationNode";
import i18nService from "@app/config/I18nService";
import { ApiMethodName } from "@app/store/api/ApiService";
import { callApi } from "@app/store/api/callApi";
import { handleError } from "@app/store/errors/actions";
import { getOrganizationId } from "@app/store/iam";
import { DEFAULT_PAGE_SIZE } from "@app/util/consts";

import { fetchDeletedEntities, restoreRecords } from "./actions";

const fetchMethodByEntityType: Record<EntityTypesSupportingRestoration, ApiMethodName> = {
  [EntityType.ACTIVITY]: "fetchActivities",
  [EntityType.COMPANY]: "fetchCompanies",
  [EntityType.DEAL]: "fetchDeals",
  [EntityType.PERSON]: "fetchPeople",
};

const messages = defineMessages({
  restoreSuccessMessage: {
    id: "settings.restorableRecords.restore.success.message",
    defaultMessage: "{count, plural, one {Record} other {Records}} successfully restored",
    description: "Records restore success message",
  },
});

export function* onFetchDeletedEntities({
  payload,
}: ReturnType<typeof fetchDeletedEntities.request>) {
  try {
    const { entityType, page, query, sortFieldName, sortOrder } = payload;
    const organizationId: Organization["id"] = yield select(getOrganizationId);
    const response: ListResponse<EntitySupportingRecordRestoration> = yield callApi(
      fetchMethodByEntityType[entityType],
      organizationId,
      {
        $filters: {
          $and: [{ name: { $in: query } }],
          includeDeletedOnly: true,
        },
        $limit: DEFAULT_PAGE_SIZE,
        $offset: (page - 1) * DEFAULT_PAGE_SIZE,
        $order: `${sortOrder === SortOrder.DESC ? "-" : ""}${sortFieldName}`,
      }
    );
    yield put(fetchDeletedEntities.success({ entities: response.data, total: response.total }));
  } catch (error) {
    yield put(fetchDeletedEntities.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onRestoreRecords({ payload }: ReturnType<typeof restoreRecords.request>) {
  try {
    const { entityType, ids } = payload;
    const organizationId: Organization["id"] = yield select(getOrganizationId);
    yield callApi("restoreRecords", organizationId, entityType, ids);
    notification.success({
      message: getSuccessNotificationNode(
        i18nService.getIntl(),
        i18nService.formatMessage(messages.restoreSuccessMessage, "Records restored successfully", {
          count: ids.length,
        })
      ),
    });
    yield put(restoreRecords.success());
    yield put(
      fetchDeletedEntities.request({
        entityType,
        page: 1,
        sortFieldName: "name",
        sortOrder: SortOrder.ASC,
      })
    );
  } catch (error) {
    yield put(restoreRecords.failure(error));
    yield put(handleError({ error }));
  }
}

export function* restorableRecordsSaga() {
  yield takeEvery(fetchDeletedEntities.request, onFetchDeletedEntities);
  yield takeEvery(restoreRecords.request, onRestoreRecords);
}
