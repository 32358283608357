import React from "react";

import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import { IFilterInstance } from "@mapmycustomers/shared/types/fieldModel/IFilterConfig";
import { SimpleCondition } from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";

import countryListOptions from "@app/util/country-options.json";

import getHumanReadableDescriptionForOptionsFilter, {
  OptionFilterOperator,
} from "../getHumanReadableDescriptionForOptionsFilter";

import CountryFilterComponent, { CountryFilterProps } from "./CountryFilter";

export const COUNTRY_FILTER_OPERATORS = [FilterOperator.IN_ANY, FilterOperator.NONE_OF];

const doesSupportValue = (value: any, operator: FilterOperator) =>
  Array.isArray(value) &&
  value.every((item) => typeof item === "string") &&
  COUNTRY_FILTER_OPERATORS.includes(operator);

const CountryFilter: IFilterInstance = {
  doesSupportValue,
  getComponent: (): React.FC<CountryFilterProps> => CountryFilterComponent,
  getHumanReadableDescription: (value: SimpleCondition, field: IField) => {
    if (!doesSupportValue(value.value, value.operator)) {
      return undefined;
    }

    const selectedValues = new Set(value.value as string[]);
    return getHumanReadableDescriptionForOptionsFilter(
      field,
      value.operator as OptionFilterOperator,
      countryListOptions.filter(({ value }) => selectedValues.has(value)).map(({ label }) => label)
    );
  },
};

export default CountryFilter;
