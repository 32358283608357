import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import SortOrder from "@mapmycustomers/shared/enum/SortOrder";
import { AnyEntity, EntityType } from "@mapmycustomers/shared/types/entity";
import Organization from "@mapmycustomers/shared/types/Organization";
import FilterModel from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";

import localSettings from "@app/config/LocalSettings";
import {
  getRecordsPastDueDrillDownConfiguration,
  getRecordsPastDueDrillDownTotalFilteredRecords,
  getRecordsPastDueDrillDownViewState,
} from "@app/scene/dashboard/store/cards/recordsPastDue/selectors";
import DrillDownViewState from "@app/scene/dashboard/types/DrillDownViewState";
import { ApiMethodName } from "@app/store/api/ApiService";
import { callApi } from "@app/store/api/callApi";
import { fetchRecordsPastDueCardDataHelper } from "@app/store/dashboard/cardDataFetchHelpers";
import { handleError } from "@app/store/errors/actions";
import { exportEntities } from "@app/store/exportEntities/actions";
import { getOrganizationId } from "@app/store/iam";
import EntityTypesSupportedInRecordsPastDue from "@app/types/dashboard/cards/recordsPastDue/EntityTypesSupportedInRecordsPastDue";
import RecordsPastDueCardConfiguration from "@app/types/dashboard/cards/recordsPastDue/RecordsPastDueCardConfiguration";
import { CompanyFieldName } from "@app/util/fieldModel/CompanyFieldModel";
import { DealFieldName } from "@app/util/fieldModel/DealFieldModel";
import getFieldModelByEntityType from "@app/util/fieldModel/getByEntityType";
import FieldModel from "@app/util/fieldModel/impl/FieldModel";
import { PersonFieldName } from "@app/util/fieldModel/PersonFieldModel";
import loggingService from "@app/util/logging";
import { convertFromPlatformFilterModel } from "@app/util/viewModel/convertFromPlatformFilterModel";
import { convertToPlatformSortModel } from "@app/util/viewModel/convertSort";
import { convertToPlatformFilterModel } from "@app/util/viewModel/convertToPlatformFilterModel";

import getDefaultDrillDownColumns from "../../utils/getDefaultDrillDownColumns";

import {
  applyRecordsPastDueDrillDownListViewSettings,
  exportRecordsPastDueCardDrillDownData,
  fetchRecordsPastDueCardData,
  fetchRecordsPastDueCardDrillDownData,
  showRecordsPastDueDrillDown,
} from "./actions";

const defaultColumns: Record<EntityTypesSupportedInRecordsPastDue, string[]> = {
  [EntityType.COMPANY]: [
    CompanyFieldName.NAME,
    CompanyFieldName.USER,
    CompanyFieldName.ADDRESS,
    CompanyFieldName.CREATED_AT,
    CompanyFieldName.SOURCE,
    CompanyFieldName.FREQUENCY_INTERVAL,
    CompanyFieldName.FREQUENCY_STATUS,
  ],
  [EntityType.DEAL]: [
    DealFieldName.NAME,
    DealFieldName.USER,
    DealFieldName.ADDRESS,
    DealFieldName.CREATED_AT,
    DealFieldName.SOURCE,
    DealFieldName.FREQUENCY_INTERVAL,
    DealFieldName.FREQUENCY_STATUS,
  ],
  [EntityType.PERSON]: [
    PersonFieldName.NAME,
    PersonFieldName.USER,
    PersonFieldName.ADDRESS,
    PersonFieldName.CREATED_AT,
    PersonFieldName.SOURCE,
    PersonFieldName.FREQUENCY_INTERVAL,
    PersonFieldName.FREQUENCY_STATUS,
  ],
};

const defaultSortColumn: Record<EntityTypesSupportedInRecordsPastDue, string> = {
  [EntityType.COMPANY]: CompanyFieldName.CREATED_AT,
  [EntityType.DEAL]: DealFieldName.CREATED_AT,
  [EntityType.PERSON]: PersonFieldName.CREATED_AT,
};

const fetchMethodByEntityType: Record<EntityTypesSupportedInRecordsPastDue, ApiMethodName> = {
  [EntityType.COMPANY]: "fetchCompanies",
  [EntityType.DEAL]: "fetchDeals",
  [EntityType.PERSON]: "fetchPeople",
};

function* onFetchRecordsPastDueCardData({
  payload,
}: ReturnType<typeof fetchRecordsPastDueCardData>) {
  const { callback, configuration, failureCallback, scope } = payload;
  try {
    yield call(fetchRecordsPastDueCardDataHelper, { callback, configuration, scope });
  } catch (error) {
    yield put(handleError({ error }));
    if (failureCallback) {
      yield call(failureCallback);
    }
  }
}

function* onOpenDrillDown({ payload }: ReturnType<typeof showRecordsPastDueDrillDown.request>) {
  const { configuration, scope } = payload;
  const { criteria, extraFilters } = configuration;

  const drillDownViewState: DrillDownViewState = yield select(getRecordsPastDueDrillDownViewState);
  const entityType = criteria.entityType;
  const fieldModel = getFieldModelByEntityType(entityType);
  const viewState = localSettings.getViewSettings(
    `dashboard/recordsPastDue/${entityType}`,
    fieldModel,
    {
      ...drillDownViewState,
      columns: getDefaultDrillDownColumns(defaultColumns[entityType], fieldModel),
      sort: [
        {
          field: fieldModel.getByName(defaultSortColumn[entityType])!,
          order: SortOrder.DESC,
        },
      ],
    }
  );

  const isTeamScope = !!scope?.teamId && !scope?.userId;

  const filter: FilterModel = {
    ...convertFromPlatformFilterModel(extraFilters ?? {}, fieldModel),
    cadenceDaysOut: {
      operator: FilterOperator.IS_OVERDUE,
      value: { operator: FilterOperator.IS_OVERDUE, value: undefined },
    },
  };
  if (isTeamScope) {
    filter.team = { operator: FilterOperator.IN_ANY, value: [scope.teamId!] };
  }
  if (scope?.userId) {
    filter.user = { operator: FilterOperator.IN_ANY, value: [scope.userId] };
  }
  if (criteria.sources.length) {
    filter.sourceCreated = { operator: FilterOperator.IN_ANY, value: criteria.sources };
  }
  viewState.filter = filter;

  yield put(showRecordsPastDueDrillDown.success({ viewState }));
}

export function* onFetchDrillDownData({
  payload,
}: ReturnType<typeof fetchRecordsPastDueCardDrillDownData.request>) {
  try {
    loggingService.debug("Dashboard: new record card, onFetchDrillDownData", payload);
    if (!payload.updateOnly) {
      // We do not listen to filter returned by AgGrid from PlatformDataSource
      delete payload.request.filter;
    }

    if (!payload.fetchOnlyWithoutFilters) {
      yield put(applyRecordsPastDueDrillDownListViewSettings(payload.request));
    }

    const configuration: RecordsPastDueCardConfiguration = yield select(
      getRecordsPastDueDrillDownConfiguration
    );
    const drillDownViewState: DrillDownViewState = yield select(
      getRecordsPastDueDrillDownViewState
    );

    if (!payload.fetchOnlyWithoutFilters) {
      localSettings.setViewSettings(
        drillDownViewState,
        `dashboard/recordsPastDue/${configuration.criteria.entityType}`
      );
    }

    if (payload.updateOnly) {
      return;
    }

    const $offset =
      payload.fetchOnlyWithoutFilters && payload.request.range
        ? payload.request.range.startRow
        : drillDownViewState.range.startRow;
    const $limit =
      payload.fetchOnlyWithoutFilters && payload.request.range
        ? payload.request.range.endRow - payload.request.range.startRow
        : drillDownViewState.range.endRow - drillDownViewState.range.startRow;

    const orgId: Organization["id"] = yield select(getOrganizationId);
    const fieldModel: FieldModel = yield call(
      getFieldModelByEntityType,
      configuration.criteria.entityType
    );
    const requestPayload = {
      $filters: {
        includeAccessStatus: true,
        ...convertToPlatformFilterModel(
          payload.fetchOnlyWithoutFilters ? {} : drillDownViewState.filter,
          drillDownViewState.columns,
          fieldModel,
          true,
          drillDownViewState.viewAs
        ),
      },
      $limit,
      $offset,
      $order: convertToPlatformSortModel(drillDownViewState.sort),
    };

    const fetchMethod = fetchMethodByEntityType[configuration.criteria.entityType];
    const response: ListResponse<AnyEntity> = yield callApi(fetchMethod, orgId, requestPayload);
    if (payload.dataCallback) {
      yield call(payload.dataCallback, response);
    }
    yield put(
      fetchRecordsPastDueCardDrillDownData.success({
        totalFilteredRecords: response.total,
        totalRecords: response.accessible,
      })
    );
  } catch (error) {
    payload.failCallback?.();
    yield put(fetchRecordsPastDueCardDrillDownData.failure(error));
    yield put(handleError({ error }));
  }
}

function* onExport() {
  const configuration: RecordsPastDueCardConfiguration = yield select(
    getRecordsPastDueDrillDownConfiguration
  );
  const drillDownViewState: DrillDownViewState = yield select(getRecordsPastDueDrillDownViewState);
  const total: number = yield select(getRecordsPastDueDrillDownTotalFilteredRecords);

  yield put(
    exportEntities.request({
      entityType: configuration.criteria.entityType,
      total,
      viewState: drillDownViewState,
    })
  );
}

export function* recordsPastDueSagas() {
  // we use takeEvery because there might be several cards of such type on board
  // however, it would be more optimal to use takeLatest, but also filter by card ids
  yield takeEvery(fetchRecordsPastDueCardData, onFetchRecordsPastDueCardData);
  yield takeLatest(showRecordsPastDueDrillDown.request, onOpenDrillDown);
  yield takeLatest(fetchRecordsPastDueCardDrillDownData.request, onFetchDrillDownData);
  yield takeLatest(exportRecordsPastDueCardDrillDownData, onExport);
}
