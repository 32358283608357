import { defineMessages, IntlShape } from "react-intl";

import ClientType from "@mapmycustomers/shared/enum/ClientType";

const clientTypeMessages = defineMessages({
  [ClientType.IMPORTS]: {
    id: "clientType.imports",
    defaultMessage: "Imports",
    description: "Record updated created clientType imports option",
  },
  [ClientType.INTEGRATIONS]: {
    id: "clientType.integrations",
    defaultMessage: "Integrations",
    description: "Record updated created clientType integrations",
  },
  [ClientType.MOBILE]: {
    id: "clientType.mobile",
    defaultMessage: "Mobile",
    description: "Record updated created clientType mobile",
  },
  [ClientType.OTHERS]: {
    id: "clientType.others",
    defaultMessage: "Others",
    description: "Record updated created clientType other",
  },
  [ClientType.PUBLIC_API]: {
    id: "clientType.publicApi",
    defaultMessage: "Public API",
    description: "Record updated created clientType public api",
  },
  [ClientType.WEB]: {
    id: "clientType.web",
    defaultMessage: "Web",
    description: "Record updated created clientType web",
  },
  [ClientType.WORKFLOWS]: {
    id: "clientType.workflows",
    defaultMessage: "Workflows",
    description: "Record updated created clientType Workflows",
  },
  unknown: {
    id: "clientType.unknown",
    defaultMessage: "Unknown",
    description: "Record updated created clientType Unknown",
  },
});

const getClientTypeDisplayName = (intl: IntlShape, clientType: ClientType) =>
  intl.formatMessage(
    clientType in clientTypeMessages ? clientTypeMessages[clientType] : clientTypeMessages.unknown
  );

export default getClientTypeDisplayName;
