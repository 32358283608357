import { createSelector } from "reselect";

import EntityType from "@mapmycustomers/shared/enum/EntityType";
import Feature from "@mapmycustomers/shared/enum/Feature";
import { MapFilterEntityType } from "@mapmycustomers/shared/types/entity";
import MapViewState from "@mapmycustomers/shared/types/viewModel/MapViewState";

import { getFeatures, getUserMetaData } from "@app/store/iam";
import {
  getAllColorLegendsRaw,
  getAllShapeLegends,
  getDefaultColorKeys,
  getDefaultShapeKeys,
} from "@app/store/pinLegends";
import { getSavedFilters } from "@app/store/savedFilters";
import getFieldModelByEntityType from "@app/util/fieldModel/getByEntityType";
import { MAP_ENTITY_TYPES } from "@app/util/map/consts";
import unmarshallRecordsListViewState from "@app/util/map/unmarshallRecordsListViewState";

import { RootState } from "../rootReducer";

const mapState = (state: RootState) => state.map;

export const getMapViewSettings = createSelector(
  getUserMetaData,
  getDefaultColorKeys,
  getDefaultShapeKeys,
  getAllColorLegendsRaw,
  getAllShapeLegends,
  getFeatures,
  getSavedFilters,
  (
    metaData,
    defaultColorKeys,
    defaultShapeKeys,
    colorPinLegends,
    shapePinLegends,
    features,
    savedFiltersGetter
  ) => {
    // no matter what, but we should set some color & shape keys
    let viewState: Partial<MapViewState> = metaData.mapViewSettings ?? {
      colorKey: defaultColorKeys,
      shapeKey: defaultShapeKeys,
    };

    // Cleanup filters
    // 1. remove fields which don't exist. Here's a thing about custom fields: getViewSettings method
    // MUST NOT be called until custom fields are fetched and added to field models. Typically, we call
    // getViewSettings in initializeApp.success action handler, so we're fine.
    // 2. rename fields when they have a new name. This requirement appeared when we switched custom fields'
    // name field to be esKey instead of id.
    // This is very similar to what is done in getViewSettings function, but for maps.
    if (viewState.filter) {
      (Object.keys(viewState.filter!) as MapFilterEntityType[]).forEach((entityType) => {
        const fieldModel = getFieldModelByEntityType(entityType);
        if (!fieldModel) {
          delete viewState.filter![entityType];
          return;
        }

        Object.keys(viewState.filter![entityType]!).forEach((fieldFilterName) => {
          const field = fieldModel.getByFilterName(fieldFilterName);
          if (!field) {
            delete viewState.filter![entityType]![fieldFilterName];
          } else if (field.filterName !== fieldFilterName) {
            viewState.filter![entityType]![field.filterName] =
              viewState.filter![entityType]![fieldFilterName];
            delete viewState.filter![entityType]![fieldFilterName];
          }
        });
      });
    }

    // Cleanup saved filter
    // Check if selected saved filter still exists
    if (viewState?.selectedSavedFilterId !== undefined) {
      const savedFilters = savedFiltersGetter(EntityType.PIN);
      const savedFilterExists = savedFilters?.some(
        ({ id }) => id === viewState?.selectedSavedFilterId
      );
      if (!savedFilterExists) {
        viewState.selectedSavedFilterId = undefined;
      }
    } else if (!("selectedSavedFilterId" in viewState)) {
      // we explicitly set selectedSavedFilterId to undefined to ensure it
      // will be removed from the viewState when applyMapViewSettings is called
      viewState.selectedSavedFilterId = undefined;
    }

    // Cleanup color & shape keys
    const disallowColor = features?.[Feature.DISALLOW_COLOR]?.enabled;

    // Cloning because
    // a) we'll modify viewState directly in the forEach below
    // b) also, we guarantee that colorKey and shapeKey are defined
    viewState = {
      ...viewState,
      colorKey: { ...(viewState.colorKey ?? {}) },
      shapeKey: { ...(viewState.shapeKey ?? {}) },
    };

    // validate color and shape keys from the viewState. Maybe somebody deleted one?
    // Here we also guarantee that each entity will have some color and shape key assigned to it
    MAP_ENTITY_TYPES.forEach((entityType) => {
      const colorKeyLegend = colorPinLegends.find(
        ({ id }) => id === viewState.colorKey![entityType]
      );
      // use a default color legend if viewState doesn't have or has invalid pin legend id for color
      // also, ensure that viewState doesn't use "Color" pin legend if color is disallowed
      if (!colorKeyLegend || (disallowColor && colorKeyLegend?.fieldName === "color")) {
        viewState.colorKey![entityType] = defaultColorKeys[entityType];
      }
      // use a default shape legend if viewState doesn't have or has invalid pin legend id for shape
      if (!shapePinLegends.some(({ id }) => id === viewState.shapeKey![entityType])) {
        viewState.shapeKey![entityType] = defaultShapeKeys[entityType];
      }
    });

    return viewState;
  }
);

export const getMapRecordsListSettings = createSelector(getUserMetaData, (metaData) => {
  const viewState = metaData.mapRecordsListSettings;
  if (!viewState) {
    return;
  }

  return unmarshallRecordsListViewState(viewState);
});

export const getOfferedFilters = createSelector(mapState, ({ offerFilters }) => offerFilters);
