import React, { FC, useEffect } from "react";
import { connect } from "react-redux";

import Select from "antd/es/select";
import cn from "classnames";
import { useIntl } from "react-intl";

import CountryCode from "@mapmycustomers/shared/enum/CountryCode";
import { IFilterComponentProps } from "@mapmycustomers/shared/types/fieldModel/IFilterConfig";

import defaultFilterOption from "@app/component/input/utils/defaultFilterOption";
import { getUserCountry } from "@app/store/iam";
import { RootState } from "@app/store/rootReducer";
import useCountryListOptions from "@app/util/countries/useCountryListOptions";

import styles from "./CountryFilter.module.scss";

export interface CountryFilterProps extends IFilterComponentProps {
  userCountry?: CountryCode;
}

const CountryFilter: FC<CountryFilterProps> = ({ className, onChange, userCountry, value }) => {
  const intl = useIntl();
  const countryListOptions = useCountryListOptions(intl);

  useEffect(
    () => {
      if (userCountry && !value.value?.length) {
        onChange?.({ ...value, value: [userCountry] });
      }
    },
    // we don't have value and onChange in deps because we don't wanna call this
    // effect when they change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userCountry]
  );

  return (
    <Select<CountryCode[]>
      className={cn(styles.container, className)}
      filterOption={defaultFilterOption}
      mode="multiple"
      onChange={(countries: string[]) => onChange?.({ ...value, value: countries })}
      options={countryListOptions}
      placeholder={intl.formatMessage({
        id: "filters.country.select.placeholder",
        defaultMessage: "Click or type to select countries",
        description: "Placeholder displayed in CountryFilter's select field",
      })}
      value={Array.isArray(value.value) ? value.value : []}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  userCountry: getUserCountry(state),
});

export default connect(mapStateToProps)(CountryFilter);
