import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";

import { faBellSlash } from "@fortawesome/free-solid-svg-icons/faBellSlash";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { faClock } from "@fortawesome/pro-solid-svg-icons/faClock";
import { faTimes } from "@fortawesome/pro-solid-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "antd/es/form";
import { useForm } from "antd/es/form/Form";
import { Col } from "antd/es/grid";
import Popconfirm from "antd/es/popconfirm";
import Row from "antd/es/row";
import Tooltip from "antd/es/tooltip";
import cn from "classnames";
import { useIntl } from "react-intl";

import ActivityVisibility from "@mapmycustomers/shared/enum/activity/ActivityVisibility";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import { EmailCreationPayload } from "@mapmycustomers/shared/types/email/Email";
import { EntityType } from "@mapmycustomers/shared/types/entity";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import {
  EntitiesSupportedByEmailFeature,
  EntityTypesSupportedByEmailFeature,
} from "@mapmycustomers/shared/types/map/types";
import Team from "@mapmycustomers/shared/types/Team";
import User from "@mapmycustomers/shared/types/User";
import { isDefined, isNotEmpty } from "@mapmycustomers/shared/util/assert";
import stringHash from "@mapmycustomers/shared/util/hash/stringHash";
import { AttachedFile, Modal, TextAreaWithMentions } from "@mapmycustomers/ui";

import AssigneeSearch from "@app/component/activity/AssigneeSearch";
import Chip from "@app/component/Chip";
import ConditionalFormField, {
  ConditionalFormFieldRenderProps,
} from "@app/component/ConditionalFormField";
import ActivityCompleted from "@app/component/createEditEntity/Activity/component/ActivityCompleted";
import ActivityNameField from "@app/component/createEditEntity/Activity/component/ActivityNameField";
import LockedActivityType from "@app/component/createEditEntity/Activity/component/LockedFormFields/LockedActivityType";
import LockedAssignee from "@app/component/createEditEntity/Activity/component/LockedFormFields/LockedAssignee";
import LockedEntityType from "@app/component/createEditEntity/Activity/component/LockedFormFields/LockedEntityType";
import { getAssociationsState } from "@app/component/createEditEntity/Activity/store";
import {
  changeAssociatedEntities,
  relateUnrelateEntities,
} from "@app/component/createEditEntity/Activity/store/actions";
import FormValues from "@app/component/createEditEntity/Activity/types/FormValues";
import getActivityPayloadFromCreationForm from "@app/component/createEditEntity/Activity/utils/getActivityPayloadFromCreationForm";
import getFormInitialValues from "@app/component/createEditEntity/Activity/utils/getFormInitialValues";
import { useIsRecurrentValueChangeHandler } from "@app/component/createEditEntity/Activity/utils/useIsRecurrentValueChangeHandler";
import useMentionsValidation from "@app/component/createEditEntity/Activity/utils/useMentionsValidation";
import { useRecurIntervalTypeValueChangeHandler } from "@app/component/createEditEntity/Activity/utils/useRecurIntervalTypeValueChangeHandler";
import FormFields from "@app/component/FormFields";
import {
  CompanyRelationshipField,
  DealRelationshipField,
  PersonRelationshipField,
} from "@app/component/FormFields/components/Associations";
import convertFieldToLayoutSchemaField from "@app/component/FormFields/utils/convertFieldToLayoutSchemaField";
import useSchema from "@app/component/FormFields/utils/useSchema";
import VisibilitySelectField from "@app/component/input/VisibilitySelectField";
import { getActivityTypes } from "@app/store/activity";
import type AssociationsState from "@app/store/associations/AssociationsState";
import { getEmailActivityType, getNewEmailAttachments, isEmailSending } from "@app/store/email";
import { getCurrentUser, getMe, isCurrentUserManager, isCurrentUserOwner } from "@app/store/iam";
import { getTeams, getUsersOfEntireOrganization } from "@app/store/members";
import { RootState } from "@app/store/rootReducer";
import layout from "@app/styles/layout";
import Iam from "@app/types/Iam";
import { getDefaultActivityEmailLayout } from "@app/util/email/getActivityEmailLayouts";
import activityFieldModel, { ActivityFieldName } from "@app/util/fieldModel/ActivityFieldModel";
import getFieldModelByEntityType from "@app/util/fieldModel/getByEntityType";
import { isUniqueCustomField } from "@app/util/fieldModel/impl/assert";
import useActivityVisibilityOptions from "@app/util/hook/useActivityVisibilityOptions";
import useErroredFieldsScroller from "@app/util/hook/useErroredFieldsScroller";
import { activityLayoutModel } from "@app/util/layout/impl";
import { getActivityVisibilityNote } from "@app/util/ui";

import SendAndCreateButton from "../SendAndCreateButton";

import styles from "./ActivityCreationModal.module.scss";
import Files from "./Files";
import messages from "./messages";

const noFilter = () => true;

interface Props {
  activityTypes: ActivityType[];
  associations: AssociationsState;
  currentUser: User;
  emailActivityType?: ActivityType;
  emailAttachments: AttachedFile[];
  emailSending: boolean;
  entityType?: EntityTypesSupportedByEmailFeature;
  isManager: boolean;
  isOwner: boolean;
  me: Iam;
  onBack: () => void;
  onChangeAssociatedEntities: typeof changeAssociatedEntities.request;
  onCreate: (activity: EmailCreationPayload["crmActivity"]) => void;
  onHide: () => void;
  onRelateUnrelateEntities: typeof relateUnrelateEntities.request;
  recipients: EntitiesSupportedByEmailFeature[];
  subject: string;
  teams: Team[];
  users: User[];
  visible: boolean;
}

const ActivityCreationModal: React.FC<Props> = ({
  activityTypes,
  associations,
  currentUser,
  emailActivityType,
  emailAttachments,
  emailSending,
  entityType,
  isManager,
  isOwner,
  me,
  onBack,
  onChangeAssociatedEntities,
  onCreate,
  onHide,
  onRelateUnrelateEntities,
  recipients,
  subject,
  teams,
  users,
  visible,
}) => {
  const intl = useIntl();
  const [form] = useForm<FormValues>();
  const [activityFormLayout] = useState(activityLayoutModel.defaultFormLayout!);

  const handleUserChange = useCallback(
    (user?: User) => {
      onChangeAssociatedEntities({
        assignee: user,
      });
    },
    [onChangeAssociatedEntities]
  );

  const initialValues = getFormInitialValues(
    { completed: true, name: subject },
    currentUser,
    emailActivityType?.id,
    false,
    ActivityVisibility.PRIVATE,
    undefined
  );

  const [formFieldsRef, handleFinishFail] = useErroredFieldsScroller();

  const isRecurrentValueChangeHandler = useIsRecurrentValueChangeHandler(form);
  const recurIntervalTypeValueChangeHandler = useRecurIntervalTypeValueChangeHandler(form);

  const visibilityOptions = useActivityVisibilityOptions(isOwner, isManager);

  const [actualSchema] = useSchema(EntityType.ACTIVITY, activityFormLayout, noFilter, true);

  const hasRequiredUniqueCustomFields = useMemo(
    () =>
      actualSchema.some((schemaField) => {
        const field = activityFieldModel.getByPlatformName(schemaField.field);
        return schemaField.required && field && isUniqueCustomField(field);
      }),
    [actualSchema]
  );

  const uniqueCustomFields = useMemo(
    () =>
      actualSchema.filter((schemaField) => {
        const field = activityFieldModel.getByPlatformName(schemaField.field);
        return field && isUniqueCustomField(field);
      }),
    [actualSchema]
  );

  const handleActivityChange = useCallback(
    (values: FormValues, allValues: FormValues) => {
      if (allValues.endAt && allValues.startAt && allValues.endAt < allValues.startAt) {
        form.setFieldsValue({ endAt: allValues.startAt });
      }
    },
    [form]
  );

  const [, allowedUserEmails, handleAllMentionedUsersHaveAccessToActivity] = useMentionsValidation(
    me,
    users,
    associations.associatedCompany,
    associations.associatedDeal,
    associations.associatedPerson
  );

  const handleIsMentionDisabled = useCallback(
    (user: User) => !allowedUserEmails?.includes(user.username),
    [allowedUserEmails]
  );

  const handleValuesChange = useCallback(
    (changedValues, values) => {
      [
        isRecurrentValueChangeHandler,
        recurIntervalTypeValueChangeHandler,
        handleAllMentionedUsersHaveAccessToActivity,
        handleActivityChange,
      ].forEach((fn) => fn(changedValues, values));
    },
    [
      handleActivityChange,
      handleAllMentionedUsersHaveAccessToActivity,
      isRecurrentValueChangeHandler,
      recurIntervalTypeValueChangeHandler,
    ]
  );

  const handleFormFinish = useCallback(() => {
    const values = form.getFieldsValue();
    const { customFields, ...activity } = getActivityPayloadFromCreationForm(
      { ...values, activityTypeId: emailActivityType?.id, assignee: currentUser },
      activityTypes
    );

    onCreate({
      ...activity,
      ...(Object.values(values.customFields ?? {}).some(isNotEmpty)
        ? {
            customFields: [
              {
                customFieldData:
                  Object.values(values.customFields ?? {})
                    .filter(isDefined)
                    .map((cf) => ({
                      customField: { id: cf.customField.id },
                      value: cf.value,
                    })) ?? [],
              },
            ],
          }
        : {}),
    });
  }, [activityTypes, currentUser, emailActivityType?.id, onCreate, form]);

  const defaultAddedFields = useMemo(() => {
    const result = [];
    if (entityType) {
      const visibleFieldNames = new Set(
        getDefaultActivityEmailLayout()?.schema.map(({ field }) => field) ?? []
      );

      const fieldModel = getFieldModelByEntityType(EntityType.ACTIVITY);
      if (emailAttachments.length > 0 && !visibleFieldNames.has("files")) {
        const field = fieldModel.getByName(ActivityFieldName.FILES);
        if (field) {
          result.push(convertFieldToLayoutSchemaField(field));
        }
      }
      if (entityType === EntityType.COMPANY && !visibleFieldNames.has("accountId")) {
        const field = fieldModel.getByName(ActivityFieldName.COMPANY);
        if (field) {
          result.push(convertFieldToLayoutSchemaField(field));
        }
      }
      if (entityType === EntityType.PERSON && !visibleFieldNames.has("contactId")) {
        const field = fieldModel.getByName(ActivityFieldName.PERSON);
        if (field) {
          result.push(convertFieldToLayoutSchemaField(field));
        }
      }
    }
    return result;
  }, [emailAttachments, entityType]);

  useEffect(() => {
    form.setFieldValue(
      "files",
      emailAttachments.map((attachedFile) => attachedFile.file)
    );
  }, [form, emailAttachments]);

  useEffect(() => {
    uniqueCustomFields.map((field) => {
      form.setFieldValue(["customFields", field.field], undefined);
    });
  }, [form, uniqueCustomFields, recipients]);

  return (
    <Modal
      className={styles.modal}
      closable={false}
      footer={null}
      maskClosable={false}
      onCancel={onHide}
      open={visible}
      title={
        <Row align="middle" justify="space-between">
          <Col>
            <Row align="middle" gutter={layout.spacerM}>
              <Col>
                <Tooltip title={intl.formatMessage(messages.goBack)}>
                  <FontAwesomeIcon
                    className={styles.backLink}
                    icon={faChevronLeft}
                    onClick={onBack}
                  />
                </Tooltip>
              </Col>
              <Col>{intl.formatMessage(messages.title, { count: recipients.length })}</Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col>
                <Chip
                  icon={<FontAwesomeIcon className={styles.icon} icon={faBellSlash} />}
                  type="disabled"
                >
                  {intl.formatMessage(messages.notificationsDisabled)}
                </Chip>
              </Col>
              <Col>
                <Popconfirm
                  cancelText={intl.formatMessage(messages.closeConfirmationNo)}
                  okButtonProps={{ danger: true }}
                  okText={intl.formatMessage(messages.closeConfirmationYes)}
                  onConfirm={onHide}
                  overlayClassName={styles.closeConfirmation}
                  title={intl.formatMessage(messages.closeConfirmationTitle, {
                    b: (text) => <b>{text}</b>,
                  })}
                >
                  <FontAwesomeIcon className={styles.headerIcon} icon={faTimes} />
                </Popconfirm>
              </Col>
            </Row>
          </Col>
        </Row>
      }
      width="40rem"
    >
      <div className={styles.formWrapper} ref={formFieldsRef}>
        <Form<FormValues>
          className={styles.form}
          form={form}
          initialValues={initialValues}
          layout="vertical"
          onFinish={handleFormFinish}
          onFinishFailed={handleFinishFail}
          onValuesChange={handleValuesChange}
          preserve
        >
          <div className={styles.container}>
            <div className={styles.note}>{intl.formatMessage(messages.note)}</div>

            {activityFormLayout && (
              <FormFields
                defaultAddedFields={defaultAddedFields}
                disableUniqueCustomFields={recipients.length > 1}
                entityType={EntityType.ACTIVITY}
                fileComponent={Files}
                layout={activityFormLayout}
              >
                <ConditionalFormField feature={FieldFeature.ACTIVITY_TYPE_FIELD}>
                  {emailActivityType && (
                    <LockedActivityType activityType={emailActivityType} isAutoEmail />
                  )}
                </ConditionalFormField>

                <ConditionalFormField fieldName={ActivityFieldName.ASSIGNEE}>
                  <LockedAssignee assignee={me} />
                </ConditionalFormField>

                <ConditionalFormField fieldName={ActivityFieldName.NAME}>
                  <ActivityNameField
                    activityTypes={activityTypes}
                    title={intl.formatMessage(messages.activityName)}
                  />
                </ConditionalFormField>

                <ConditionalFormField fieldName={ActivityFieldName.ASSIGNEE}>
                  {({ disabled, label, required }: ConditionalFormFieldRenderProps) => (
                    <Form.Item
                      label={label}
                      name="assignee"
                      required={required}
                      rules={[{ required }]}
                      valuePropName="assignee"
                    >
                      <AssigneeSearch
                        allowedUserEmails={allowedUserEmails}
                        disabled={disabled}
                        onChange={handleUserChange}
                        users={users}
                      />
                    </Form.Item>
                  )}
                </ConditionalFormField>
                <ConditionalFormField fieldName={ActivityFieldName.COMPANY}>
                  {entityType === EntityType.COMPANY ? (
                    <LockedEntityType entityType={EntityType.COMPANY} />
                  ) : (
                    ({ disabled, required }: ConditionalFormFieldRenderProps) => (
                      <CompanyRelationshipField
                        allowAdd
                        associatedCompany={associations.associatedCompany}
                        associatedDeal={associations.associatedDeal}
                        associatedPerson={associations.associatedPerson}
                        disabled={disabled}
                        entityType={EntityType.ACTIVITY}
                        field={activityFieldModel.getByName(ActivityFieldName.COMPANY)!}
                        onChangeAssociatedEntities={onChangeAssociatedEntities}
                        relateEntities={onRelateUnrelateEntities}
                        required={required}
                        suggestedCompanies={associations.availableCompanies}
                      />
                    )
                  )}
                </ConditionalFormField>

                <ConditionalFormField fieldName={ActivityFieldName.PERSON}>
                  {entityType === EntityType.PERSON ? (
                    <LockedEntityType entityType={EntityType.PERSON} />
                  ) : (
                    ({ disabled, required }: ConditionalFormFieldRenderProps) => (
                      <PersonRelationshipField
                        allowAdd
                        associatedCompany={associations.associatedCompany}
                        associatedDeal={associations.associatedDeal}
                        associatedPerson={associations.associatedPerson}
                        disabled={disabled}
                        field={activityFieldModel.getByName(ActivityFieldName.PERSON)!}
                        onChangeAssociatedEntities={onChangeAssociatedEntities}
                        relateEntities={onRelateUnrelateEntities}
                        required={required}
                        suggestedPeople={associations.availablePeople}
                      />
                    )
                  )}
                </ConditionalFormField>

                <ConditionalFormField fieldName={ActivityFieldName.DEAL}>
                  {({ disabled, required }: ConditionalFormFieldRenderProps) => (
                    <DealRelationshipField
                      allowAdd
                      associatedCompany={associations.associatedCompany}
                      associatedDeal={associations.associatedDeal}
                      associatedPerson={associations.associatedPerson}
                      disabled={disabled}
                      field={activityFieldModel.getByName(ActivityFieldName.DEAL)!}
                      onChangeAssociatedEntities={onChangeAssociatedEntities}
                      relateEntities={onRelateUnrelateEntities}
                      required={required}
                      suggestedDeals={associations.availableDeals}
                    />
                  )}
                </ConditionalFormField>

                <ConditionalFormField fieldName={ActivityFieldName.NOTE}>
                  {({ disabled, label, required }: ConditionalFormFieldRenderProps) => (
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, curValues) =>
                        prevValues.note !== curValues.note ||
                        prevValues.visibility !== curValues.visibility
                      }
                    >
                      {({ getFieldValue }) => (
                        <Form.Item
                          label={label}
                          name={ActivityFieldName.NOTE}
                          required={required}
                          rules={[{ required }]}
                        >
                          <TextAreaWithMentions
                            allUsers={users}
                            disabled={disabled}
                            emptyTextForWithSkippedUsers={intl.formatMessage({
                              id: "createEditActivityModal.field.note.emptyTextForWithSkippedUsers",
                              defaultMessage:
                                "Only users that have view or edit access to either the association or the activity can be mentioned",
                              description:
                                "Message for the Activity Note mention dropdown when some disabled users are skipped",
                            })}
                            hideDisabledUsers
                            isMentionDisabled={handleIsMentionDisabled}
                            key={
                              getFieldValue(ActivityFieldName.VISIBILITY) +
                              stringHash(allowedUserEmails?.join() ?? "")
                            }
                            maxLength={Number.MAX_SAFE_INTEGER}
                          />
                        </Form.Item>
                      )}
                    </Form.Item>
                  )}
                </ConditionalFormField>

                <ConditionalFormField fieldName={ActivityFieldName.VISIBILITY}>
                  {({ disabled, required }: ConditionalFormFieldRenderProps) => (
                    <Form.Item
                      label={intl.formatMessage({
                        id: "createEditActivityModal.field.visibility",
                        defaultMessage: "Visible to",
                        description: "Visibility field label in Create create edit activity modal",
                      })}
                      required={required}
                      shouldUpdate={(prev, next) =>
                        prev.visibility !== next.visibility || prev.teamIds !== next.teamIds
                      }
                    >
                      {({ getFieldValue, setFieldsValue }) => (
                        <VisibilitySelectField<ActivityVisibility>
                          disabled={disabled}
                          noteGetter={getActivityVisibilityNote}
                          onChange={(visibility: ActivityVisibility) =>
                            setFieldsValue({ visibility })
                          }
                          onTeamIdsChange={(teamIds: Team["id"][]) => setFieldsValue({ teamIds })}
                          options={visibilityOptions}
                          teamIds={getFieldValue("teamIds")}
                          teams={teams}
                          teamsRenderer={(node) => (
                            <Form.Item name={"teamIds"} noStyle>
                              {node}
                            </Form.Item>
                          )}
                          value={getFieldValue(ActivityFieldName.VISIBILITY)}
                          visibilityRenderer={(node) => (
                            <Form.Item name={ActivityFieldName.VISIBILITY} noStyle>
                              {node}
                            </Form.Item>
                          )}
                        />
                      )}
                    </Form.Item>
                  )}
                </ConditionalFormField>
              </FormFields>
            )}
          </div>
          <div className={styles.footer}>
            <div>
              <div className={styles.footerInfo}>
                <div className={styles.clockIcon}>
                  <FontAwesomeIcon icon={faClock} />
                </div>
                <div>
                  <div className={cn(styles.footerInfoText, styles.footerInfoTextDateTime)}>
                    {intl.formatMessage(messages.activityDateTimeDescription)}
                  </div>
                  <div className={styles.footerInfoText}>
                    {intl.formatMessage(messages.doesNotRepeat)}
                  </div>
                </div>
              </div>
              <ActivityCompleted creating disabled form={form} />
            </div>
            <div>
              <SendAndCreateButton
                disabled={emailSending || (hasRequiredUniqueCustomFields && recipients.length > 1)}
                loading={emailSending}
                onClick={form.submit}
                recipientCount={recipients.length}
                tooltipProps={
                  hasRequiredUniqueCustomFields && recipients.length > 1
                    ? {
                        title: intl.formatMessage(messages.uniqueCustomFieldRequiredTooltip),
                        trigger: ["hover"],
                      }
                    : undefined
                }
              />
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => ({
  activityTypes: getActivityTypes(state),
  associations: getAssociationsState(state),
  currentUser: getCurrentUser(state)!,
  emailActivityType: getEmailActivityType(state),
  emailAttachments: getNewEmailAttachments(state),
  emailSending: isEmailSending(state),
  isManager: isCurrentUserManager(state),
  isOwner: isCurrentUserOwner(state),
  me: getMe(state)!,
  teams: getTeams(state),
  users: getUsersOfEntireOrganization(state),
});

const mapDispatchToProps = {
  onChangeAssociatedEntities: changeAssociatedEntities.request,
  onRelateUnrelateEntities: relateUnrelateEntities.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityCreationModal);
