import { createSelector } from "reselect";
import { ActionType, createReducer } from "typesafe-actions";

import RoleEnum from "@mapmycustomers/shared/enum/Role";
import Role from "@mapmycustomers/shared/types/Role";
import Team from "@mapmycustomers/shared/types/Team";
import User from "@mapmycustomers/shared/types/User";

import { deleteProfilePhoto, updateMe, uploadProfilePhoto } from "@app/store/iam/actions";

import { RootState } from "../rootReducer";

import { Actions, fetchRoles, fetchTeams, fetchUsers, updateTeam, updateUser } from "./actions";

export interface MembersState {
  roles: Role[];
  rolesLoading: boolean;
  teams: Team[];
  teamsLoading: boolean;
  users: User[];
  usersLoading: boolean;
}

const initialState: MembersState = {
  roles: [],
  rolesLoading: false,
  teams: [],
  teamsLoading: false,
  users: [],
  usersLoading: false,
};

const members = createReducer<
  MembersState,
  Actions | ActionType<typeof deleteProfilePhoto | typeof updateMe | typeof uploadProfilePhoto>
>(initialState)
  .handleAction(fetchRoles.request, (state) => ({
    ...state,
    roles: [],
    rolesLoading: true,
  }))
  .handleAction(fetchRoles.success, (state, action) => ({
    ...state,
    roles: action.payload,
    rolesLoading: false,
  }))
  .handleAction(fetchRoles.failure, (state) => ({
    ...state,
    rolesLoading: false,
  }))
  .handleAction(fetchTeams.request, (state) => ({
    ...state,
    teamsLoading: true,
  }))
  .handleAction(fetchTeams.success, (state, action) => ({
    ...state,
    teams: action.payload,
    teamsLoading: false,
  }))
  .handleAction(fetchTeams.failure, (state) => ({
    ...state,
    teamsLoading: false,
  }))
  .handleAction(fetchUsers.request, (state) => ({
    ...state,
    users: [],
    usersLoading: true,
  }))
  .handleAction(fetchUsers.success, (state, action) => ({
    ...state,
    users: action.payload,
    usersLoading: false,
  }))
  .handleAction(fetchUsers.failure, (state) => ({
    ...state,
    usersLoading: false,
  }))
  .handleAction(uploadProfilePhoto.success, (state, { payload }) => ({
    ...state,
    users: state.users.map((user) =>
      user.id === payload.id ? { ...user, profilePhoto: payload.profilePhoto } : user
    ),
  }))
  .handleAction(deleteProfilePhoto.success, (state, { payload }) => ({
    ...state,
    users: state.users.map((user) =>
      user.id === payload.id ? { ...user, profilePhoto: undefined } : user
    ),
  }))
  .handleAction(updateMe.success, (state, { payload }) => {
    const { accessRights, addOns, cumulRole, layouts, organization, settings, ...currentUser } =
      payload;

    return {
      ...state,
      users: state.users.map((user) =>
        user.id === payload.id ? { ...user, ...currentUser } : user
      ),
    };
  })
  .handleAction(updateUser.success, (state, { payload }) => ({
    ...state,
    users: state.users.map((user) => (user.id === payload.id ? { ...user, ...payload } : user)),
  }))
  .handleAction(updateTeam.success, (state, { payload }) => ({
    ...state,
    teams: state.teams.map((team) => (team.id === payload.id ? { ...team, ...payload } : team)),
  }));

const membersState = (state: RootState) => state.members;

export const getRoles = createSelector(membersState, ({ roles }) => roles);
export const getRolesExcludingOwners = createSelector(getRoles, (roles) =>
  roles.filter(({ key }) => key !== RoleEnum.OWNER)
);
export const areRolesLoading = createSelector(membersState, ({ rolesLoading }) => rolesLoading);
export const getTeamsOfEntireOrganization = createSelector(membersState, ({ teams }) => teams);
export const getTeams = createSelector(membersState, ({ teams }) =>
  teams.filter(({ viaTeamAccess }) => viaTeamAccess)
);
export const areTeamsLoading = createSelector(membersState, ({ teamsLoading }) => teamsLoading);
export const getUsers = createSelector(membersState, ({ users }) =>
  users.filter(({ viaTeamAccess }) => viaTeamAccess)
);
export const getUsersOfEntireOrganization = createSelector(membersState, ({ users }) => users);
export const areUsersLoading = createSelector(membersState, ({ usersLoading }) => usersLoading);

export type MembersActions = Actions;
export default members;
