export enum RecordSource {
  PUBLIC_API = "public api",
  WEB = "web",
  MOBILE = "mobile",
  IMPORTS = "imports",
  INTEGRATIONS = "integrations",
  WORKFLOWS = "workflows",
  LEAD_GEN = "lead-gen",
  ZAPIER = "zapier",
}

export default RecordSource;
