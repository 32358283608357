import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import {
  IFilterInstance,
  IFilterInstanceConfig,
  IFilterOperatorConfig,
} from "@mapmycustomers/shared/types/fieldModel/IFilterConfig";

import CustomField from "./CustomField";

export const isCustomField = (field: IField): field is CustomField =>
  field.hasFeature(FieldFeature.CUSTOM_FIELD);

export const isCalculatedField = (field: IField): field is CustomField =>
  field.hasFeature(FieldFeature.CALCULATED_FIELD);

export const isUniqueCustomField = (field: IField): field is CustomField =>
  isCustomField(field) && field.isUniqueField;

export const isFilterInstanceConfig = (
  value: IFilterInstance | IFilterInstanceConfig
): value is IFilterInstanceConfig => typeof value === "object" && "instance" in value;

export const isFilterOperatorConfig = (
  value: FilterOperator | IFilterOperatorConfig
): value is IFilterOperatorConfig => typeof value === "object" && "instance" in value;
