import React, { FC, useEffect, useMemo } from "react";

import cn from "classnames";
import { defineMessages, useIntl } from "react-intl";

import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import { SimpleCondition } from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import { SelectField } from "@mapmycustomers/ui";

import DaysField from "@app/util/filters/FrequencyStatusFilter/DaysField";

import styles from "./CommonValue.module.scss";
import FrequencyStatusFilterValue from "./FrequencyStatusFilterValue";

const SUPPORTED_OVERDUE_SUB_OPERATORS = [
  FilterOperator.IS_OVERDUE,
  FilterOperator.GREATER_THAN,
  FilterOperator.GREATER_THAN_OR_EQUAL,
  FilterOperator.LESS_THAN,
  FilterOperator.LESS_THAN_OR_EQUAL,
] as const;

const subOperatorMessages = defineMessages<(typeof SUPPORTED_OVERDUE_SUB_OPERATORS)[number]>({
  [FilterOperator.GREATER_THAN]: {
    id: "filters.cadenceStatus.subOperator.overdue.greaterThan",
    defaultMessage: "by greater than",
    description: "Greater than in frequency status filter",
  },
  [FilterOperator.GREATER_THAN_OR_EQUAL]: {
    id: "filters.cadenceStatus.subOperator.overdue.greaterThanOrEqual",
    defaultMessage: "by greater than or equal to",
    description: "Greater than or equal operator in frequency status filter",
  },
  [FilterOperator.IS_OVERDUE]: {
    id: "filters.cadenceStatus.subOperator.overdue.isOverdue",
    defaultMessage: "now",
    description: "Overdue operator in frequency status filter",
  },
  [FilterOperator.LESS_THAN]: {
    id: "filters.cadenceStatus.subOperator.overdue.lessThan",
    defaultMessage: "by less than",
    description: "Less than operator in frequency status filter",
  },
  [FilterOperator.LESS_THAN_OR_EQUAL]: {
    id: "filters.cadenceStatus.subOperator.overdue.lessThanOrEqual",
    defaultMessage: "by less than or equal to",
    description: "Less than or equal operator in frequency status filter",
  },
});

export interface PastDueValueProps {
  className?: string;
  onChange?: (value: SimpleCondition) => void;
  value: SimpleCondition;
}

const PastDueValue: FC<PastDueValueProps> = ({ className, onChange, value }) => {
  const intl = useIntl();

  const options = useMemo(() => {
    return SUPPORTED_OVERDUE_SUB_OPERATORS.map((operator) => ({
      label: intl.formatMessage(subOperatorMessages[operator]),
      value: operator,
    }));
  }, [intl]);

  const subValue = value.value as FrequencyStatusFilterValue | undefined;

  useEffect(
    () => {
      if (!value.value) {
        // selecting "now" as the default value
        onChange?.({ ...value, value: { ...value.value, operator: FilterOperator.IS_OVERDUE } });
      }
    },
    // we only need to run this useEffect once and we don't need to depend on value or onChange
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className={cn(styles.container, className)}>
      <SelectField<FilterOperator>
        className={styles.selectField}
        dropdownMatchSelectWidth={false}
        onChange={(operator) =>
          onChange?.({ ...value, value: { ...subValue, operator } as SimpleCondition })
        }
        options={options}
        showSearch
        size="middle"
        value={subValue?.operator}
      />
      {subValue?.operator !== FilterOperator.IS_OVERDUE && (
        <DaysField
          className={styles.daysField}
          onChange={(days) => {
            onChange?.({ ...value, value: { ...subValue, value: days } as SimpleCondition });
          }}
          value={subValue?.value}
        />
      )}
    </div>
  );
};

export default PastDueValue;
