import React from "react";

import { faPaperPlane } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button, { ButtonProps } from "antd/es/button";
import Tooltip, { TooltipProps } from "antd/es/tooltip";
import { useIntl } from "react-intl";

interface Props extends ButtonProps {
  recipientCount: number;
  tooltipProps?: TooltipProps;
}

const SendAndCreateButton: React.FC<Props> = ({
  disabled,
  onClick,
  recipientCount,
  tooltipProps,
  ...props
}) => {
  const intl = useIntl();

  return (
    <Tooltip
      title={intl.formatMessage(
        {
          id: "email.creationModal.sendAndCreate.tooltip",
          defaultMessage:
            "Send {count, plural, one {email} other {emails}} and create {count, plural, one {activity} other {activities}}",
          description: "Tooltip for Send and Create button in email creation modal",
        },
        { count: recipientCount }
      )}
      trigger={disabled ? [] : ["hover"]}
      {...tooltipProps}
    >
      <Button
        {...props}
        disabled={disabled}
        icon={<FontAwesomeIcon icon={faPaperPlane} />}
        onClick={onClick}
        type="primary"
      >
        {intl.formatMessage({
          id: "email.creationModal.sendAndCreate",
          defaultMessage: "Send and Create",
          description: "Send and Create button in email creation modal",
        })}
      </Button>
    </Tooltip>
  );
};

export default SendAndCreateButton;
