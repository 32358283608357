import notification from "antd/es/notification";
import { defineMessage } from "react-intl";
import { all, call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { v4 } from "uuid";

import { EntityType } from "@mapmycustomers/shared/enum";
import { GeocodeResult } from "@mapmycustomers/shared/types/base/Located";
import CustomFieldValuesUpsertResponse from "@mapmycustomers/shared/types/customField/CustomFieldValuesUpsertResponse";
import { Person } from "@mapmycustomers/shared/types/entity";
import Company from "@mapmycustomers/shared/types/entity/Company";
import { DuplicateEntity } from "@mapmycustomers/shared/types/entity/Duplicate";
import File, { RawFile } from "@mapmycustomers/shared/types/File";
import Organization from "@mapmycustomers/shared/types/Organization";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import { PlatformFilterCondition } from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";

import i18nService from "@app/config/I18nService";
import { CompanyPayload } from "@app/store/api/ApiService";
import { RequestOptions } from "@app/store/api/BaseApiService";
import { callApi } from "@app/store/api/callApi";
import { showEntityView } from "@app/store/entityView/actions";
import { showEntityChannel } from "@app/store/entityView/sagas";
import { handleError } from "@app/store/errors/actions";
import { getOrganization, getOrganizationId } from "@app/store/iam";
import { notifyAboutChanges } from "@app/store/uiSync/actions";
import FileListItem from "@app/types/FileListItem";
import { extendNavbarAnalyticWithMenuKey } from "@app/util/analytic/navbarAnalytics";
import { DUPLICATE_SEARCH_RADIUS, NOTIFICATION_DURATION_WITH_ACTION } from "@app/util/consts";
import { allSettled, SettleResult } from "@app/util/effects";

import getCreationGeocodeErrorMessage from "../../util/getCreationGeocodeErrorMessage";
import getSuccessNotificationNode from "../../util/getSuccessNotificationNode";

import { getFileGroupId, getUploadedFileListIds } from ".";
import {
  clearAllUploadedCompanyFiles,
  createCompany,
  initialize,
  removeCompanyFile,
  uploadCompanyFiles,
} from "./actions";

const successMessage = defineMessage({
  id: "createCompanyModal.success",
  defaultMessage: "Company added successfully",
  description: "Company add success message",
});

export function* onInitialize() {
  try {
    const org: Organization = yield select(getOrganization);
    const companiesResponse: ListResponse<Company> = yield callApi("fetchCompanies", org.id, {
      $columns: ["id", "name"],
      $limit: 100,
      $order: "name",
    });
    yield put(
      initialize.success({ availableCompanies: companiesResponse.data, fileGroupId: v4() })
    );
  } catch (error) {
    yield put(initialize.failure());
    yield put(handleError({ error }));
  }
}

export function* onCreateCompany({ payload }: ReturnType<typeof createCompany.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);

    const {
      callback,
      checkDuplicates,
      company,
      customFieldsValues,
      customFieldsValueValidateCallback,
      groupsIdsToAdd,
      layoutId,
      leadGen,
      personId,
    } = payload;

    // Validate custom fields values
    if (customFieldsValues.length && customFieldsValueValidateCallback) {
      const customFieldValidateResponse: CustomFieldValuesUpsertResponse[] = yield callApi(
        "upsertCustomFieldsValues",
        false,
        orgId,
        layoutId,
        EntityType.COMPANY,
        undefined,
        customFieldsValues,
        true
      );

      const erroredCustomFields = customFieldValidateResponse[0].errorCF.filter(
        ({ error }) => error.length > 0
      );

      if (erroredCustomFields.length) {
        yield call(customFieldsValueValidateCallback, erroredCustomFields);
        yield put(createCompany.failure({}));
        return;
      }
    }

    if (checkDuplicates) {
      const { email, geoPoint, name, phone, website } = company;
      const fieldsFilter: PlatformFilterCondition[] = [
        { name },
        ...((phone ?? "").trim().length ? [{ phone }] : []),
        ...((email ?? "").trim().length ? [{ email }] : []),
        ...((website ?? "").trim().length ? [{ domain: website }] : []),
      ];
      if (geoPoint) {
        fieldsFilter.push({
          area: { point: geoPoint.coordinates, radius: DUPLICATE_SEARCH_RADIUS, uom: "metres" },
        });
      } else {
        const addressToCheck = {
          address: company.address ?? "",
          city: company.city ?? "",
          country: company.country ?? "",
          postalCode: company.postalCode ?? "",
          region: company.region ?? "",
        };
        if (Object.values(addressToCheck).some((value) => value.trim().length > 0)) {
          const response: GeocodeResult = yield callApi("geocodeAddress", orgId, addressToCheck);
          if (response.geoPoint) {
            fieldsFilter.push({
              area: {
                point: response.geoPoint.coordinates,
                radius: DUPLICATE_SEARCH_RADIUS,
                uom: "metres",
              },
            });
          }
        }
      }

      const response: ListResponse<DuplicateEntity> = yield callApi(
        "fetchEntityDuplicates",
        orgId,
        EntityType.COMPANY,
        {
          $filters: { $or: fieldsFilter, dupeCheck: true },
        }
      );
      if (response.data.length) {
        yield put(
          createCompany.failure({
            duplicates: response.data,
          })
        );
        return;
      }
    }

    const fileIds: File["id"][] = yield select(getUploadedFileListIds);
    const companyToSend: CompanyPayload = {
      ...company,
      files: fileIds.length > 0 ? [...fileIds] : undefined,
    };
    const headers: RequestOptions["headers"] = {};
    if (leadGen) {
      headers["x-mmc-source"] = "lead-gen";
    }
    const newCompany: Company = yield callApi("createCompany", orgId, layoutId, companyToSend, {
      headers,
    });
    const companyIds: Array<Company["id"]> = [newCompany.id];

    yield all(
      groupsIdsToAdd.map((groupId) =>
        callApi("addToGroup", orgId, groupId, EntityType.COMPANY, companyIds)
      )
    );
    if (customFieldsValues.length) {
      yield callApi(
        "upsertCustomFieldsValues",
        false,
        orgId,
        layoutId,
        EntityType.COMPANY,
        newCompany.id,
        customFieldsValues
      );
    }
    if (personId) {
      const person: Person | undefined = yield callApi("fetchPerson", orgId, personId);
      if (person) {
        yield callApi("updatePerson", orgId, undefined, {
          id: person.id,
          accounts: [...person.accounts, { id: newCompany.id }],
        });
      }
    }
    const intl = i18nService.getIntl();
    const message = getCreationGeocodeErrorMessage(newCompany.geoStatus, intl);
    if (message) {
      notification.warning({ message });
    }
    if (callback) {
      yield call(callback, newCompany);
    }
    yield put(notifyAboutChanges({ added: [newCompany], entityType: EntityType.COMPANY }));
    extendNavbarAnalyticWithMenuKey("addCompany").clicked(["Create Company"]);
    const notificationKey = "create-company-notification";
    notification.success({
      duration: NOTIFICATION_DURATION_WITH_ACTION,
      key: notificationKey,
      message: getSuccessNotificationNode(
        i18nService.getIntl(),
        i18nService.formatMessage(successMessage, "Company added successfully"),
        () => {
          extendNavbarAnalyticWithMenuKey("addCompany").clicked(["Create Company", "View Record"]);
          showEntityChannel.put(
            showEntityView({ entityId: newCompany.id, entityType: EntityType.COMPANY })
          );
          notification.close(notificationKey);
        }
      ),
    });

    yield put(clearAllUploadedCompanyFiles());
    yield put(createCompany.success(newCompany));
  } catch (error) {
    yield put(createCompany.failure({}));
    yield put(handleError({ error }));
  }
}

export function* onUploadCompanyFiles({ payload }: ReturnType<typeof uploadCompanyFiles.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const fileGroupId: string = yield select(getFileGroupId);
    const responses: SettleResult<RawFile>[] = yield allSettled(
      payload.files.map((file) =>
        callApi("createFile", org.id, file, undefined, undefined, undefined, {
          headers: {
            "x-mmc-file-group-id": fileGroupId,
          },
        })
      )
    );
    const fileList: FileListItem[] = responses.map((response, index) => ({
      file: payload.files[index],
      uploading: false,
      ...(response.error
        ? { errored: true, errorMessage: String(response.result) }
        : { errored: false, uploadedFile: response.result }),
    }));
    payload.callback?.(fileList);
    yield put(uploadCompanyFiles.success(fileList));
  } catch (error) {
    payload.callback?.(payload.files.map((file) => ({ errored: true, file, uploading: false })));
    yield put(uploadCompanyFiles.failure());
    yield put(handleError({ error }));
  }
}

export function* onRemoveCompanyFile({ payload }: ReturnType<typeof removeCompanyFile.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    yield callApi("deleteFile", org.id, payload.id);
    yield put(removeCompanyFile.success(payload.id));
  } catch (error) {
    yield put(removeCompanyFile.failure());
    yield put(handleError({ error }));
  }
}

export function* createCompanyModalSaga() {
  yield takeLatest(initialize.request, onInitialize);
  yield takeLatest(createCompany.request, onCreateCompany);
  yield takeEvery(uploadCompanyFiles.request, onUploadCompanyFiles);
  yield takeEvery(removeCompanyFile.request, onRemoveCompanyFile);
}
