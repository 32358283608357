import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

import CustomFieldValue from "@mapmycustomers/shared/types/customField/CustomFieldValue";
import { CustomFieldError } from "@mapmycustomers/shared/types/customField/CustomFieldValuesUpsertResponse";
import { Group, Person } from "@mapmycustomers/shared/types/entity";
import Company, { CompanyRef } from "@mapmycustomers/shared/types/entity/Company";
import { DuplicateEntity } from "@mapmycustomers/shared/types/entity/Duplicate";
import { RawFile } from "@mapmycustomers/shared/types/File";
import FormLayout from "@mapmycustomers/shared/types/layout/FormLayout";

import { CompanyPayload } from "@app/store/api/ApiService";
import FileListItem from "@app/types/FileListItem";

export const showGlobalCreateCompanyModal = createAction(
  "@component/globalCreateCompanyModal/show"
)<{ showPostCreatePrompt?: boolean } | void>();
export const hideGlobalCreateCompanyModal = createAction(
  "@component/globalCreateCompanyModal/hide"
)<void>();

export const initialize = createAsyncAction(
  "@component/createCompanyModal/initialize/request",
  "@component/createCompanyModal/initialize/success",
  "@component/createCompanyModal/initialize/failure"
)<
  void,
  {
    availableCompanies: CompanyRef[];
    fileGroupId: string;
  },
  void
>();

export const createCompany = createAsyncAction(
  "@component/createCompanyModal/createCompany/request",
  "@component/createCompanyModal/createCompany/success",
  "@component/createCompanyModal/createCompany/failure"
)<
  {
    callback?: (newCompany: Company) => void;
    checkDuplicates?: boolean;
    company: CompanyPayload;
    customFieldsValues: CustomFieldValue[];
    customFieldsValueValidateCallback?: (customFieldErrors: CustomFieldError[]) => void;
    groupsIdsToAdd: Array<Group["id"]>;
    layoutId: FormLayout["id"];
    leadGen?: boolean;
    personId?: Person["id"]; // add company to this person's accounts
  },
  Company,
  { duplicates?: DuplicateEntity[] }
>();

export const uploadCompanyFiles = createAsyncAction(
  "@component/createCompanyModal/uploadCompanyFiles/request",
  "@component/createCompanyModal/uploadCompanyFiles/success",
  "@component/createCompanyModal/uploadCompanyFiles/failure"
)<
  {
    callback?: (filesList: FileListItem[]) => void;
    files: (Blob | File)[];
  },
  FileListItem[],
  void
>();

export const removeCompanyFile = createAsyncAction(
  "@component/createCompanyModal/removeCompanyFile/request",
  "@component/createCompanyModal/removeCompanyFile/success",
  "@component/createCompanyModal/removeCompanyFile/failure"
)<RawFile, RawFile["id"], void>();

export const clearAllUploadedCompanyFiles = createAction(
  "@component/createCompanyModal/clearAllUploadedCompanyFiles"
)<void>();

export type Actions = ActionType<
  | typeof clearAllUploadedCompanyFiles
  | typeof createCompany
  | typeof hideGlobalCreateCompanyModal
  | typeof initialize
  | typeof removeCompanyFile
  | typeof showGlobalCreateCompanyModal
  | typeof uploadCompanyFiles
>;
