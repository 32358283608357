import { combineReducers } from "redux";

import customFields, { CustomFieldsActions } from "./component/CustomFields/store";
import formLayouts, { FormLayoutsActions } from "./component/FormLayouts/store";
import imports, { ImportActions } from "./component/Imports/store";
import manageFields, { ManageFieldsActions } from "./component/ManageFields/store";
import mergeDuplicates, { MergeDuplicatesActions } from "./component/MergeDuplicates/store";
import restorableRecords, { RestorableRecordsActions } from "./component/RestorableRecords/store";
import sandbox, { SandboxActions } from "./component/Sandbox/store";

const settingsReducer = combineReducers({
  customFields,
  formLayouts,
  imports,
  manageFields,
  mergeDuplicates,
  restorableRecords,
  sandbox,
});

export type SettingsActions =
  | CustomFieldsActions
  | FormLayoutsActions
  | ImportActions
  | ManageFieldsActions
  | MergeDuplicatesActions
  | RestorableRecordsActions
  | SandboxActions;

export default settingsReducer;
