import React, { useEffect } from "react";
import { connect } from "react-redux";

import { defineMessage } from "react-intl";
import { Redirect } from "react-router-dom";

import ApiError from "@mapmycustomers/shared/util/api/ApiError";

import Path from "@app/enum/Path";
import {
  getError,
  isInvalidUser,
  isResetPasswordLinkExpired,
  isResetPasswordLoading,
  isResetPasswordSuccess,
  isSignedIn,
} from "@app/store/auth";
import { checkResetPasswordCodeStatus, resetPassword } from "@app/store/auth/actions";
import { RootState } from "@app/store/rootReducer";
import getErrorMessage from "@app/util/errorHandling/getErrorMessage";

import AuthLayout from "../AuthLayout";

import LinkExpiredPage from "./LinkExpiredPage";
import ResetPasswordForm from "./ResetPasswordForm";
import ResetPasswordSuccessScreen from "./ResetPasswordSuccessScreen";
import UserInvalidPage from "./UserInvalidPage";

const genericErrorMessage = defineMessage({
  id: "auth.resetPassword.error.unknown",
  defaultMessage: "Server issue, please try again later or contact support",
  description: "A generic reset password failed error message text",
});

interface Props {
  error?: ApiError;
  invalidUser: boolean;
  isResetPasswordLinkExpired: boolean;
  isResetPasswordLoading: boolean;
  isResetPasswordSuccess: boolean;
  isSignedIn: boolean | undefined;
  onInitialize: typeof checkResetPasswordCodeStatus.request;
  onResetPassword: typeof resetPassword.request;
}

export const ResetPasswordPage: React.FC<Props> = ({
  error,
  invalidUser,
  isResetPasswordLinkExpired,
  isResetPasswordLoading,
  isResetPasswordSuccess,
  isSignedIn,
  onInitialize,
  onResetPassword,
}) => {
  // Api call errors
  let message = undefined;
  if (error) {
    message = getErrorMessage(error, {}, genericErrorMessage);
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const username = urlParams.get("username");

    if (code && username) {
      onInitialize({ code, username });
    }
  }, [onInitialize]);

  // go to home to proceed when already signed in
  if (isSignedIn) {
    return <Redirect to={Path.DASHBOARD} />;
  }

  if (isResetPasswordLinkExpired) {
    return <LinkExpiredPage />;
  }

  if (invalidUser) {
    return <UserInvalidPage />;
  }

  return (
    <AuthLayout>
      {isResetPasswordSuccess ? (
        <ResetPasswordSuccessScreen />
      ) : (
        <ResetPasswordForm
          errorMessage={message}
          isResetPasswordLoading={isResetPasswordLoading}
          onResetPassword={onResetPassword}
        />
      )}
    </AuthLayout>
  );
};

const mapStateToProps = (state: RootState) => ({
  error: getError(state),
  invalidUser: isInvalidUser(state),
  isResetPasswordLinkExpired: isResetPasswordLinkExpired(state),
  isResetPasswordLoading: isResetPasswordLoading(state),
  isResetPasswordSuccess: isResetPasswordSuccess(state),
  isSignedIn: isSignedIn(state),
});

const mapDispatchToProps = {
  onInitialize: checkResetPasswordCodeStatus.request,
  onResetPassword: resetPassword.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);
