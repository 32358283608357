import notification from "antd/es/notification";
import { defineMessage } from "react-intl";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import * as uuid from "uuid";

import EntityType from "@mapmycustomers/shared/enum/EntityType";
import Activity from "@mapmycustomers/shared/types/entity/Activity";
import { RawFile } from "@mapmycustomers/shared/types/File";
import Organization from "@mapmycustomers/shared/types/Organization";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import ViewState from "@mapmycustomers/shared/types/viewModel/ViewState";

import { showGlobalCreateActivityModal } from "@app/component/createEditEntity/Activity/store/actions";
import getSuccessNotificationNode from "@app/component/createEditEntity/util/getSuccessNotificationNode";
import i18nService from "@app/config/I18nService";
import localSettings from "@app/config/LocalSettings";
import { callApi } from "@app/store/api/callApi";
import { handleError } from "@app/store/errors/actions";
import { exportEntities } from "@app/store/exportEntities/actions";
import { getOrganization, getOrganizationId } from "@app/store/iam";
import { notifyAboutChanges } from "@app/store/uiSync/actions";
import FileListItem from "@app/types/FileListItem";
import { allSettled, SettleResult } from "@app/util/effects";
import activityFieldModel from "@app/util/fieldModel/ActivityFieldModel";
import { activityLayoutModel } from "@app/util/layout/impl";
import { getEntityTypeDisplayName } from "@app/util/ui";
import { isSimpleCondition } from "@app/util/viewModel/assert";
import { convertToPlatformSortModel } from "@app/util/viewModel/convertSort";
import { convertToPlatformFilterModel } from "@app/util/viewModel/convertToPlatformFilterModel";

import {
  applyListViewSettings,
  bulkDeleteActivities,
  bulkEdit,
  bulkPostpone,
  bulkToggleComplete,
  deleteActivity,
  exportActivities,
  fetchFilePreview,
  fetchList,
  openDuplicationModal,
  removeActivityFile,
  resetSelectedActivities,
  toggleComplete,
  updateActivity,
  updateActivityView,
  updateNote,
  uploadActivityFiles,
} from "./actions";
import {
  getListViewState,
  getTotalFilteredRecordsCount,
  getUpdateViewStateCallback,
} from "./selectors";

const postponeSuccessMessage = defineMessage({
  id: "scene.activity.saga.postpone.success",
  defaultMessage: "Activity postponed successfully",
  description: "Activity postpone success message",
});

const bulkPostponeSuccessMessage = defineMessage({
  id: "scene.activity.saga.bulkPostpone.success",
  defaultMessage: "{count} {entityName} postponed by {period}",
  description: "Activity bulk postpone success message",
});

const toggleCompleteSuccessMessage = defineMessage({
  id: "scene.activity.saga.toggleComplete.success",
  defaultMessage: 'Activity{completed, select, true {} other { is no longer}} marked as "Done"',
  description: "Activity toggle complete success message",
});

const bulkToggleCompleteSuccessMessage = defineMessage({
  id: "scene.activity.saga.bulkToggleComplete.success",
  defaultMessage:
    '{count} {entityName}{completed, select, true {} other { {count, plural, one {is} other {are}} no longer}} marked as "Done"',
  description: "Activity bulk toggle complete success message",
});

const filesAddedSuccess = defineMessage({
  id: "scene.activity.saga.fileAdded.success",
  defaultMessage: "{count, plural, one {File} other {Files}} Added to Activity",
  description: "Activity files added success message",
});

const noteAddedSuccess = defineMessage({
  id: "scene.activity.saga.noteAdded.success",
  defaultMessage: "Activity Note {text}",
  description: "Activity note added success message",
});

const deleteSuccessMessage = defineMessage({
  id: "scene.activity.saga.delete.success.title",
  defaultMessage: "Activity successfully deleted",
  description: "Activity delete success message",
});

const successDeletedDescription = defineMessage({
  id: "scene.activity.saga.delete.success.description",
  defaultMessage: "To restore this record, go to Data in Settings.",
  description: "Activity deleted success message description",
});

const bulkDeleteSuccessMessage = defineMessage({
  id: "scene.activity.saga.bulkDelete.success.title",
  defaultMessage: "{entityName} successfully deleted",
  description: "Activities bulk delete success message",
});

const bulkDeleteSuccessDescription = defineMessage({
  id: "scene.activity.saga.bulkDelete.success.description",
  defaultMessage:
    "To restore {plural, select, true {these records} other {this record}}, go to Data in Settings.",
  description: "Activities are deleted successfully description from the Select Bar",
});

const bulkEditSuccessMessage = defineMessage({
  id: "scene.activity.saga.bulkEdit.success",
  defaultMessage: "{count} {entityName} updated",
  description: "Activities bulk delete edit message",
});

export function* onFetchList({ payload }: ReturnType<typeof fetchList.request>) {
  try {
    if (!payload.fetchOnlyWithoutFilters) {
      yield put(applyListViewSettings(payload.request));
    }

    const listViewState: ViewState = yield select(getListViewState);

    if (!payload.fetchOnlyWithoutFilters) {
      localSettings.setViewSettings(listViewState, "activity/listView");
    }

    if (payload.updateOnly) {
      return;
    }

    const $offset =
      payload.fetchOnlyWithoutFilters && payload.request.range
        ? payload.request.range.startRow
        : listViewState.range.startRow;
    const $limit =
      payload.fetchOnlyWithoutFilters && payload.request.range
        ? payload.request.range.endRow - payload.request.range.startRow
        : listViewState.range.endRow - listViewState.range.startRow;

    const organization: Organization = yield select(getOrganization);
    const { quickFilter, ...filter } = listViewState.filter;
    const $filters = {
      includeAccessStatus: true,
      includeEmails: true,
      includeFiles: true,
      includeUsersWithAccess: true,
      ...convertToPlatformFilterModel(
        payload.fetchOnlyWithoutFilters ? {} : filter,
        listViewState.columns,
        activityFieldModel,
        true,
        listViewState.viewAs
      ),
    };
    if (!payload.fetchOnlyWithoutFilters && quickFilter && isSimpleCondition(quickFilter)) {
      const { value } = quickFilter;
      $filters["$and"]?.push({
        $or: [
          { name: { $in: value } },
          { "account.name": { $in: value } },
          { "contact.name": { $in: value } },
          { "deal.name": { $in: value } },
        ],
      });
    }
    const requestPayload = {
      $filters,
      $limit,
      $offset,
      $order: convertToPlatformSortModel(listViewState.sort),
    };
    const response: ListResponse<Activity> = yield callApi(
      "fetchActivities",
      organization.id,
      requestPayload
    );
    payload.dataCallback?.(response);
    yield put(
      fetchList.success({
        list: response.data,
        totalFilteredRecords: response.total,
        totalRecords: response.accessible,
      })
    );
  } catch (error) {
    payload.failCallback?.();
    yield put(fetchList.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onUpdateActivity({ payload }: ReturnType<typeof updateActivity.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const layoutId = activityLayoutModel.getLayoutFor(payload).id;

    const updatedActivity: Activity = yield callApi("updateActivity", organization.id, layoutId, {
      ...payload,
      files: (payload.files as RawFile[]).map(({ id }) => id),
    });
    yield put(updateActivity.success(updatedActivity));

    notification.success({
      message: getSuccessNotificationNode(
        i18nService.getIntl(),
        i18nService.formatMessage(postponeSuccessMessage, "Activity postponed successfully")
      ),
    });
  } catch (error) {
    yield put(updateActivity.failure(payload.id));
    yield put(handleError({ error }));
  }
}

export function* onToggleComplete({ payload }: ReturnType<typeof toggleComplete.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const layoutId = activityLayoutModel.getLayoutFor(payload).id;

    const response: Activity = yield callApi("updateActivity", organization.id, layoutId, {
      ...payload,
      completed: !payload.completed,
      files: (payload.files as RawFile[]).map(({ id }) => id),
    });
    yield put(toggleComplete.success(response));

    notification.success({
      message: getSuccessNotificationNode(
        i18nService.getIntl(),
        i18nService.formatMessage(
          toggleCompleteSuccessMessage,
          `Activity marked as "${response.completed ? "Done" : "Not yet done"}"`,
          { completed: response.completed }
        )
      ),
    });
  } catch (error) {
    yield put(toggleComplete.failure(payload.id));
    yield put(handleError({ error }));
  }
}

export function* onOpenDuplicationModal({
  payload,
}: ReturnType<typeof openDuplicationModal.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const { id, ...activity }: Activity = yield callApi("fetchActivity", organization.id, payload, {
      includeAccessStatus: true,
      includeCustomFields: true,
    });
    yield put(openDuplicationModal.success(id));
    yield put(showGlobalCreateActivityModal({ activity, cloning: true }));
  } catch (error) {
    yield put(toggleComplete.failure(payload));
    yield put(handleError({ error }));
  }
}

export function* onDeleteActivity({
  payload: { id, callback, deleteType },
}: ReturnType<typeof deleteActivity.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    yield callApi("deleteActivity", organization.id, id, deleteType);
    yield put(deleteActivity.success(id));
    if (callback) {
      yield call(callback);
    }
    yield put(notifyAboutChanges({ deletedIds: [id], entityType: EntityType.ACTIVITY }));

    notification.success({
      description: i18nService.formatMessage(successDeletedDescription),
      message: getSuccessNotificationNode(
        i18nService.getIntl(),
        i18nService.formatMessage(deleteSuccessMessage, "Activity successfully deleted")
      ),
    });
  } catch (error) {
    yield put(deleteActivity.failure(id));
    yield put(handleError({ error }));
  }
}

export function* onBulkDeleteActivities({
  payload,
}: ReturnType<typeof bulkDeleteActivities.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    yield callApi("deleteEntities", organization.id, EntityType.ACTIVITY, payload.ids);
    yield put(bulkDeleteActivities.success());
    yield put(fetchList.request({ request: {} }));
    payload.onSuccess(); // should go before reset selected due to closing modal/popup actions
    yield put(resetSelectedActivities());

    const intl = i18nService.getIntl();
    const entityName = intl
      ? getEntityTypeDisplayName(intl, EntityType.ACTIVITY, {
          genitive: payload.ids.length === 1 ? undefined : payload.ids.length,
          lowercase: true,
          // choose nominative when count is 1 and genitive otherwise
          plural: payload.ids.length === 1 ? false : undefined,
        })
      : "";
    const count = payload.ids.length;
    if (intl) {
      notification.success({
        description: i18nService.formatMessage(
          bulkDeleteSuccessDescription,
          "To restore these records, go to Data in Settings.",
          {
            plural: count > 1,
          }
        ),
        message: getSuccessNotificationNode(
          intl,
          i18nService.formatMessage(
            bulkDeleteSuccessMessage,
            `${entityName} successfully deleted`,
            {
              entityName: getEntityTypeDisplayName(intl, EntityType.ACTIVITY, {
                lowercase: false,
                plural: count > 1,
              }),
            }
          )
        ),
      });
    }
  } catch (error) {
    yield put(bulkDeleteActivities.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onFetchFilePreview({ payload }: ReturnType<typeof fetchFilePreview.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const fileData: Blob = yield callApi(
      "fetchFile",
      org.id,
      payload.file,
      false,
      true,
      EntityType.ACTIVITY,
      payload.activityId,
      { responseType: "blob" }
    );
    yield put(fetchFilePreview.success(fileData));
  } catch (error) {
    yield put(fetchFilePreview.failure());
    yield put(handleError({ error }));
  }
}

export function* onBulkEdit({ payload }: ReturnType<typeof bulkEdit.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    yield callApi("updateEntities", organization.id, EntityType.ACTIVITY, payload.activities);
    yield put(bulkEdit.success(payload.activities));

    const intl = i18nService.getIntl();
    const entityName = intl
      ? getEntityTypeDisplayName(intl, EntityType.ACTIVITY, {
          genitive: payload.activities.length === 1 ? undefined : payload.activities.length,
          lowercase: true,
          // choose nominative when count is 1 and genitive otherwise
          plural: payload.activities.length === 1 ? false : undefined,
        })
      : "";
    const count = payload.activities.length;
    notification.success({
      message: getSuccessNotificationNode(
        intl,
        i18nService.formatMessage(bulkEditSuccessMessage, `${count} ${entityName} updated`, {
          count,
          entityName,
        })
      ),
    });

    yield put(
      notifyAboutChanges({
        entityType: EntityType.ACTIVITY,
        updated: payload.activities as Activity[],
      })
    );
    payload.onSuccess();
  } catch (error) {
    yield put(bulkEdit.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onBulkPostpone({ payload }: ReturnType<typeof bulkPostpone.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    yield callApi("updateEntities", organization.id, EntityType.ACTIVITY, payload.activities);
    yield put(bulkPostpone.success(payload.activities));

    const intl = i18nService.getIntl();
    const entityName = intl
      ? getEntityTypeDisplayName(intl, EntityType.ACTIVITY, {
          genitive: payload.activities.length === 1 ? undefined : payload.activities.length,
          lowercase: true,
          // choose nominative when count is 1 and genitive otherwise
          plural: payload.activities.length === 1 ? false : undefined,
        })
      : "";
    const count = payload.activities.length;
    notification.success({
      message: getSuccessNotificationNode(
        intl,
        i18nService.formatMessage(
          bulkPostponeSuccessMessage,
          `${count} ${entityName} postpone by ${payload.period}`,
          {
            count,
            entityName,
            period: payload.period,
          }
        )
      ),
    });
  } catch (error) {
    yield put(bulkPostpone.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onBulkToggleComplete({ payload }: ReturnType<typeof bulkToggleComplete.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const updatedActivities = payload.activities.map(({ id }) => ({
      id,
      completed: payload.completed,
      completedAt: payload.completed ? new Date().toISOString() : null,
    }));
    yield callApi("updateEntities", organization.id, EntityType.ACTIVITY, updatedActivities);
    yield put(bulkToggleComplete.success(updatedActivities));

    const intl = i18nService.getIntl();
    const entityName = intl
      ? getEntityTypeDisplayName(intl, EntityType.ACTIVITY, {
          genitive: payload.activities.length === 1 ? undefined : payload.activities.length,
          lowercase: true,
          // choose nominative when count is 1 and genitive otherwise
          plural: payload.activities.length === 1 ? false : undefined,
        })
      : "";
    const count = payload.activities.length;
    notification.success({
      message: getSuccessNotificationNode(
        intl,
        i18nService.formatMessage(
          bulkToggleCompleteSuccessMessage,
          `${count} ${entityName} marked as ${payload.completed ? '"Done"' : '"Not yet done"'}`,
          {
            completed: payload.completed,
            count,
            entityName,
          }
        )
      ),
    });
    yield put(
      notifyAboutChanges({
        entityType: EntityType.ACTIVITY,
        updated: payload.activities as Activity[],
      })
    );
  } catch (error) {
    yield put(bulkToggleComplete.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onUpdateNote({ payload }: ReturnType<typeof updateNote.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const layoutId = activityLayoutModel.getLayoutFor(payload.activity).id;
    const { files, ...activity } = payload.activity;

    const response: Activity = yield callApi("updateActivity", orgId, layoutId, activity);
    yield put(updateNote.success({ id: response.id, note: response.note }));

    const intl = i18nService.getIntl();
    if (intl) {
      notification.success({
        message: intl.formatMessage(noteAddedSuccess, {
          text: payload.updated ? "Updated" : "Created",
        }),
      });
    }
    payload.onSuccess();
  } catch (error) {
    yield put(updateNote.failure(payload.activity.id));
    yield put(handleError({ error }));
  }
}

export function* onUpdateActivityView({
  payload: { viewState },
}: ReturnType<typeof updateActivityView>) {
  const callback: (state: Partial<ViewState>) => undefined | void = yield select(
    getUpdateViewStateCallback
  );
  callback?.(viewState);
}

export function* onUploadActivityFiles({
  payload,
}: ReturnType<typeof uploadActivityFiles.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const fileGroupId = uuid.v4();
    const responses: SettleResult<RawFile>[] = yield allSettled(
      payload.files.map((file) => {
        return callApi("createFile", org.id, file, false, EntityType.ACTIVITY, payload.activityId, {
          headers: {
            "x-mmc-file-group-id": fileGroupId,
          },
        });
      })
    );
    const fileList: FileListItem[] = responses.map((response, index) => ({
      file: payload.files[index],
      uploading: false,
      ...(response.error
        ? { errored: true, errorMessage: String(response.result), exceedsLimit: false }
        : { errored: false, exceedsLimit: false, uploadedFile: response.result }),
    }));
    payload.callback?.(fileList);
    yield put(uploadActivityFiles.success(fileList));
    const intl = i18nService.getIntl();
    if (intl) {
      notification.success({
        message: intl.formatMessage(filesAddedSuccess, {
          count: payload.files.length,
        }),
      });
    }
  } catch (error) {
    payload.callback?.(payload.files.map((file) => ({ errored: true, file, uploading: false })));
    yield put(uploadActivityFiles.failure());
    yield put(handleError({ error }));
  }
}

export function* onRemoveActivityFile({ payload }: ReturnType<typeof removeActivityFile.request>) {
  try {
    const org: Organization = yield select(getOrganization);

    yield callApi(
      "deleteEntityFile",
      org.id,
      EntityType.ACTIVITY,
      payload.activity.id,
      payload.file.id
    );
    const updatedActivity: Activity = yield callApi("fetchActivity", org.id, payload.activity.id, {
      includeCustomFields: true,
      includeFiles: true,
    });
    yield put(removeActivityFile.success({ activity: updatedActivity, file: payload.file.id }));
    payload.onSuccess(updatedActivity);
  } catch (error) {
    yield put(removeActivityFile.failure());
    yield put(handleError({ error }));
  }
}

export function* onExportActivities({
  payload: { viewState },
}: ReturnType<typeof exportActivities>) {
  const total: number = yield select(getTotalFilteredRecordsCount);
  yield put(
    exportEntities.request({
      entityType: EntityType.ACTIVITY,
      total,
      viewState,
    })
  );
}

export function* activitySaga() {
  yield takeEvery(bulkDeleteActivities.request, onBulkDeleteActivities);
  yield takeEvery(bulkEdit.request, onBulkEdit);
  yield takeEvery(bulkPostpone.request, onBulkPostpone);
  yield takeEvery(bulkToggleComplete.request, onBulkToggleComplete);
  yield takeEvery(deleteActivity.request, onDeleteActivity);
  yield takeEvery(fetchFilePreview.request, onFetchFilePreview);
  yield takeEvery(fetchList.request, onFetchList);
  yield takeEvery(openDuplicationModal.request, onOpenDuplicationModal);
  yield takeEvery(toggleComplete.request, onToggleComplete);
  yield takeEvery(updateActivity.request, onUpdateActivity);
  yield takeEvery(updateNote.request, onUpdateNote);
  yield takeLatest(removeActivityFile.request, onRemoveActivityFile);
  yield takeLatest(updateActivityView, onUpdateActivityView);
  yield takeLatest(uploadActivityFiles.request, onUploadActivityFiles);
  yield takeLatest(exportActivities, onExportActivities);
}
