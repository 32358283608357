import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import PlatformFilterOperator from "@mapmycustomers/shared/enum/PlatformFilterOperator";
import SortOrder from "@mapmycustomers/shared/enum/SortOrder";
import { AnyEntity, EntityType } from "@mapmycustomers/shared/types/entity";
import Organization from "@mapmycustomers/shared/types/Organization";
import FilterModel from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";

import localSettings from "@app/config/LocalSettings";
import { getDatesRange } from "@app/enum/dashboard/dateRanges";
import {
  getNewRecordsDrillDownConfiguration,
  getNewRecordsDrillDownTotalFilteredRecords,
  getNewRecordsDrillDownViewState,
} from "@app/scene/dashboard/store/cards/newRecords/selectors";
import DrillDownViewState from "@app/scene/dashboard/types/DrillDownViewState";
import { ApiMethodName } from "@app/store/api/ApiService";
import { callApi } from "@app/store/api/callApi";
import { fetchNewRecordsCardDataHelper } from "@app/store/dashboard/cardDataFetchHelpers";
import { handleError } from "@app/store/errors/actions";
import { exportEntities } from "@app/store/exportEntities/actions";
import { getOrganizationId } from "@app/store/iam";
import EntityTypesSupportedInNewRecords from "@app/types/dashboard/cards/newRecords/EntityTypesSupportedInNewRecords";
import NewRecordsCardConfiguration from "@app/types/dashboard/cards/newRecords/NewRecordsCardConfiguration";
import Scope from "@app/types/dashboard/Scope";
import { CompanyFieldName } from "@app/util/fieldModel/CompanyFieldModel";
import { DealFieldName } from "@app/util/fieldModel/DealFieldModel";
import getFieldModelByEntityType from "@app/util/fieldModel/getByEntityType";
import FieldModel from "@app/util/fieldModel/impl/FieldModel";
import { PersonFieldName } from "@app/util/fieldModel/PersonFieldModel";
import loggingService from "@app/util/logging";
import { convertFromPlatformFilterModel } from "@app/util/viewModel/convertFromPlatformFilterModel";
import { convertToPlatformSortModel } from "@app/util/viewModel/convertSort";
import { convertToPlatformFilterModel } from "@app/util/viewModel/convertToPlatformFilterModel";

import getDefaultDrillDownColumns from "../../utils/getDefaultDrillDownColumns";

import {
  applyNewRecordsDrillDownListViewSettings,
  exportNewRecordsCardDrillDownData,
  fetchNewRecordsCardData,
  fetchNewRecordsCardDrillDownData,
  showNewRecordsDrillDown,
} from "./actions";

const defaultColumns: Record<EntityTypesSupportedInNewRecords, string[]> = {
  [EntityType.COMPANY]: [
    CompanyFieldName.NAME,
    CompanyFieldName.USER,
    CompanyFieldName.ADDRESS,
    CompanyFieldName.CREATED_AT,
    CompanyFieldName.SOURCE,
  ],
  [EntityType.DEAL]: [
    DealFieldName.NAME,
    DealFieldName.USER,
    DealFieldName.ADDRESS,
    DealFieldName.CREATED_AT,
    DealFieldName.SOURCE,
  ],
  [EntityType.PERSON]: [
    PersonFieldName.NAME,
    PersonFieldName.USER,
    PersonFieldName.ADDRESS,
    PersonFieldName.CREATED_AT,
    PersonFieldName.SOURCE,
  ],
};

const defaultSortColumn: Record<EntityTypesSupportedInNewRecords, string> = {
  [EntityType.COMPANY]: CompanyFieldName.CREATED_AT,
  [EntityType.DEAL]: DealFieldName.CREATED_AT,
  [EntityType.PERSON]: PersonFieldName.CREATED_AT,
};

const fetchMethodByEntityType: Record<EntityTypesSupportedInNewRecords, ApiMethodName> = {
  [EntityType.COMPANY]: "fetchCompanies",
  [EntityType.DEAL]: "fetchDeals",
  [EntityType.PERSON]: "fetchPeople",
};

export const getFilters = (
  scope: Scope | undefined,
  configuration: NewRecordsCardConfiguration
) => {
  const { criteria, dateRange, extraFilters } = configuration;
  const isTeamScope = !!scope?.teamId && !scope?.userId;

  const range = getDatesRange(dateRange.range, dateRange.subRange);

  return {
    ...extraFilters,
    createdAt: { $gte: range.startDate.toISOString(), $lte: range.endDate.toISOString() },
    createdBy: scope?.userId ? { [PlatformFilterOperator.CONTAINS]: [scope.userId] } : undefined,
    source: criteria.sources.length ? { $in: criteria.sources } : undefined,
    teamId: isTeamScope ? { [PlatformFilterOperator.CONTAINS]: [scope.teamId!] } : undefined,
  };
};

function* onFetchNewRecordsCardData({ payload }: ReturnType<typeof fetchNewRecordsCardData>) {
  const { callback, configuration, failureCallback, scope } = payload;

  try {
    yield call(fetchNewRecordsCardDataHelper, { callback, configuration, scope });
  } catch (error) {
    yield put(handleError({ error }));
    if (failureCallback) {
      yield call(failureCallback);
    }
  }
}

function* onOpenDrillDown({ payload }: ReturnType<typeof showNewRecordsDrillDown.request>) {
  const { configuration, customDateRange, scope } = payload;
  const { criteria, dateRange, extraFilters } = configuration;

  const drillDownViewState: DrillDownViewState = yield select(getNewRecordsDrillDownViewState);
  const entityType = payload.configuration.criteria.entityType;
  const fieldModel = getFieldModelByEntityType(entityType);
  const viewState = localSettings.getViewSettings(
    `dashboard/newRecords/${entityType}`,
    fieldModel,
    {
      ...drillDownViewState,
      columns: getDefaultDrillDownColumns(defaultColumns[entityType], fieldModel),
      sort: [
        {
          field: fieldModel.getByName(defaultSortColumn[entityType])!,
          order: SortOrder.DESC,
        },
      ],
    }
  );

  const isTeamScope = !!scope?.teamId && !scope?.userId;
  const range = customDateRange ?? getDatesRange(dateRange.range, dateRange.subRange);

  const filter: FilterModel = {
    ...convertFromPlatformFilterModel(extraFilters ?? {}, fieldModel),
    createdAt: { operator: FilterOperator.IN_RANGE, value: [range.startDate, range.endDate] },
  };
  if (isTeamScope) {
    filter.team = { operator: FilterOperator.IN_ANY, value: [scope.teamId!] };
  }
  if (scope?.userId) {
    filter.creator = { operator: FilterOperator.IN_ANY, value: [scope.userId] };
  }
  if (criteria.sources.length) {
    filter.sourceCreated = { operator: FilterOperator.IN_ANY, value: criteria.sources };
  }
  viewState.filter = filter;

  yield put(showNewRecordsDrillDown.success({ viewState }));
}

export function* onFetchDrillDownData({
  payload,
}: ReturnType<typeof fetchNewRecordsCardDrillDownData.request>) {
  try {
    loggingService.debug("Dashboard: new record card, onFetchDrillDownData", payload);
    if (!payload.updateOnly) {
      // We do not listen to filter returned by AgGrid from PlatformDataSource
      delete payload.request.filter;
    }

    if (!payload.fetchOnlyWithoutFilters) {
      yield put(applyNewRecordsDrillDownListViewSettings(payload.request));
    }

    const configuration: NewRecordsCardConfiguration = yield select(
      getNewRecordsDrillDownConfiguration
    );
    const drillDownViewState: DrillDownViewState = yield select(getNewRecordsDrillDownViewState);

    if (!payload.fetchOnlyWithoutFilters) {
      localSettings.setViewSettings(
        drillDownViewState,
        `dashboard/newRecords/${configuration.criteria.entityType}`
      );
    }

    if (payload.updateOnly) {
      return;
    }

    const $offset =
      payload.fetchOnlyWithoutFilters && payload.request.range
        ? payload.request.range.startRow
        : drillDownViewState.range.startRow;
    const $limit =
      payload.fetchOnlyWithoutFilters && payload.request.range
        ? payload.request.range.endRow - payload.request.range.startRow
        : drillDownViewState.range.endRow - drillDownViewState.range.startRow;

    const orgId: Organization["id"] = yield select(getOrganizationId);
    const fieldModel: FieldModel = yield call(
      getFieldModelByEntityType,
      configuration.criteria.entityType
    );
    const requestPayload = {
      $filters: {
        includeAccessStatus: true,
        ...convertToPlatformFilterModel(
          payload.fetchOnlyWithoutFilters ? {} : drillDownViewState.filter,
          drillDownViewState.columns,
          fieldModel,
          true,
          drillDownViewState.viewAs
        ),
      },
      $limit,
      $offset,
      $order: convertToPlatformSortModel(drillDownViewState.sort),
    };

    const fetchMethod = fetchMethodByEntityType[configuration.criteria.entityType];
    const response: ListResponse<AnyEntity> = yield callApi(fetchMethod, orgId, requestPayload);
    payload.dataCallback?.(response);
    yield put(
      fetchNewRecordsCardDrillDownData.success({
        totalFilteredRecords: response.total,
        totalRecords: response.accessible,
      })
    );
  } catch (error) {
    payload.failCallback?.();
    yield put(fetchNewRecordsCardDrillDownData.failure(error));
    yield put(handleError({ error }));
  }
}

function* onExport() {
  const configuration: NewRecordsCardConfiguration = yield select(
    getNewRecordsDrillDownConfiguration
  );
  const drillDownViewState: DrillDownViewState = yield select(getNewRecordsDrillDownViewState);
  const total: number = yield select(getNewRecordsDrillDownTotalFilteredRecords);

  yield put(
    exportEntities.request({
      entityType: configuration.criteria.entityType,
      total,
      viewState: drillDownViewState,
    })
  );
}

export function* newRecordsSagas() {
  // we use takeEvery because there might be several cards of such type on board
  // however, it would be more optimal to use takeLatest, but also filter by card ids
  yield takeEvery(fetchNewRecordsCardData, onFetchNewRecordsCardData);
  yield takeLatest(showNewRecordsDrillDown.request, onOpenDrillDown);
  yield takeLatest(fetchNewRecordsCardDrillDownData.request, onFetchDrillDownData);
  yield takeLatest(exportNewRecordsCardDrillDownData, onExport);
}
