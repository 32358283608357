import { ActionType, createAsyncAction } from "typesafe-actions";

import SortOrder from "@mapmycustomers/shared/enum/SortOrder";
import {
  EntitySupportingRecordRestoration,
  EntityTypesSupportingRestoration,
} from "@mapmycustomers/shared/types/entity";

export const fetchDeletedEntities = createAsyncAction(
  "settings/restorableRecords/fetchDeletedEntities/request",
  "settings/restorableRecords/fetchDeletedEntities/success",
  "settings/restorableRecords/fetchDeletedEntities/failure"
)<
  {
    entityType: EntityTypesSupportingRestoration;
    page: number;
    query?: string;
    sortFieldName: string;
    sortOrder: SortOrder;
  },
  { entities: EntitySupportingRecordRestoration[]; total: number },
  unknown
>();

export const restoreRecords = createAsyncAction(
  "settings/restorableRecords/restoreRecords/request",
  "settings/restorableRecords/restoreRecords/success",
  "settings/restorableRecords/restoreRecords/failure"
)<
  { entityType: EntityTypesSupportingRestoration; ids: EntitySupportingRecordRestoration["id"][] },
  void,
  unknown
>();

export type Actions = ActionType<typeof fetchDeletedEntities | typeof restoreRecords>;
