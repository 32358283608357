import notification from "antd/es/notification";
import chunk from "lodash-es/chunk";
import { defineMessages } from "react-intl";
import {
  all,
  call,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
  takeLeading,
} from "redux-saga/effects";
import { Action, isActionOf } from "typesafe-actions";

import { geometryFromBinary } from "@mapmycustomers/shared";
import SortOrder from "@mapmycustomers/shared/enum/SortOrder";
import { Territory } from "@mapmycustomers/shared/types/entity";
import Organization from "@mapmycustomers/shared/types/Organization";
import User from "@mapmycustomers/shared/types/User";
import ListRequest from "@mapmycustomers/shared/types/viewModel/internalModel/ListRequest";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import PlatformFilterModel from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";
import PlatformListRequest from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformListRequest";

import i18nService from "@app/config/I18nService";
import { callApi } from "@app/store/api/callApi";
import { handleError } from "@app/store/errors/actions";
import { getCurrentUserId, getOrganization, getOrganizationId } from "@app/store/iam";
import { updateMetadata } from "@app/store/iam/actions";
import {
  areTerritoriesLoading,
  getTerritories,
  haveAnyTerritories,
} from "@app/store/territories/selectors";
import { allSettled, SettleResult } from "@app/util/effects";
import territoryFieldModel from "@app/util/fieldModel/TerritoryFieldModel";
import loggingService from "@app/util/logging";
import { convertToPlatformSortModel } from "@app/util/viewModel/convertSort";
import { convertToPlatformFilterModel } from "@app/util/viewModel/convertToPlatformFilterModel";

import {
  createTerritory,
  deleteTerritory,
  fetchAllTerritories,
  fetchTerritories,
  hideTerritories,
  showTerritories,
  updateTerritory,
  updateTerritorySharing,
} from "./actions";

const messages = defineMessages({
  created: {
    id: "territories.create.success",
    defaultMessage: "{name} Territory Created.",
    description: "Territory created successfully",
  },
  deleted: {
    id: "territories.deleted.success",
    defaultMessage: "Territory successfully deleted",
    description: "Territory deleted successfully",
  },
  updated: {
    id: "territories.update.success",
    defaultMessage: "{name} Territory Edited.",
    description: "Territory updated successfully",
  },
});

const successTypeActionMatcher =
  () =>
  (action: Action): boolean =>
    isActionOf(fetchTerritories.success, action);

export function* onFetchTerritories({ payload }: ReturnType<typeof fetchTerritories.request>) {
  try {
    const organizationId: Organization["id"] = yield select(getOrganizationId);
    const requestPayload: Partial<Omit<PlatformListRequest, "$columns">> = {
      $filters: payload.request?.filter
        ? convertToPlatformFilterModel(payload.request.filter, [], territoryFieldModel)
        : ({} as PlatformFilterModel),
      $limit: payload.request?.range
        ? payload.request.range.endRow - payload.request.range.startRow
        : undefined,
      $offset: payload.request?.range ? payload.request.range.startRow : 0,
      $order: payload.request?.sort ? convertToPlatformSortModel(payload.request.sort) : undefined,
    };
    requestPayload.$filters!.includeAccessStatus = true;

    const response: ListResponse<Territory> = yield callApi(
      "fetchTerritories",
      organizationId,
      requestPayload
    );

    yield put(
      fetchTerritories.success({
        territories: response.data.map((territory) => ({ ...territory, count: undefined })),
        total: response.accessible,
      })
    );

    // Send follow-up request with additional parameter to calculate number of items per territory
    // This is kind of optimistic fetching to let quicker display list of territories first
    requestPayload.$filters!.includeEntitiesCount = true;
    const responseWithCount: ListResponse<Territory> = yield callApi(
      "fetchTerritories",
      organizationId,
      requestPayload
    );

    yield put(
      fetchTerritories.success({
        territories: responseWithCount.data,
        total: responseWithCount.accessible,
      })
    );

    // Now let's split territories in chunks by 100, and fetch their shapes

    const territoriesWithFiles = responseWithCount.data.filter(
      (territory) => territory.territoryDetail.shapeFile?.publicURI
    );
    const chunks = chunk(territoriesWithFiles, 500);

    for (const chunk of chunks) {
      const files: SettleResult<ArrayBuffer>[] = yield allSettled(
        chunk.map(function* (territory) {
          const response: Response = yield fetch(
            territory.territoryDetail.shapeFile!.publicURI!,
            {}
          );
          const arrayBuffer: ArrayBuffer = yield response.arrayBuffer();
          return arrayBuffer;
        })
      );
      responseWithCount.data.forEach((territory) => {
        const index = chunk.findIndex(({ id }) => id === territory.id);
        if (index >= 0 && !files[index].error) {
          try {
            territory.territoryDetail.shape = geometryFromBinary(
              files[index].result as ArrayBuffer
            );
          } catch (error) {
            loggingService.error(`Failed to decode territory (id=${territory.id}) shape file`, {
              error,
            });
          }
        }
      });

      yield put(
        fetchTerritories.success({
          territories: responseWithCount.data,
          total: responseWithCount.accessible,
        })
      );
    }
  } catch (error) {
    yield put(fetchTerritories.failure(error));
  }
}

export function* onFetchAllTerritories({
  payload,
}: ReturnType<typeof fetchAllTerritories.request>) {
  try {
    const { force = false } = payload ?? {};

    const loading: boolean = yield select(areTerritoriesLoading);
    const anyTerritories: boolean = yield select(haveAnyTerritories);

    if (loading && !force) {
      // just return. Saga which initiated loading will trigger fetchAllTerritories.success
      return;
    }

    // We have territories already, don't fetch again, unless force==true.
    // Note: I know this condition doesn't work good when there are no territories at all,
    // but in that case there's nothing to return and no shape files to load. So it
    // should be acceptable.
    if (anyTerritories && !force) {
      yield put(fetchAllTerritories.success());
      return;
    }

    // call fetchCustomFields actions with a given request options
    const request: Partial<ListRequest> = {
      range: { endRow: 2000, startRow: 0 }, // fetch all
      sort: [{ field: territoryFieldModel.getByName("name")!, order: SortOrder.ASC }],
    };
    yield put(fetchTerritories.request({ request }));

    // now wait till all succeed or any fails, whichever happens first
    const { failure } = yield race({
      failure: take([fetchTerritories.failure]),
      success: all([take(successTypeActionMatcher())]),
    });

    if (failure) {
      yield put(fetchAllTerritories.failure());
    } else {
      yield put(fetchAllTerritories.success());
    }
  } catch (error) {
    yield put(fetchAllTerritories.failure());
    yield put(handleError({ error }));
  }
}

export function* onCreateTerritory({
  payload: { onSuccess, shape, territory, userIdsToShareWith },
}: ReturnType<typeof createTerritory.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const currentUserId: User["id"] = yield select(getCurrentUserId);
    const newTerritory: Territory = yield callApi(
      "createTerritory",
      org.id,
      {
        ...territory,
        userId: currentUserId,
      },
      shape
    );

    const intl = i18nService.getIntl();
    if (intl) {
      notification.success({
        message: intl.formatMessage(messages.created, {
          name: newTerritory.name,
        }),
      });
    }

    if (userIdsToShareWith?.length) {
      const payload = userIdsToShareWith.map((user) => ({
        groupId: newTerritory.id,
        userId: user,
      }));
      yield callApi("bulkCreateGroupShare", org.id, payload);
    }

    // There's a timeout after shared-territories api requests and the moment territory is actually updated.
    // so, we're using a workaround for now. For more info: https://mapmycustomers.slack.com/archives/C03PGD7BLBY/p1663156032349549
    newTerritory.userIds = userIdsToShareWith ?? [];

    // fake shape
    newTerritory.territoryDetail.shape = shape;

    onSuccess(newTerritory);
    yield put(createTerritory.success({ territory: newTerritory }));
  } catch (error) {
    yield put(createTerritory.failure(error));
    yield put(handleError({ error }));
  }
}
export function* onUpdateTerritory({
  payload: { onSuccess, shape, territory },
}: ReturnType<typeof updateTerritory.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const updatedTerritory: Territory = yield callApi("updateTerritory", orgId, territory, shape);

    // fake shape
    updatedTerritory.territoryDetail.shape = shape ?? territory.territoryDetail.shape;

    yield put(updateTerritory.success({ territory: updatedTerritory }));
    if (onSuccess) {
      yield call(onSuccess, updatedTerritory);
    }
  } catch (error) {
    yield put(updateTerritory.failure());
    yield put(handleError({ error }));
  }
}

export function* onDeleteTerritory({
  payload: { onSuccess, territoryId },
}: ReturnType<typeof deleteTerritory.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    yield callApi("deleteTerritory", orgId, territoryId);
    yield put(deleteTerritory.success({ territoryId }));
    if (onSuccess) {
      yield call(onSuccess, territoryId);
    }
    const intl = i18nService.getIntl();
    if (intl) {
      notification.success({
        message: intl.formatMessage(messages.deleted),
      });
    }
  } catch (error) {
    yield put(deleteTerritory.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onUpdateTerritorySharing({
  payload: { onSuccess, territory, userIdsToShareWith },
}: ReturnType<typeof updateTerritorySharing.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);

    const usersToAdd = userIdsToShareWith.filter((id) => !territory.userIds?.includes(id));
    const usersToDelete = (territory.userIds ?? []).filter(
      (id) => !userIdsToShareWith.includes(id)
    );
    const createPayload = (userIds: User["id"][]) =>
      userIds.map((userId) => ({ groupId: territory.id, userId }));

    yield all([
      usersToAdd.length
        ? callApi("bulkCreateGroupShare", orgId, createPayload(usersToAdd))
        : undefined,
      usersToDelete.length
        ? callApi("bulkDeleteGroupShare", orgId, createPayload(usersToDelete))
        : undefined,
    ]);

    const updatedTerritory: Territory = yield callApi("fetchTerritory", orgId, territory.id);
    // There's a timeout after shared-territories api requests and the moment territory is actually updated.
    // so, we're using a workaround for now. For more info: https://mapmycustomers.slack.com/archives/C03PGD7BLBY/p1663156032349549
    updatedTerritory.userIds = userIdsToShareWith;

    const intl = i18nService.getIntl();
    if (intl) {
      notification.success({
        message: intl.formatMessage(messages.updated, {
          name: updatedTerritory.name,
        }),
      });
    }

    yield put(updateTerritorySharing.success({ territory: updatedTerritory }));
    if (onSuccess) {
      yield call(onSuccess, updatedTerritory);
    }
  } catch (error) {
    yield put(updateTerritorySharing.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onShowTerritories({
  payload: visibleTerritoryIds,
}: ReturnType<typeof showTerritories>) {
  const territories: Territory[] = yield select(getTerritories);
  const visibleTerritoryIdsSet = new Set(visibleTerritoryIds);
  const hiddenTerritoryIds = territories
    .filter(({ id }) => !visibleTerritoryIdsSet.has(id))
    .map(({ id }) => id);
  yield put(hideTerritories(hiddenTerritoryIds));
}

export function* onPersistHiddenTerritoriesIds({
  payload: hiddenTerritoriesIds,
}: ReturnType<typeof hideTerritories>) {
  yield put(updateMetadata.request({ hiddenTerritoriesIds }));
}

const regularFetchActionMatcher = (action: Action): boolean =>
  isActionOf(fetchAllTerritories.request, action) && (!action.payload || !action.payload.force);

const forcedFetchActionMatcher = (action: Action): boolean =>
  isActionOf(fetchAllTerritories.request, action) && !!action.payload?.force;

export function* territoriesSaga() {
  yield takeLatest(fetchTerritories.request, onFetchTerritories);
  yield takeLeading(regularFetchActionMatcher, onFetchAllTerritories);
  yield takeLatest(forcedFetchActionMatcher, onFetchAllTerritories);
  yield takeEvery(createTerritory.request, onCreateTerritory);
  yield takeEvery(updateTerritory.request, onUpdateTerritory);
  yield takeEvery(deleteTerritory.request, onDeleteTerritory);
  yield takeEvery(updateTerritorySharing.request, onUpdateTerritorySharing);
  yield takeEvery(hideTerritories, onPersistHiddenTerritoriesIds);
  yield takeEvery(showTerritories, onShowTerritories);
}
