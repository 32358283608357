import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import SortOrder from "@mapmycustomers/shared/enum/SortOrder";
import {
  AnyEntity,
  EntityType,
  EntityTypesSupportedByMapsPage,
  MapEntity,
} from "@mapmycustomers/shared/types/entity";
import { MapEntry } from "@mapmycustomers/shared/types/map";
import Organization from "@mapmycustomers/shared/types/Organization";
import FilterModel from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import MapViewState from "@mapmycustomers/shared/types/viewModel/MapViewState";
import PlatformFilterModel from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";
import PlatformMapRequest from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformMapRequest";

import localSettings from "@app/config/LocalSettings";
import ViewMode from "@app/enum/dashboard/ViewMode";
import { OUT_OF_FREQUENCY_ENTITY_TYPES } from "@app/scene/dashboard/components/cards/OutOfFrequency/consts";
import {
  getOutOfFrequencyDrillDownTotalFilteredRecords,
  getOutOfFrequencyDrillDownViewStates,
} from "@app/scene/dashboard/store/cards/outOfFrequency/selectors";
import DrillDownViewState from "@app/scene/dashboard/types/DrillDownViewState";
import getPrecision from "@app/scene/map/utils/getPrecision";
import { ApiMethodName } from "@app/store/api/ApiService";
import { callApi } from "@app/store/api/callApi";
import { handleError } from "@app/store/errors/actions";
import { exportEntities } from "@app/store/exportEntities/actions";
import { getOrganizationId, isBigOrganization } from "@app/store/iam";
import { getMapViewSettings } from "@app/store/map";
import convertMapFilterModelToFilterModelForEntity from "@app/store/savedFilters/convertMapFilterModelToFilterModel";
import EntityTypesSupportedInOutOfFrequency from "@app/types/dashboard/cards/outOfFrequency/EntityTypesSupportedInOutOfFrequency";
import { CompanyFieldName } from "@app/util/fieldModel/CompanyFieldModel";
import { DealFieldName } from "@app/util/fieldModel/DealFieldModel";
import getFieldModelByEntityType from "@app/util/fieldModel/getByEntityType";
import FieldModel from "@app/util/fieldModel/impl/FieldModel";
import { PersonFieldName } from "@app/util/fieldModel/PersonFieldModel";
import loggingService from "@app/util/logging";
import categorizeMapEntries from "@app/util/map/categorizeMapEnties";
import convertMapPlatformFilterToMapFilterModel from "@app/util/viewModel/convertMapPlatformFilterToMapFilterModel";
import { convertToPlatformSortModel } from "@app/util/viewModel/convertSort";
import { convertToPlatformFilterModel } from "@app/util/viewModel/convertToPlatformFilterModel";

import getDefaultDrillDownColumns from "../../utils/getDefaultDrillDownColumns";

import {
  applyOutOfFrequencyDrillDownListViewSettings,
  exportOutOfFrequencyCardDrillDownData,
  fetchOutOfFrequencyCardData,
  fetchOutOfFrequencyCardDrillDownData,
  showOutOfFrequencyDrillDown,
} from "./actions";

const LIST_PAGE_SIZE = 20;

const defaultColumns: Record<EntityTypesSupportedInOutOfFrequency, string[]> = {
  [EntityType.COMPANY]: [
    CompanyFieldName.NAME,
    CompanyFieldName.USER,
    CompanyFieldName.ADDRESS,
    CompanyFieldName.LAST_ACTIVITY_DATE,
    CompanyFieldName.FREQUENCY_INTERVAL,
    CompanyFieldName.FREQUENCY_STATUS,
  ],
  [EntityType.DEAL]: [
    DealFieldName.NAME,
    DealFieldName.USER,
    DealFieldName.ADDRESS,
    DealFieldName.LAST_ACTIVITY_DATE,
    DealFieldName.FREQUENCY_INTERVAL,
    DealFieldName.FREQUENCY_STATUS,
  ],
  [EntityType.PERSON]: [
    PersonFieldName.NAME,
    PersonFieldName.USER,
    PersonFieldName.ADDRESS,
    PersonFieldName.LAST_ACTIVITY_DATE,
    PersonFieldName.FREQUENCY_INTERVAL,
    PersonFieldName.FREQUENCY_STATUS,
  ],
};

const defaultSortColumn: Record<EntityTypesSupportedInOutOfFrequency, string> = {
  [EntityType.COMPANY]: CompanyFieldName.LAST_ACTIVITY_DATE,
  [EntityType.DEAL]: DealFieldName.LAST_ACTIVITY_DATE,
  [EntityType.PERSON]: PersonFieldName.LAST_ACTIVITY_DATE,
};

const fetchMethodByEntityType: Record<EntityTypesSupportedInOutOfFrequency, ApiMethodName> = {
  [EntityType.COMPANY]: "fetchCompanies",
  [EntityType.DEAL]: "fetchDeals",
  [EntityType.PERSON]: "fetchPeople",
};

function* onFetchOutOfFrequencyCardData({
  payload,
}: ReturnType<typeof fetchOutOfFrequencyCardData>) {
  const { callback, configuration, failureCallback, offset, scope, viewMode, viewport } = payload;
  const { criteria, extraFilters } = configuration;
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const mapViewState: MapViewState = yield select(getMapViewSettings);

    const bigOrganization: boolean = yield select(isBigOrganization);

    const entityTypes =
      Array.isArray(criteria.entityTypes) && criteria.entityTypes.length
        ? criteria.entityTypes
        : OUT_OF_FREQUENCY_ENTITY_TYPES;

    const payload: PlatformMapRequest = {
      $filters: {
        $and: [
          { cadenceDaysOut: { $gt: 0 } },
          ...(!scope?.userId && scope?.teamId ? [{ teamId: { $in: [scope.teamId] } }] : []),
          ...(scope?.userId ? [{ userId: { $in: [scope.userId] } }] : []),
          ...(criteria.sources?.length ? [{ source: { $in: criteria.sources } }] : []),
        ],
        cadence: true,
        entities: entityTypes.reduce(
          (result, entityType) => ({
            ...result,
            [entityType]: {
              ...((
                extraFilters?.entities as
                  | Partial<Record<EntityTypesSupportedByMapsPage, PlatformFilterModel>>
                  | undefined
              )?.[entityType] ?? {}),
              includeCustomFields: true,
              includeGroups: true,
              includeNotes: true,
              includeRoutes: true,
              includeTerritories: true,
            },
          }),
          {}
        ),
        includeCustomFields: true,
        pinLegends: entityTypes.reduce(
          (result, entityType) => ({
            ...result,
            [entityType]: {
              color: mapViewState.colorKey![entityType],
              shape: mapViewState.shapeKey![entityType],
            },
          }),
          {}
        ),
        ...(viewMode === ViewMode.MAP
          ? {
              bounds: viewport?.bounds,
              precision: getPrecision(viewport?.zoom ?? 1, bigOrganization),
              precisionThreshold: 1000,
            }
          : {}),
      },
      $limit: viewMode === ViewMode.LIST ? LIST_PAGE_SIZE : undefined,
      $offset: offset,
      $order: "-cadenceDaysOut",
    };

    if (viewMode === ViewMode.MAP) {
      const response: ListResponse<MapEntry> = yield callApi("fetchMapPins", orgId, payload);
      yield call(callback, {
        categorizedMapEntries: categorizeMapEntries(response.data),
        total: response.total,
      });
    } else {
      const response: ListResponse<MapEntity> = yield callApi("fetchPins", orgId, payload);
      yield call(callback, { entities: response.data, total: response.total });
    }
  } catch (error) {
    yield put(handleError({ error }));
    if (failureCallback) {
      yield call(failureCallback);
    }
  }
}

function* onOpenDrillDown({ payload }: ReturnType<typeof showOutOfFrequencyDrillDown.request>) {
  const { configuration, scope } = payload;
  const { criteria, extraFilters } = configuration;

  const viewStates: Record<EntityTypesSupportedInOutOfFrequency, DrillDownViewState> = yield select(
    getOutOfFrequencyDrillDownTotalFilteredRecords
  );

  const entityTypes =
    Array.isArray(criteria.entityTypes) && criteria.entityTypes.length
      ? criteria.entityTypes
      : OUT_OF_FREQUENCY_ENTITY_TYPES;

  entityTypes.forEach((entityType) => {
    const fieldModel = getFieldModelByEntityType(entityType);
    const viewState = localSettings.getViewSettings(
      `dashboard/outOfFrequency/${entityType}`,
      fieldModel,
      {
        ...viewStates[entityType],
        columns: getDefaultDrillDownColumns(defaultColumns[entityType], fieldModel),
        sort: [
          {
            field: fieldModel.getByName(defaultSortColumn[entityType])!,
            order: SortOrder.DESC,
          },
        ],
      }
    );

    viewStates[entityType] = viewState;
  });

  const mapFilterModel = convertMapPlatformFilterToMapFilterModel(extraFilters ?? {});

  entityTypes.forEach((entityType) => {
    const viewState: DrillDownViewState = viewStates[entityType];

    const isTeamScope = !!scope?.teamId && !scope?.userId;

    const filter: FilterModel = {
      ...convertMapFilterModelToFilterModelForEntity(entityType, mapFilterModel),
      cadenceDaysOut: {
        operator: FilterOperator.IS_OVERDUE,
        value: { operator: FilterOperator.IS_OVERDUE, value: undefined },
      },
    };
    if (isTeamScope) {
      filter.team = { operator: FilterOperator.IN_ANY, value: [scope.teamId!] };
    }
    if (scope?.userId) {
      filter.user = { operator: FilterOperator.IN_ANY, value: [scope.userId] };
    }
    if (criteria.sources.length) {
      filter.sourceCreated = { operator: FilterOperator.IN_ANY, value: criteria.sources };
    }
    viewState.filter = filter;

    viewStates[entityType] = viewState;
  });

  yield put(showOutOfFrequencyDrillDown.success({ viewStates }));
}

export function* onFetchDrillDownData({
  payload,
}: ReturnType<typeof fetchOutOfFrequencyCardDrillDownData.request>) {
  try {
    loggingService.debug("Dashboard: out of frequency card, onFetchDrillDownData", payload);
    if (!payload.updateOnly) {
      // We do not listen to filter returned by AgGrid from PlatformDataSource
      delete payload.request.filter;
    }

    if (!payload.fetchOnlyWithoutFilters) {
      yield put(
        applyOutOfFrequencyDrillDownListViewSettings({
          ...payload.request,
          entityType: payload.entityType,
        })
      );
    }

    const drillDownViewStates: Record<EntityTypesSupportedInOutOfFrequency, DrillDownViewState> =
      yield select(getOutOfFrequencyDrillDownViewStates);
    const drillDownViewState = drillDownViewStates[payload.entityType];

    if (!payload.fetchOnlyWithoutFilters) {
      localSettings.setViewSettings(
        drillDownViewState,
        `dashboard/outOfFrequency/${payload.entityType}`
      );
    }

    if (payload.updateOnly) {
      return;
    }

    const $offset =
      payload.fetchOnlyWithoutFilters && payload.request.range
        ? payload.request.range.startRow
        : drillDownViewState.range.startRow;
    const $limit =
      payload.fetchOnlyWithoutFilters && payload.request.range
        ? payload.request.range.endRow - payload.request.range.startRow
        : drillDownViewState.range.endRow - drillDownViewState.range.startRow;

    const orgId: Organization["id"] = yield select(getOrganizationId);
    const fieldModel: FieldModel = yield call(getFieldModelByEntityType, payload.entityType!);
    const requestPayload = {
      $filters: {
        includeAccessStatus: true,
        ...convertToPlatformFilterModel(
          payload.fetchOnlyWithoutFilters ? {} : drillDownViewState.filter,
          drillDownViewState.columns,
          fieldModel,
          true,
          drillDownViewState.viewAs
        ),
      },
      $limit,
      $offset,
      $order: convertToPlatformSortModel(drillDownViewState.sort),
    };

    const fetchMethod = fetchMethodByEntityType[payload.entityType];
    const response: ListResponse<AnyEntity> = yield callApi(fetchMethod, orgId, requestPayload);
    if (payload.dataCallback) {
      yield call(payload.dataCallback, response);
    }
    yield put(
      fetchOutOfFrequencyCardDrillDownData.success({
        entityType: payload.entityType,
        totalFilteredRecords: response.total,
        totalRecords: response.accessible,
      })
    );
  } catch (error) {
    payload.failCallback?.();
    yield put(fetchOutOfFrequencyCardDrillDownData.failure(error));
    yield put(handleError({ error }));
  }
}

function* onExport({ payload }: ReturnType<typeof exportOutOfFrequencyCardDrillDownData>) {
  const drillDownViewStates: Record<EntityTypesSupportedInOutOfFrequency, DrillDownViewState> =
    yield select(getOutOfFrequencyDrillDownViewStates);
  const total: Record<EntityTypesSupportedInOutOfFrequency, number> = yield select(
    getOutOfFrequencyDrillDownTotalFilteredRecords
  );

  yield put(
    exportEntities.request({
      entityType: payload.entityType,
      total: total[payload.entityType],
      viewState: drillDownViewStates[payload.entityType],
    })
  );
}

export function* outOfFrequencySagas() {
  // we use takeEvery because there might be several cards of such type on board
  // however, it would be more optimal to use takeLatest, but also filter by card ids
  yield takeEvery(fetchOutOfFrequencyCardData, onFetchOutOfFrequencyCardData);
  yield takeLatest(showOutOfFrequencyDrillDown.request, onOpenDrillDown);
  yield takeLatest(fetchOutOfFrequencyCardDrillDownData.request, onFetchDrillDownData);
  yield takeLatest(exportOutOfFrequencyCardDrillDownData, onExport);
}
