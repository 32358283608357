import React from "react";

import Button from "antd/es/button";

import styles from "./getNotificationNodeWithAction.module.scss";

const getNotificationNodeWithAction = (message: string, action?: string, onClick?: () => void) => (
  <div className={styles.container}>
    <span>{message}</span>
    {action && onClick && <Button onClick={onClick}>{action}</Button>}
  </div>
);

export default getNotificationNodeWithAction;
