import FieldVisibility from "@mapmycustomers/shared/enum/FieldVisibility";

import AddressPart from "@app/enum/AddressPart";

export const METERS_IN_MILE = 1609.34;
export const FEET_IN_MILE = 5280;

export const SHOULD_USE_12_HOUR_FORMAT = new Date(2000, 1, 1, 1, 0, 0)
  .toLocaleString()
  .includes("AM");

export const IMPORT_FILE_UPLOAD_LIMIT = 50 * 1024 * 1024; // 50MB;

export const RECORD_FILE_UPLOAD_LIMIT = 250 * 1024 * 1024; // 250MB;

export const USER_IMAGE_FILE_UPLOAD_LIMIT = 50 * 1024 * 1024; // 50MB;
export const USER_IMAGE_FILE_TYPES = ["image/jpeg", "image/png"];

// FIXME: pls never use this const:
export const NEGATIVE_ENTITY_ID = -1;

export const DEFAULT_PAGE_SIZE = 100;

export const SHORT_ADDRESS: Readonly<AddressPart[]> = [
  AddressPart.ADDRESS,
  AddressPart.CITY,
  AddressPart.STATE,
  AddressPart.POSTAL_CODE,
];

export const FULL_ADDRESS: Readonly<AddressPart[]> = [
  AddressPart.ADDRESS,
  AddressPart.CITY,
  AddressPart.STATE,
  AddressPart.POSTAL_CODE,
  AddressPart.COUNTRY,
];

export const PREVIEW_MODAL_LIMIT = 25;
export const MAX_PINNED_DASHBOARDS = 5;

export const DEAL_PREVIEW_MODAL_LIMIT = 25;

export const MAX_ROUTE_STOPS = 69;

export const DUPLICATE_SEARCH_RADIUS = 10;

export const DEFAULT_NOTIFICATION_DURATION = 5;
export const DEFAULT_ACTIVITY_PERIOD_DURATIONS = [60, 45, 30, 20, 15, 10, 5];

export const NOTIFICATION_DURATION_WITH_ACTION = 10;
export const MAX_AGGREGATION_LIMIT = 100;

export const MAX_DEAL_SCORE = 100;

export const AVAILABLE_UI_LANGUAGES = ["en", "es", "pt"];

// In certain cases we do need to move modal towards higher z-index value (default value is 1000).
// For example when we have modal invoked from submenu, while keeping this submenu visible. Like in groups bulk editing.
// In such case we do value of submenu' zIndex (which is 1050 by default and couldn't be declaratively changed)
// conflicting with modal popup, causing wrong visual look.
// Modal component allows us to change zIndex nicely without any hacks.
export const TOPMOST_MODAL_ZINDEX = 1050;

export const ACTIVITY_DEFAULT_REMIND_TIME = 10;

export const EXPORT_RECORD_COUNT_LIMIT = 100000;

export const EMPTY_AGGREGATION_KEY = "[empty]";

export const TEXT_LENGTH_LIMIT = 255;

export const EMPTY_ADDRESS = {
  address: "",
  city: "",
  country: "",
  countryCode: undefined,
  postalCode: "",
};

export const DEFAULT_FIELD_VISIBILITY = FieldVisibility.PRIVATE;

export const PASSWORD_MINIMUM_LENGTH = 12;

export const MINUTES_IN_HOUR = 60;
export const MINUTES_IN_DAY = MINUTES_IN_HOUR * 24;
export const MINUTES_IN_WEEK = MINUTES_IN_DAY * 7;
