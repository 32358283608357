import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";

import Form from "antd/es/form";
import cn from "classnames";
import { isPast } from "date-fns/esm";
import { FieldData } from "rc-field-form/lib/interface";
import { defineMessage, FormattedMessage } from "react-intl";

import ApiError from "@mapmycustomers/shared/util/api/ApiError";
import { Alert, LoadingSpinner } from "@mapmycustomers/ui";

import commonStyles from "@app/scene/auth/component/AuthCommon.module.scss";
import signupAnalytics from "@app/scene/auth/signupAnalytics";
import {
  getError,
  getInvitationInfo,
  isInvitationInfoLoading,
  isSignUpLoading,
} from "@app/store/auth";
import { fetchInvitationInfo, signUpMember } from "@app/store/auth/actions";
import { RootState } from "@app/store/rootReducer";
import InvitationInfo from "@app/types/auth/InvitationInfo";
import getErrorMessage from "@app/util/errorHandling/getErrorMessage";
import isValidDate from "@app/util/isValidDate";
import { parseApiDate } from "@app/util/parsers";
import validatePasswords, {
  NOTHING_VALID,
  PasswordValidationResult,
} from "@app/util/validatePasswords";

import SignupField from "../../enum/SignupField";
import SignupFormValues from "../../types/SignupFormValues";
import styles from "../RegisterForm.module.scss";

import ExpiredCodeError from "./ExpiredCodeError";
import InvalidCodeError from "./InvalidCodeError";
import RegisterMemberTitle from "./RegisterMemberTitle";
import ContactInfoStep from "./steps/ContactInfoStep";
import InvitationInfoStep from "./steps/InvitationInfoStep";
import PasswordStep from "./steps/PasswordStep";
import useStepValidator from "./steps/useStepValidator";

const genericErrorMessage = defineMessage({
  id: "auth.registerMember.error.unknown",
  defaultMessage: "Server issue, please try again later or contact support",
  description: "A generic member registration failed error message text",
});

const DEFAULT_INITIAL_VALUES: Partial<SignupFormValues> = {
  // need to default all checkboxes to some value, otherwise their value is undefined and
  // field doesn't pass validation by useStepValidator hook (see areStepFieldsValid method there)
  [SignupField.AGREE_WITH_TNC]: false,
  [SignupField.PROMO_EMAILS]: false,
};

interface Props {
  code: string;
  email: string;
  error?: ApiError;
  fetchInvitationInfo: (invitationCode: string) => void;
  invitationInfo: InvitationInfo | undefined;
  loading: boolean;
  signingUp: boolean;
  signUpMember: typeof signUpMember.request;
}

export const RegisterMember: React.FC<Props> = ({
  code,
  email,
  error,
  fetchInvitationInfo,
  invitationInfo,
  loading,
  signingUp,
  signUpMember,
}) => {
  useEffect(() => {
    fetchInvitationInfo(code);
  }, [code, fetchInvitationInfo]);

  const [form] = Form.useForm<SignupFormValues>();

  // Registration pages steps handling
  const [currentStep, setCurrentStep] = useState<number>(0);
  const handleBackClick = useCallback(() => {
    signupAnalytics.clicked(["Go back"]);
    setCurrentStep((currentStep) => (currentStep + 3 - 1) % 3);
  }, [setCurrentStep]);
  const handleNextClick = useCallback(() => {
    signupAnalytics.clicked(["Next step"]);
    setCurrentStep((currentStep) => (currentStep + 1) % 3);
  }, [setCurrentStep]);

  const [onFormFieldsChange, validity] = useStepValidator(form);
  const [passwordValidationResult, setPasswordValidationResult] =
    useState<PasswordValidationResult>(NOTHING_VALID);
  const handleFormFieldsChange = useCallback(
    (changedFields: FieldData[]) => {
      onFormFieldsChange(changedFields);
      setPasswordValidationResult(
        validatePasswords(
          form.getFieldValue(SignupField.PASSWORD),
          form.getFieldValue(SignupField.PASSWORD_CONFIRMATION)
        )
      );
    },
    [form, onFormFieldsChange, setPasswordValidationResult]
  );

  const errorMessage = error ? getErrorMessage(error, {}, genericErrorMessage) : undefined;

  if (loading) {
    return <LoadingSpinner global />;
  }

  if (!invitationInfo) {
    return <InvalidCodeError />;
  }

  const invitationExpirationDate = parseApiDate(invitationInfo.invitationCodeExpiresAt);
  if (!isValidDate(invitationExpirationDate) || isPast(invitationExpirationDate)) {
    return <ExpiredCodeError />;
  }

  return (
    <Form
      className={commonStyles.container}
      form={form}
      initialValues={{
        ...DEFAULT_INITIAL_VALUES,
        [SignupField.EMAIL]: email,
        [SignupField.INVITATION_CODE]: code,
      }}
      layout="vertical"
      onFieldsChange={handleFormFieldsChange}
      onFinish={signUpMember}
    >
      <Form.Item hidden name={SignupField.INVITATION_CODE} />
      {errorMessage && <Alert message={errorMessage} type="error" />}
      <RegisterMemberTitle stepIndex={currentStep} />
      <InvitationInfoStep
        className={cn({ [styles.hidden]: currentStep !== 0 })}
        form={form}
        info={invitationInfo}
        isValid
        onNextClick={handleNextClick}
      />
      <ContactInfoStep
        className={cn({ [styles.hidden]: currentStep !== 1 })}
        form={form}
        isValid={validity.isContactsStepValid}
        onBackClick={handleBackClick}
        onNextClick={handleNextClick}
        title={
          <FormattedMessage
            id="auth.registerMember.contactInfoStep.description"
            defaultMessage="Finish signing up to join your team"
            description="Brief description of second step of the member register form"
          />
        }
      />
      <PasswordStep
        className={cn({ [styles.hidden]: currentStep !== 2 })}
        form={form}
        isValid={validity.isPasswordStepValid}
        onBackClick={handleBackClick}
        passwordValidationResult={passwordValidationResult}
        signingUp={signingUp}
      />
    </Form>
  );
};

const mapStateToProps = (state: RootState) => ({
  error: getError(state),
  invitationInfo: getInvitationInfo(state),
  loading: isInvitationInfoLoading(state),
  signingUp: isSignUpLoading(state),
});

const mapDispatchToProps = {
  fetchInvitationInfo: fetchInvitationInfo.request,
  signUpMember: signUpMember.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterMember);
