import type { MutableRefObject, Ref, RefCallback } from "react";
import { useCallback } from "react";

export function applyRef<T>(value: null | T, ref?: Ref<T>): void {
  if (!ref) {
    return;
  }

  if (typeof ref === "function") {
    ref(value);
  } else if (typeof ref === "object") {
    (ref as MutableRefObject<null | T>).current = value;
  }
}

function useSharedRef<T>(...forwardedRefs: Ref<null | T>[]): RefCallback<null | T> {
  return useCallback(
    (value: null | T) => {
      forwardedRefs.forEach((forwardedRef) => {
        applyRef(value, forwardedRef);
      });
    },
    [forwardedRefs]
  );
}

export default useSharedRef;
