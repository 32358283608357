import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";

import { faAngleDown } from "@fortawesome/free-solid-svg-icons/faAngleDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "antd/es/button";
import Checkbox from "antd/es/checkbox";
import Form from "antd/es/form";
import { useForm } from "antd/es/form/Form";
import PopConfirm from "antd/es/popconfirm";
import Select from "antd/es/select";
import cn from "classnames";
import { differenceInMinutes } from "date-fns/esm";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import ActivityCompletion from "@mapmycustomers/shared/enum/activity/ActivityCompletion";
import ActivityVisibility from "@mapmycustomers/shared/enum/activity/ActivityVisibility";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import Role from "@mapmycustomers/shared/enum/Role";
import Visibility from "@mapmycustomers/shared/enum/Visibility";
import { Activity, EntityType } from "@mapmycustomers/shared/types/entity";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import Team from "@mapmycustomers/shared/types/Team";
import User from "@mapmycustomers/shared/types/User";
import stringHash from "@mapmycustomers/shared/util/hash/stringHash";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import useRerenderer from "@mapmycustomers/shared/util/hook/useRerenderer";
import { Alert, LoadingSpinner, SelectField, TextAreaWithMentions } from "@mapmycustomers/ui";

import AssigneeSearch from "@app/component/activity/AssigneeSearch";
import ConditionalFormField, {
  ConditionalFormFieldRenderProps,
} from "@app/component/ConditionalFormField";
import { useRecurIntervalTypeValueChangeHandler } from "@app/component/createEditEntity/Activity/utils/useRecurIntervalTypeValueChangeHandler";
import FormFields from "@app/component/FormFields";
import {
  CompanyRelationshipField,
  DealRelationshipField,
  PersonRelationshipField,
} from "@app/component/FormFields/components/Associations";
import FormLayoutSelector from "@app/component/FormLayoutSelector";
import FormNoLayoutAlert from "@app/component/FormNoLayoutAlert";
import VisibilitySelectField from "@app/component/input/VisibilitySelectField";
import BaseModal from "@app/component/modal/BaseModal";
import OrganizationSetting from "@app/enum/OrganizationSetting";
import { getActivityTypes } from "@app/store/activity";
import AssociationsState from "@app/store/associations/AssociationsState";
import { getEmailActivityType } from "@app/store/email";
import { getFocusedFieldName } from "@app/store/entityView";
import { addUserRecent } from "@app/store/globalSearch/actions";
import { getCurrentUser, getMe, getOrganizationSettingValue } from "@app/store/iam";
import { isManager, isOwner } from "@app/store/iam/util";
import { getTeamsOfEntireOrganization, getUsersOfEntireOrganization } from "@app/store/members";
import { RootState } from "@app/store/rootReducer";
import HideAction from "@app/types/HideCallback";
import Iam from "@app/types/Iam";
import { useExtendedAnalytics } from "@app/util/analytic/AnalyticsService";
import AnalyticsContext from "@app/util/contexts/AnalyticsContext";
import useAnalytics from "@app/util/contexts/useAnalytics";
import isEmailActivity from "@app/util/email/isEmailActivity";
import activityFieldModel, { ActivityFieldName } from "@app/util/fieldModel/ActivityFieldModel";
import useFormChangeTracker from "@app/util/hook/form/useFormChangeTracker";
import useActivityVisibilityOptions from "@app/util/hook/useActivityVisibilityOptions";
import useCustomFieldValidation from "@app/util/hook/useCustomFieldValidation";
import useErroredFieldsScroller from "@app/util/hook/useErroredFieldsScroller";
import isValidDate from "@app/util/isValidDate";
import { activityLayoutModel } from "@app/util/layout/impl";
import isRequiredLayoutSchemaField from "@app/util/schema/isRequiredLayoutSchemaField";
import { getActivityVisibilityNote } from "@app/util/ui";
import useActivityTypeOptions from "@app/util/ui/useActivityTypeOptions";

import EditActivityFocusableField from "../../activity/type/EditActivityFocusableField";
import NotificationSettings from "../NotificationSettings";

import CheckInActivityAlert from "./CheckInActivityAlert";
import ActivityFooter from "./component/ActivityFooter";
import ActivityNameField from "./component/ActivityNameField";
import EmailSection from "./component/EmailSection";
import File from "./component/File";
import LockedFile from "./component/LockedFile";
import LockedActivityType from "./component/LockedFormFields/LockedActivityType";
import LockedAssignee from "./component/LockedFormFields/LockedAssignee";
import LockedEntityType from "./component/LockedFormFields/LockedEntityType";
import NoAccess from "./component/NoAccess";
import RelatedEntitiesMatching from "./component/RelatedEntitiesMatching";
import SubmitButton from "./component/SubmitButton";
import styles from "./CreateEditActivityModal.module.scss";
import { RecurringDeleteDropdown } from "./RecurringDeleteDropdown";
import {
  getAssociationsState,
  getEditedActivity,
  hasNoAccess,
  isActivityComplete,
  isDeleting,
  isFilesListChanged,
  isInitializing,
  isUpdating,
} from "./store";
import {
  changeAssociatedEntities,
  clearActivity,
  clearAllUploadedCreateEditActivityFiles,
  clearAssociations,
  deleteActivity,
  initializeEditActivityModal,
  relateUnrelateEntities,
  updateActivity,
} from "./store/actions";
import FormValues from "./types/FormValues";
import fillOutRequiredFieldsMessage from "./utils/fillOutRequiredFieldsMessage";
import getFormInitialValues from "./utils/getFormInitialValues";
import inaccessibleEntityAlertMessage from "./utils/inaccessibleEntityAlertMessage";
import useActivityTypeValidationRules from "./utils/useActivityTypeValidationRules";
import useDeleteCallback from "./utils/useDeleteCallback";
import { useIsRecurrentValueChangeHandler } from "./utils/useIsRecurrentValueChangeHandler";
import useMentionsValidation from "./utils/useMentionsValidation";
import { useModalConfirm } from "./utils/useModalConfirm";
import { useRecurIntervalChangedWarningHandler } from "./utils/useRecurIntervalChangedWarningHandler";
import useSaveModeHandler from "./utils/useSaveModeHandler";
import useUpdateCallback from "./utils/useUpdateCallback";

export const messages = defineMessages({
  requiredFields: {
    id: "createActivityModal.requiredFieldAlert",
    defaultMessage: "Fill out all required fields to log activity.",
    description: "Required Field alert while logging activity",
  },
});

interface Props {
  activity?: Activity;
  activityCompleted: boolean;
  // specify activity id, rest of data is fetched by the modal itself
  activityId: Activity["id"];
  activityTypes: ActivityType[];
  addUserRecent: typeof addUserRecent;
  associations: AssociationsState;
  className?: string;
  clearActivity: typeof clearActivity;
  clearAllUploadedFiles: () => void;
  clearAssociations: typeof clearAssociations;
  currentUser: User;
  defaultCompletion: boolean;
  defaultVisibility: ActivityVisibility;
  deleteActivity: typeof deleteActivity.request;
  deleting: boolean;
  emailActivityType?: ActivityType;
  filesListChanged: boolean;
  focusedFieldName?: EditActivityFocusableField;
  initialize: typeof initializeEditActivityModal.request;
  initializing: boolean;
  me: Iam;
  noAccess?: boolean;
  onChangeAssociatedEntities: typeof changeAssociatedEntities.request;
  onHide: HideAction<Activity>;
  onRelateUnrelateEntities: typeof relateUnrelateEntities.request;
  overrideValues?: Partial<Activity>;
  requiredFieldsOnly?: boolean;
  teams: Team[];
  updateActivity: typeof updateActivity.request;
  updating: boolean;
  users: User[];
}

const EditActivityModal: React.FC<Props> = ({
  activity,
  activityCompleted,
  activityId,
  activityTypes,
  addUserRecent,
  associations,
  className,
  clearActivity,
  clearAllUploadedFiles,
  clearAssociations,
  currentUser,
  defaultCompletion,
  defaultVisibility,
  deleteActivity,
  deleting,
  emailActivityType,
  filesListChanged,
  initialize,
  initializing,
  me,
  noAccess,
  onChangeAssociatedEntities,
  onHide,
  onRelateUnrelateEntities,
  overrideValues,
  requiredFieldsOnly,
  teams,
  updateActivity,
  updating,
  users,
}) => {
  const intl = useIntl();
  const analyticIssuer = useExtendedAnalytics(["Edit Activity"], useAnalytics());

  const forceRender = useRerenderer();

  useEffect(() => {
    initialize({ activityId, overrideValues });
  }, [activityId, overrideValues, initialize]);

  const [formLayout, setFormLayout] = useState(activityLayoutModel.defaultFormLayout);
  const [formFieldsRef, handleFinishFail] = useErroredFieldsScroller();

  useEffect(() => {
    if (activity) {
      setFormLayout(activityLayoutModel.getLayoutFor(activity));
    }
  }, [activity]);

  const [shouldCreateAnother, , , toggleCreateAnother] = useBoolean(false);
  const handleCreateAnotherChange = useCallback(() => toggleCreateAnother(), [toggleCreateAnother]);
  const visibilityOptions = useActivityVisibilityOptions(isOwner(me), isManager(me));

  const [form] = useForm<FormValues>();
  const [isFormChanged, handleFormChanges] = useFormChangeTracker(form);

  const updatedActivityCompleted = form.getFieldValue(ActivityFieldName.COMPLETED);
  const activityMarkedDone =
    !!overrideValues?.completed ||
    (updatedActivityCompleted && updatedActivityCompleted !== activityCompleted);

  const isCheckInActivity = activity?.reliability != null;
  const isCheckInActivityVerified = activity?.reliability === true;

  const teamMemberIds = users.filter(({ role }) => role.key !== Role.OWNER).map((user) => user.id);
  const isCreatedByUserInTeam = !!activity && teamMemberIds.includes(activity.user.id);
  const isAssignedToUserInTeam =
    !!activity?.assignee && teamMemberIds.includes(activity.assignee.id);
  const teamOrPublicVisibility =
    activity?.visibility === Visibility.SHARED_WITH_ORGANIZATION ||
    activity?.visibility === Visibility.SHARED_WITH_TEAM;
  const isAssignee = activity?.assignee?.id === currentUser.id;
  const isCreator = activity?.user.id === currentUser.id;
  const isAssigneeNotCreator =
    activity?.assignee?.id === currentUser.id && currentUser.id !== activity?.user.id;

  let canChangeVisibility = false;
  if (isOwner(me) || isCreator) {
    canChangeVisibility = true;
  } else if (isManager(me)) {
    // managers can change visibility if:
    // visibility is public or team
    // AND activity was created by or assigned to anybody in their team
    // BUT not when manager is assignee and creator is not on their team
    canChangeVisibility =
      teamOrPublicVisibility &&
      (isCreatedByUserInTeam || isAssignedToUserInTeam) &&
      !(isAssignee && !isCreatedByUserInTeam);
  }

  const handleHide = useCallback(
    (updated?: boolean, entity?: Activity) => {
      // we need this call because if we don't clear the activity from the store, the very next
      // time EditActivity modal is open, it'll use that previous value. Even though there will
      // be a new activityId, the old activity will be already used to initialize the form.
      clearActivity();
      clearAssociations();
      clearAllUploadedFiles();
      onHide(updated, entity);
    },
    [clearActivity, clearAssociations, clearAllUploadedFiles, onHide]
  );
  const handleCancelClick = useCallback(() => handleHide(), [handleHide]);
  const handleCustomFieldValidate = useCustomFieldValidation(form, handleFinishFail);

  const handleFormFinish = useUpdateCallback(
    activity,
    activityTypes,
    form,
    formLayout,
    activityMarkedDone,
    handleHide,
    updateActivity,
    intl,
    analyticIssuer,
    handleCustomFieldValidate
  );

  const handleDelete = useDeleteCallback(activity, deleteActivity, onHide, analyticIssuer);

  const [, allowedUserEmails, handleAllMentionedUsersHaveAccessToActivity] = useMentionsValidation(
    me,
    users,
    associations.associatedCompany,
    associations.associatedDeal,
    associations.associatedPerson,
    activity
  );

  const handleSave = useCallback(() => form.submit(), [form]);
  const [modalConfirm, contextHolder] = useModalConfirm(handleSave);

  const isRecurrentValueChangeHandler = useIsRecurrentValueChangeHandler(form);
  const recurIntervalTypeValueChangeHandler = useRecurIntervalTypeValueChangeHandler(form);
  const [isChangedRecurrentIntervalWarningVisible, handleRecurIntervalChange] =
    useRecurIntervalChangedWarningHandler(activity);

  const handleClickOk = useCallback(() => {
    if (isChangedRecurrentIntervalWarningVisible) {
      modalConfirm();
    } else {
      handleSave();
    }
  }, [handleSave, isChangedRecurrentIntervalWarningVisible, modalConfirm]);

  const [{ isActivityPublic, options, recurChanged, showCheckInSubmit }, saveModeHandler] =
    useSaveModeHandler(activity);

  const handleActivityChange = useCallback(
    (values: FormValues, allValues: FormValues) => {
      if (allValues.endAt && allValues.startAt && allValues.endAt < allValues.startAt) {
        form.setFieldsValue({ endAt: allValues.startAt });
      }
    },
    [form]
  );

  const handleValuesChanges = useCallback(
    (changedValues, values) => {
      [
        isRecurrentValueChangeHandler,
        recurIntervalTypeValueChangeHandler,
        saveModeHandler,
        handleRecurIntervalChange,
        handleFormChanges,
        handleAllMentionedUsersHaveAccessToActivity,
        handleActivityChange,
      ].forEach((fn) => fn(changedValues, values));
    },
    [
      isRecurrentValueChangeHandler,
      recurIntervalTypeValueChangeHandler,
      saveModeHandler,
      handleRecurIntervalChange,
      handleFormChanges,
      handleAllMentionedUsersHaveAccessToActivity,
      handleActivityChange,
    ]
  );

  const handleNonInteractiveChanges = useCallback(() => {
    const formValues = form.getFieldsValue();
    saveModeHandler(formValues, formValues);
    handleFormChanges();
    forceRender();
  }, [forceRender, form, handleFormChanges, saveModeHandler]);

  const handleIsMentionDisabled = useCallback(
    (user: User) => {
      return !allowedUserEmails?.includes(user.username);
    },
    [allowedUserEmails]
  );

  const submitButtonDisabled = initializing || deleting || updating || !formLayout;
  const showConfirm = !initializing && (isFormChanged || filesListChanged);

  const isCompleted = form.getFieldValue(ActivityFieldName.COMPLETED);
  const dateValue = form.getFieldValue(ActivityFieldName.START_AT);
  const isPastDate = isValidDate(dateValue) && differenceInMinutes(new Date(), dateValue) >= 60;

  const unavailableActivityType = useMemo(() => {
    return activityTypes
      .filter(({ active }) => active)
      .some(({ id }) => id === activity?.crmActivityType?.id)
      ? undefined
      : activity?.crmActivityType;
  }, [activity, activityTypes]);

  const activityTypeValidationRules = useActivityTypeValidationRules(intl, unavailableActivityType);
  const activityTypeOptions = useActivityTypeOptions(
    activityTypes,
    form.getFieldValue("activityTypeId"),
    useCallback(
      (type) => !type.active || (isCheckInActivity && !type.checkInEnabled),
      [isCheckInActivity]
    )
  );

  const appendAddableFieldsWithFilter = useCallback(
    (field: IField) => (activity ? field.hasNonEmptyValueFor(activity) : false),
    [activity]
  );

  useEffect(() => {
    const initialValues = getFormInitialValues(
      activity,
      currentUser,
      undefined,
      defaultCompletion,
      defaultVisibility,
      unavailableActivityType
    );
    form.setFieldsValue(initialValues);
  }, [activity, currentUser, defaultCompletion, defaultVisibility, form, unavailableActivityType]);

  useEffect(() => {
    addUserRecent({ entity: { id: activityId, type: EntityType.ACTIVITY } });
  }, [activityId, addUserRecent]);

  return (
    <AnalyticsContext.Provider value={analyticIssuer}>
      <BaseModal
        borderedHeader
        className={cn(styles.modal, className)}
        destroyOnClose
        entityTypeName="Activity"
        extra={
          <RelatedEntitiesMatching
            associatedCompany={associations.associatedCompany}
            associatedDeal={associations.associatedDeal}
            associatedPerson={associations.associatedPerson}
          />
        }
        footer={null}
        noContentScroll
        onCancel={handleCancelClick}
        showConfirm={showConfirm}
        subtitle={
          isPastDate || initializing || !formLayout || isEmailActivity(activity) ? undefined : (
            <NotificationSettings
              disabled={updating}
              form={form}
              onChange={handleNonInteractiveChanges}
            />
          )
        }
        title={
          <>
            {intl.formatMessage({
              id: "editActivityModal.header",
              defaultMessage: "Edit Activity",
              description: "Header of Edit Activity modal",
            })}

            <FormLayoutSelector
              disabled={initializing || updating}
              entityType={EntityType.ACTIVITY}
              layout={formLayout}
              onChange={setFormLayout}
            />
          </>
        }
      >
        {initializing ? (
          <LoadingSpinner />
        ) : noAccess ? (
          <NoAccess onDismiss={handleCancelClick} />
        ) : formLayout ? (
          <div className={styles.formContainer} ref={formFieldsRef}>
            <Form<FormValues>
              className={styles.form}
              form={form}
              initialValues={getFormInitialValues(
                activity,
                currentUser,
                undefined,
                defaultCompletion,
                defaultVisibility,
                unavailableActivityType
              )}
              layout="vertical"
              onFinish={handleFormFinish}
              onFinishFailed={handleFinishFail}
              onValuesChange={handleValuesChanges}
            >
              <div className={styles.content}>
                {associations.inaccessibleEntity && (
                  <Alert
                    className={styles.inaccessibleEntityAlert}
                    message={intl.formatMessage(inaccessibleEntityAlertMessage)}
                    showIcon
                    type="warning"
                  />
                )}

                {requiredFieldsOnly && (
                  <Alert
                    className={styles.inaccessibleEntityAlert}
                    message={intl.formatMessage(fillOutRequiredFieldsMessage)}
                    showIcon
                    type="warning"
                  />
                )}

                {isCheckInActivityVerified && <CheckInActivityAlert />}

                {isEmailActivity(activity) && activity && <EmailSection activity={activity} />}

                <FormFields
                  appendAddableFieldsWithFilter={appendAddableFieldsWithFilter}
                  disallowAdding={requiredFieldsOnly}
                  entityType={EntityType.ACTIVITY}
                  fileComponent={isEmailActivity(activity) ? LockedFile : File}
                  filterFields={requiredFieldsOnly ? isRequiredLayoutSchemaField : undefined}
                  ignoreRequired={!isCompleted}
                  layout={formLayout}
                >
                  {emailActivityType && (
                    <LockedActivityType activityType={emailActivityType} isAutoEmail />
                  )}
                  <ConditionalFormField feature={FieldFeature.ACTIVITY_TYPE_FIELD}>
                    {emailActivityType && isEmailActivity(activity) ? (
                      <LockedActivityType activityType={emailActivityType} isAutoEmail />
                    ) : (
                      ({ disabled, label, required }: ConditionalFormFieldRenderProps) => (
                        <Form.Item
                          label={label}
                          name="activityTypeId"
                          required={required}
                          rules={activityTypeValidationRules}
                        >
                          <SelectField<ActivityType["id"]>
                            className={styles.activityTypeSelect}
                            disabled={disabled}
                            dropdownMatchSelectWidth={false}
                            filterOption={(inputValue, option) =>
                              (
                                activityTypes.find(
                                  (type) => type.id === parseInt(`${option?.value || 0}`)
                                )?.name as string
                              )
                                ?.toLowerCase()
                                .includes(inputValue.toLowerCase().trim()) ?? false
                            }
                            placeholder={
                              unavailableActivityType?.name ??
                              intl.formatMessage({
                                id: "createEditActivityModal.field.activityType.placeholder",
                                defaultMessage: "Select",
                                description:
                                  "Placeholder for the Activity Type field on the Create Activity modal",
                              })
                            }
                            showSearch
                          >
                            {isCheckInActivity && (
                              <Select.Option
                                className={styles.message}
                                disabled
                                key="Activity Types"
                                label={intl.formatMessage({
                                  id: "createEditActivityModal.field.activityType.checkInTypes.message",
                                  defaultMessage:
                                    "Some activity types may be disabled for Check-In activities",
                                  description:
                                    "Message for the CheckIn Activity Types while editing CheckIn Activity",
                                })}
                                value={-1}
                              >
                                {intl.formatMessage({
                                  id: "createEditActivityModal.field.activityType.checkInTypes.message",
                                  defaultMessage:
                                    "Some activity types may be disabled for Check-In activities",
                                  description:
                                    "Message for the CheckIn Activity Types while editing CheckIn Activity",
                                })}
                              </Select.Option>
                            )}
                            {activityTypeOptions.map((type) => (
                              <Select.Option
                                className={cn({ [styles.disabled]: type.disabled })}
                                disabled={type.disabled}
                                key={type.value}
                                label={type.label}
                                value={type.value}
                              >
                                {type.label}
                              </Select.Option>
                            ))}
                          </SelectField>
                        </Form.Item>
                      )
                    )}
                  </ConditionalFormField>

                  <ConditionalFormField fieldName={ActivityFieldName.NAME}>
                    <ActivityNameField activityTypes={activityTypes} />
                  </ConditionalFormField>

                  <ConditionalFormField fieldName={ActivityFieldName.ASSIGNEE}>
                    {({ disabled, label, required }: ConditionalFormFieldRenderProps) =>
                      isEmailActivity(activity) && activity?.assignee ? (
                        <LockedAssignee assignee={activity.assignee} />
                      ) : (
                        <Form.Item
                          label={label}
                          name="assignee"
                          required={required}
                          rules={[{ required }]}
                        >
                          <AssigneeSearch
                            allowedUserEmails={allowedUserEmails}
                            disabled={!canChangeVisibility || disabled}
                            users={users}
                          />
                        </Form.Item>
                      )
                    }
                  </ConditionalFormField>

                  <ConditionalFormField fieldName={ActivityFieldName.COMPANY}>
                    {activity?.emailLog?.entityType === EntityType.COMPANY &&
                    activity?.account?.id ? (
                      <LockedEntityType
                        entityName={activity.account.name}
                        entityType={EntityType.COMPANY}
                      />
                    ) : (
                      ({ disabled, required }: ConditionalFormFieldRenderProps) => (
                        <CompanyRelationshipField
                          associatedCompany={associations.associatedCompany}
                          associatedDeal={associations.associatedDeal}
                          associatedPerson={associations.associatedPerson}
                          disabled={disabled}
                          entityType={EntityType.ACTIVITY}
                          field={activityFieldModel.getByName(ActivityFieldName.COMPANY)!}
                          onChangeAssociatedEntities={onChangeAssociatedEntities}
                          relateEntities={onRelateUnrelateEntities}
                          required={required}
                          suggestedCompanies={associations.availableCompanies}
                        />
                      )
                    )}
                  </ConditionalFormField>

                  <ConditionalFormField fieldName={ActivityFieldName.PERSON}>
                    {activity?.emailLog?.entityType === EntityType.PERSON &&
                    activity?.contact?.id ? (
                      <LockedEntityType
                        entityName={activity.contact.name}
                        entityType={EntityType.PERSON}
                      />
                    ) : (
                      ({ disabled, required }: ConditionalFormFieldRenderProps) => (
                        <PersonRelationshipField
                          associatedCompany={associations.associatedCompany}
                          associatedDeal={associations.associatedDeal}
                          associatedPerson={associations.associatedPerson}
                          disabled={disabled}
                          field={activityFieldModel.getByName(ActivityFieldName.PERSON)!}
                          onChangeAssociatedEntities={onChangeAssociatedEntities}
                          relateEntities={onRelateUnrelateEntities}
                          required={required}
                          suggestedPeople={associations.availablePeople}
                        />
                      )
                    )}
                  </ConditionalFormField>

                  <ConditionalFormField fieldName={ActivityFieldName.DEAL}>
                    {({ disabled, required }: ConditionalFormFieldRenderProps) => (
                      <DealRelationshipField
                        associatedCompany={associations.associatedCompany}
                        associatedDeal={associations.associatedDeal}
                        associatedPerson={associations.associatedPerson}
                        disabled={disabled}
                        field={activityFieldModel.getByName(ActivityFieldName.DEAL)!}
                        onChangeAssociatedEntities={onChangeAssociatedEntities}
                        relateEntities={onRelateUnrelateEntities}
                        required={required}
                        suggestedDeals={associations.availableDeals}
                      />
                    )}
                  </ConditionalFormField>

                  <ConditionalFormField fieldName={ActivityFieldName.NOTE}>
                    {({ disabled, label, required }: ConditionalFormFieldRenderProps) => (
                      <Form.Item
                        dependencies={[ActivityFieldName.NOTE, ActivityFieldName.VISIBILITY]}
                        noStyle
                        shouldUpdate={(prevValues, curValues) =>
                          prevValues.visibility !== curValues.visibility
                        }
                      >
                        {({ getFieldValue }) => (
                          <Form.Item
                            label={label}
                            name={ActivityFieldName.NOTE}
                            required={required}
                            rules={[{ required }]}
                          >
                            <TextAreaWithMentions
                              allUsers={users}
                              disabled={disabled}
                              hideDisabledUsers
                              isMentionDisabled={handleIsMentionDisabled}
                              key={
                                getFieldValue(ActivityFieldName.VISIBILITY) +
                                stringHash(allowedUserEmails?.join() ?? "")
                              }
                              maxLength={Number.MAX_SAFE_INTEGER}
                            />
                          </Form.Item>
                        )}
                      </Form.Item>
                    )}
                  </ConditionalFormField>

                  <ConditionalFormField fieldName={ActivityFieldName.VISIBILITY}>
                    {({ disabled, required }: ConditionalFormFieldRenderProps) => (
                      <Form.Item
                        label={intl.formatMessage({
                          id: "createEditActivityModal.field.visibility",
                          defaultMessage: "Visible to",
                          description:
                            "Visibility field label in Create create edit activity modal",
                        })}
                        required={required}
                        shouldUpdate={(prev, next) =>
                          prev.visibility !== next.visibility || prev.teamIds !== next.teamIds
                        }
                      >
                        {({ getFieldValue, setFieldsValue }) => {
                          const teamIds = getFieldValue("teamIds");
                          const availableTeams = teams.filter(
                            ({ id, viaTeamAccess }) => viaTeamAccess || teamIds?.includes(id)
                          );
                          return (
                            <VisibilitySelectField<ActivityVisibility>
                              disabled={disabled || !canChangeVisibility}
                              noteGetter={getActivityVisibilityNote}
                              onChange={(visibility: ActivityVisibility) =>
                                setFieldsValue({ visibility })
                              }
                              onTeamIdsChange={(teamIds: Team["id"][]) =>
                                setFieldsValue({ teamIds })
                              }
                              options={visibilityOptions}
                              teamIds={teamIds}
                              teams={availableTeams}
                              teamsRenderer={(node) => (
                                <Form.Item name="teamIds" noStyle>
                                  {node}
                                </Form.Item>
                              )}
                              value={getFieldValue(ActivityFieldName.VISIBILITY)}
                              visibilityRenderer={(node) => (
                                <Form.Item name={ActivityFieldName.VISIBILITY} noStyle>
                                  {node}
                                </Form.Item>
                              )}
                            />
                          );
                        }}
                      </Form.Item>
                    )}
                  </ConditionalFormField>
                </FormFields>
              </div>

              <div className={styles.footer}>
                <div className={styles.left}>
                  <ActivityFooter
                    activity={activity}
                    form={form}
                    onChange={handleNonInteractiveChanges}
                  />
                </div>

                <div className={styles.right}>
                  <div className={styles.actions}>
                    <div>
                      {activity?.recurInterval ? (
                        <RecurringDeleteDropdown
                          isAssigneeNotCreator={isAssigneeNotCreator}
                          onDelete={handleDelete}
                        >
                          <Button
                            className={styles.deleteButton}
                            disabled={deleting}
                            loading={deleting}
                          >
                            <FormattedMessage
                              id="editActivityModal.footer.delete"
                              defaultMessage="Delete"
                              description="Delete button title on Create/Edit Activity modal"
                            />{" "}
                            <FontAwesomeIcon icon={faAngleDown} />
                          </Button>
                        </RecurringDeleteDropdown>
                      ) : (
                        <PopConfirm
                          cancelText={intl.formatMessage({
                            id: "editActivityModal.footer.deleteActivity.confirm.cancel",
                            defaultMessage: "Cancel",
                            description: "No-confirmation text for Delete activity from edit modal",
                          })}
                          okText={intl.formatMessage({
                            id: "editActivityModal.footer.deleteActivity.confirm.delete",
                            defaultMessage: "Delete",
                            description:
                              "Yes-confirmation text for Delete activity from edit modal",
                          })}
                          onConfirm={() => handleDelete()}
                          overlayClassName={styles.confirmOverlay}
                          title={intl.formatMessage(
                            {
                              id: "editActivityModal.footer.deleteActivity.confirm.title",
                              defaultMessage:
                                "<b>Are you sure?</b> You have the option to restore deleted records for up to 90 days.",
                              description: "Delete activity from edit modal Confirmation text",
                            },
                            { b: (text) => <b>{text}</b> }
                          )}
                        >
                          <Button
                            className={styles.deleteButton}
                            disabled={deleting}
                            loading={deleting}
                          >
                            <FormattedMessage
                              id="editActivityModal.footer.deleteActivity"
                              defaultMessage="Delete Activity"
                              description="Delete button title on Create/Edit Activity modal"
                            />
                          </Button>
                        </PopConfirm>
                      )}
                    </div>

                    <SubmitButton
                      buttonTitle={intl.formatMessage({
                        id: "editActivityModal.footer.saveChanges",
                        defaultMessage: "Save Changes",
                        description: "Save Changes button title on Edit Activity modal",
                      })}
                      disabled={submitButtonDisabled}
                      form={form}
                      isActivityPublic={isActivityPublic}
                      isAssigneeNotCreator={isAssigneeNotCreator}
                      isRecurChanged={recurChanged}
                      key="submit"
                      loading={initializing || updating}
                      onCreate={handleClickOk}
                      saveOptions={options}
                      showCheckInSubmit={showCheckInSubmit}
                    />
                    {contextHolder}
                  </div>
                  {!isEmailActivity(activity) && (
                    <Checkbox
                      checked={shouldCreateAnother}
                      key="createAnother"
                      onChange={handleCreateAnotherChange}
                    >
                      {intl.formatMessage({
                        id: "editActivityModal.footer.createAnother.title",
                        defaultMessage: "Create another after saving",
                        description: "Create another label on Create Activity modal",
                      })}
                    </Checkbox>
                  )}
                </div>
              </div>
            </Form>
          </div>
        ) : (
          <div className={styles.content}>
            <FormNoLayoutAlert />
          </div>
        )}
      </BaseModal>
    </AnalyticsContext.Provider>
  );
};

export const mapStateToPropsEditActivityModal = (state: RootState) => ({
  activity: getEditedActivity(state),
  activityCompleted: isActivityComplete(state),
  activityTypes: getActivityTypes(state),
  associations: getAssociationsState(state),
  currentUser: getCurrentUser(state)!,
  defaultCompletion:
    getOrganizationSettingValue(state)(OrganizationSetting.ACTIVITY_COMPLETION_STATUS) !==
    ActivityCompletion.INCOMPLETE,
  defaultVisibility: ActivityVisibility.PRIVATE as ActivityVisibility,
  deleting: isDeleting(state),
  emailActivityType: getEmailActivityType(state),
  filesListChanged: isFilesListChanged(state),
  focusedFieldName: getFocusedFieldName(state),
  initializing: isInitializing(state),
  me: getMe(state)!,
  noAccess: hasNoAccess(state),
  teams: getTeamsOfEntireOrganization(state),
  updating: isUpdating(state),
  users: getUsersOfEntireOrganization(state),
});

export const mapDispatchToPropsEditActivityModal = {
  addUserRecent,
  clearActivity,
  clearAllUploadedFiles: clearAllUploadedCreateEditActivityFiles,
  clearAssociations: clearAssociations,
  deleteActivity: deleteActivity.request,
  initialize: initializeEditActivityModal.request,
  onChangeAssociatedEntities: changeAssociatedEntities.request,
  onRelateUnrelateEntities: relateUnrelateEntities.request,
  updateActivity: updateActivity.request,
};

export default connect(
  mapStateToPropsEditActivityModal,
  mapDispatchToPropsEditActivityModal
)(EditActivityModal);
