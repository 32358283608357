import React, { ReactNode } from "react";

import { defineMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";

import ApiError from "@mapmycustomers/shared/util/api/ApiError";

import Path from "@app/enum/Path";
import getErrorMessage from "@app/util/errorHandling/getErrorMessage";

const genericErrorMessage = defineMessage({
  id: "auth.login.error.unknown",
  defaultMessage: "Server issue, please try again later or contact support",
  description: "A generic login failed error message text",
});

const useAuthErrorMessage = (
  error: ApiError | undefined,
  onForgotPasswordClick?: () => void
): ReactNode | undefined => {
  const intl = useIntl();
  let message = undefined;
  if (error) {
    if (
      (error.status === 401 || error.status === 403) &&
      error.message === "Password based login is disabled for your organization"
    ) {
      message = intl.formatMessage({
        id: "auth.login.error.passwordLoginDisabled",
        defaultMessage: "Password based login is disabled for your organization",
        description: "Disabled password login error message text",
      });
    } else if (error.status === 401) {
      message = getErrorMessage(error, {}, genericErrorMessage);
      if (message.startsWith("Your password has expired")) {
        message = intl.formatMessage(
          {
            id: "auth.login.error.passwordExpired",
            defaultMessage:
              "Your password has expired, please create a new password by clicking <link>Forgot password</link> below",
            description: "Expired password login error message text",
          },
          {
            link: (text: ReactNode[]) => (
              <Link onClick={onForgotPasswordClick} to={Path.FORGOT_PASSWORD}>
                {text}
              </Link>
            ),
          }
        );
      } else {
        // don't show an error when was redirected to the login page because of the expired token
        message = undefined;
      }
    } else {
      message = getErrorMessage(error, {}, genericErrorMessage);
      if (message.startsWith("Invalid grant")) {
        message = intl.formatMessage({
          id: "auth.login.error.invalidCredentials",
          defaultMessage: "This username and password are incorrect, please try again",
          description: "An invalid credentials error message text",
        });
      } else if (message.startsWith("This organization has been locked.")) {
        message = intl.formatMessage({
          id: "auth.login.error.lockedOrganization",
          defaultMessage:
            "This organization has been locked. Contact support for more information.",
          description: "Locked organization error message text",
        });
      } else if (message.startsWith("Your account has been deactivated.")) {
        message = intl.formatMessage({
          id: "auth.login.error.accountDeactivated",
          defaultMessage:
            "Your account has been deactivated. Please contact your organization owner.",
          description: "Account Deactivated error message text",
        });
      } else {
        message = intl.formatMessage(genericErrorMessage);
      }
    }
  }

  return message;
};

export default useAuthErrorMessage;
