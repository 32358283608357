import { createReducer, getType } from "typesafe-actions";

import BaseMapStyle from "@mapmycustomers/shared/enum/map/BaseMapStyle";
import MapTool from "@mapmycustomers/shared/enum/map/MapTool";

import LassoToolMode from "@app/scene/map/enums/LassoToolMode";
import MapMode from "@app/scene/map/enums/MapMode";
import { LegendsActions } from "@app/scene/map/store/legends/actions";
import territory, { territoryModeInitialState } from "@app/scene/map/store/territoryMode/reducers";
import LassoModel from "@app/scene/map/types/LassoModel";
import PinDropModel from "@app/scene/map/types/PinDropModel";
import MapViewportState from "@app/types/map/MapViewportState";
import { MAX_LASSO_PATHS } from "@app/util/lasso";
import loggingService from "@app/util/logging";
import { MAP_ENTITY_TYPES } from "@app/util/map/consts";

import {
  Actions,
  appendLassoPath,
  cleanLassoPaths,
  enterMode,
  exitLassoMode,
  exitMode,
  fetchLassoSelection,
  GroupModeActions,
  initializeMapView,
  LayersActions,
  LeadFinderActions,
  MapModeActions,
  PinLegendsActions,
  PinLocationActions,
  setLassoContainsFlags,
  setLocationSearchVisibility,
  setMapSettings,
  setMapTool,
  TerritoryModeActions,
  updateMapStyle,
  updatePinDropCoordinates,
  updateViewportState,
} from "./actions";
import GroupModeState from "./groupMode/GroupModeState";
import groups, { groupsModeInitialState } from "./groupMode/reducers";
import layers, { layersInitialState, LayersState } from "./layers/reducers";
import LeadFinderState from "./leadFinder/LeadFinderState";
import leadFinder, { leadFinderInitialState } from "./leadFinder/reducers";
import legends, { legendsInitialState, LegendsState } from "./legends/reducers";
import MapModeState from "./mapMode/MapModeState";
import mapMode, { mapModeInitialState } from "./mapMode/reducers";
import PinLegendsState from "./pinLegends/PinLegendsState";
import pinLegends, { pinLegendsInitialState } from "./pinLegends/reducers";
import PinLocationState from "./pinLocation/PinLocationState";
import pinLocation, { pinLocationInitialState } from "./pinLocation/reducers";
import TerritoryModeState from "./territoryMode/TerritoryModeState";

export type { MapLayer } from "./layers/reducers";

export interface MapState {
  error: undefined | unknown;
  groupMode: GroupModeState;
  initializing: boolean;
  lasso: LassoModel;
  layers: LayersState;
  leadFinder: LeadFinderState;
  legends: LegendsState;
  loading: boolean;
  locationSearchVisible: boolean;
  lookUpLocation: google.maps.LatLngLiteral | undefined;
  mapMode: MapModeState;
  mode: MapMode | undefined;
  pinDrop?: PinDropModel;
  pinLegends: PinLegendsState;
  pinLocation: PinLocationState;
  style: BaseMapStyle;
  territoryMode: TerritoryModeState;
  viewport: MapViewportState;
}

const initialState: MapState = {
  error: undefined,
  groupMode: groupsModeInitialState,
  initializing: false,
  lasso: {
    allowAdd: true,
    containsClusters: false,
    loading: false,
    options: {
      mode: LassoToolMode.DEFAULT,
      visibleEntityTypes: MAP_ENTITY_TYPES,
    },
    paths: [],
  },

  layers: layersInitialState,
  leadFinder: leadFinderInitialState,
  legends: legendsInitialState,
  loading: false,
  locationSearchVisible: false,
  lookUpLocation: undefined,
  mapMode: mapModeInitialState,
  mode: undefined,
  pinDrop: undefined,
  pinLegends: pinLegendsInitialState,
  pinLocation: pinLocationInitialState,
  style: BaseMapStyle.MUTED,

  territoryMode: territoryModeInitialState,

  viewport: {
    bounds: { bottom_right: [0, 0], top_left: [0, 0] },
    zoom: 2,
  },
};

const map = createReducer<MapState, Actions>(initialState)
  .handleType(getType(initializeMapView.request), (state) => ({
    ...state,
    initializing: true,
  }))
  .handleType(getType(setMapSettings), (state, { payload }) => ({
    ...state,
    ...payload,
  }))
  .handleType(
    [getType(initializeMapView.success), getType(initializeMapView.failure)],
    (state) => ({
      ...state,
      initializing: false,
    })
  )
  .handleType(getType(updateViewportState), (state, { payload }) => ({
    ...state,
    viewport: payload,
  }))
  .handleType(getType(updateMapStyle), (state, { payload }) => ({
    ...state,
    style: payload,
  }))
  .handleType(getType(enterMode), (state, { payload }) => ({
    ...state,
    mode: payload,
  }))
  .handleType(getType(exitMode), (state) => ({
    ...state,
    mode: undefined,
  }))
  .handleType(getType(setMapTool), (state, { payload }) => {
    let mapTool = payload === undefined ? undefined : payload.mapTool;
    let lassoMode = state.lasso.options.mode;

    if (
      payload?.mapTool === MapTool.LASSO &&
      payload.mapToolOptions?.mode === LassoToolMode.ADD_GROUP_PINS
    ) {
      // only allow switching into ADD_GROUP_PINS lasso mode when the conditions are met
      if (
        payload.mapToolOptions?.addItemsToGroupId &&
        payload.mapToolOptions?.visibleEntityTypes.length === 1
      ) {
        lassoMode = LassoToolMode.ADD_GROUP_PINS;
      } else {
        loggingService.error(
          "Can not switch into AddToGroup lasso mode, conditions are not met. Check options:",
          payload.mapToolOptions
        );
        mapTool = undefined;
        lassoMode = LassoToolMode.DEFAULT;
      }
    }

    return {
      ...state,
      lasso:
        payload?.mapTool === MapTool.LASSO
          ? {
              ...state.lasso,
              options: {
                ...state.lasso.options,
                addItemsToGroupId:
                  lassoMode === LassoToolMode.ADD_GROUP_PINS
                    ? payload.mapToolOptions?.addItemsToGroupId
                    : undefined,
                mode: lassoMode,
                visibleEntityTypes:
                  lassoMode !== LassoToolMode.DEFAULT
                    ? payload.mapToolOptions?.visibleEntityTypes ?? MAP_ENTITY_TYPES
                    : MAP_ENTITY_TYPES,
              },
            }
          : state.lasso,
      mapMode: {
        ...state.mapMode,
        viewState: {
          ...state.mapMode.viewState,
          tool: mapTool,
        },
      },
    };
  })
  .handleType(getType(updatePinDropCoordinates.request), (state, { payload }) => ({
    ...state,
    pinDrop: {
      ...state.pinDrop,
      address: undefined,
      coordinates: payload,
      loading: true,
      visible: !!payload,
    },
  }))
  .handleType(getType(updatePinDropCoordinates.success), (state, { payload }) => ({
    ...state,
    pinDrop: {
      ...state.pinDrop,
      address: payload,
      loading: false,
    },
  }))
  .handleType(getType(appendLassoPath), (state, { payload }) => ({
    ...state,
    lasso: {
      ...state.lasso,
      allowAdd:
        (state.lasso?.paths && Array.isArray(state.lasso.paths) ? state.lasso.paths.length : 0) <
        MAX_LASSO_PATHS - 1,
      paths:
        state.lasso?.paths && Array.isArray(state.lasso.paths)
          ? [...state.lasso.paths, payload]
          : [payload],
    },
  }))
  .handleType(getType(cleanLassoPaths), (state) => ({
    ...state,
    lasso: {
      ...state.lasso,
      allowAdd: true,
      containsClusters: false,
      paths: [],
    },
  }))
  .handleType(getType(setLassoContainsFlags), (state, { payload }) => ({
    ...state,
    lasso: {
      ...state.lasso,
      ...payload,
    },
  }))
  .handleType(getType(exitLassoMode), (state) => ({
    ...state,
    lasso: {
      ...state.lasso,
      options: {
        ...state.lasso.options,
        mode: LassoToolMode.DEFAULT,
      },
    },
  }))
  .handleType(getType(fetchLassoSelection.request), (state) => ({
    ...state,
    lasso: {
      ...state.lasso,
      loading: true,
    },
  }))
  .handleType(
    [getType(fetchLassoSelection.success), getType(fetchLassoSelection.failure)],
    (state) => ({
      ...state,
      lasso: {
        ...state.lasso,
        loading: false,
      },
    })
  )
  .handleType(
    Object.keys(mapMode.handlers) as MapModeActions["type"][],
    (state: MapState, action: MapModeActions) => ({
      ...state,
      mapMode: mapMode(state.mapMode, action),
    })
  )
  .handleType(
    Object.keys(groups.handlers) as GroupModeActions["type"][],
    (state: MapState, action: GroupModeActions) => ({
      ...state,
      groupMode: groups(state.groupMode, action),
    })
  )
  .handleType(
    Object.keys(territory.handlers) as TerritoryModeActions["type"][],
    (state: MapState, action: TerritoryModeActions) => ({
      ...state,
      territoryMode: territory(state.territoryMode, action),
    })
  )
  .handleType(
    Object.keys(layers.handlers) as LayersActions["type"][],
    (state: MapState, action: LayersActions) => ({
      ...state,
      layers: layers(state.layers, action),
    })
  )
  .handleType(
    Object.keys(leadFinder.handlers) as LeadFinderActions["type"][],
    (state: MapState, action: LeadFinderActions) => ({
      ...state,
      leadFinder: leadFinder(state.leadFinder, action),
    })
  )
  .handleType(
    Object.keys(legends.handlers) as LegendsActions["type"][],
    (state: MapState, action: LegendsActions) => ({
      ...state,
      legends: legends(state.legends, action),
    })
  )
  .handleType(
    Object.keys(pinLegends.handlers) as PinLegendsActions["type"][],
    (state: MapState, action: PinLegendsActions) => ({
      ...state,
      pinLegends: pinLegends(state.pinLegends, action),
    })
  )
  .handleType(getType(setLocationSearchVisibility), (state, { payload }) => ({
    ...state,
    locationSearchVisible: payload,
  }))
  .handleType(
    Object.keys(pinLocation.handlers) as PinLocationActions["type"][],
    (state: MapState, action: PinLocationActions) => ({
      ...state,
      pinLocation: pinLocation(state.pinLocation, action),
    })
  );

export type MapActions = Actions;
export default map;
