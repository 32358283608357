import { createReducer, getType } from "typesafe-actions";

import { ModifiedMapFilterModel } from "@mapmycustomers/shared/types/viewModel/internalModel/MapFilterModel";

import { Actions, setOfferedFilters } from "./actions";

export type MapState = {
  // Offers user to filter by some field. Intended use is to provide a filter by some field with some
  // operator, but without a value.
  offerFilters?: ModifiedMapFilterModel;
};

const initialState: MapState = {};

const map = createReducer<MapState, Actions>(initialState).handleType(
  getType(setOfferedFilters),
  (state, { payload }) => ({
    ...state,
    offerFilters: payload,
  })
);

export type MapActions = Actions;
export { getMapViewSettings } from "@app/store/map/selectors";
export default map;
