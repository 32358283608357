import React, { useCallback, useMemo } from "react";
import { connect } from "react-redux";

import { faFile } from "@fortawesome/free-solid-svg-icons/faFile";
import { useIntl } from "react-intl";

import { Activity, EntityType } from "@mapmycustomers/shared/types/entity";
import { RawFile } from "@mapmycustomers/shared/types/File";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";

import Section from "@app/component/activity/ActivityAnnotation/Section";
import ExtendedAnalytics from "@app/component/analytics/ExtendedAnalytics";
import FileContent from "@app/component/FileContent/FilesContent";
import { downloadFile } from "@app/component/FilePreview/store/actions";
import FileUploaderModal from "@app/component/FileUploaderModal";
import AddOrCreateButton from "@app/component/preview/components/AddOrCreateButton";
import TextWithInfo from "@app/component/typography/TextWithInfo";
import { RootState } from "@app/store/rootReducer";
import { RECORD_FILE_UPLOAD_LIMIT } from "@app/util/consts";
import { ActivityFieldName } from "@app/util/fieldModel/ActivityFieldModel";
import { activityLayoutModel } from "@app/util/layout/impl";
import useEntityLayoutSchema from "@app/util/useEntityLayoutSchema";

import { areFilesUploading } from "../store";
import { deleteActivityFile, fetchThumbnail, uploadActivityFiles } from "../store/actions";

import styles from "./FilesSection.module.scss";

interface Props {
  activity: Activity;
  files?: RawFile[];
  fileUploading: boolean;
  onDeleteFile: typeof deleteActivityFile.request;
  onDownload: typeof downloadFile;
  onFetchThumbnail: typeof fetchThumbnail;
  onUpload: typeof uploadActivityFiles.request;
}

const FilesSection: React.FC<Props> = ({
  activity,
  files,
  fileUploading,
  onDeleteFile,
  onDownload,
  onFetchThumbnail,
  onUpload,
}) => {
  const intl = useIntl();
  const [isAddingFile, startAddingFileProcess, stopAddingFileProcess] = useBoolean(false, true);

  const layout = useMemo(() => activityLayoutModel.getLayoutFor(activity), [activity]);
  const [schema] = useEntityLayoutSchema({ entity: activity, layout });
  const filesSchema = useMemo(
    () => schema.find((schemaField) => schemaField.field === ActivityFieldName.FILES),
    [schema]
  );

  const handleDownload = useCallback(
    (file: RawFile) => {
      onDownload({ entityId: activity.id, entityType: EntityType.ACTIVITY, file });
    },
    [activity, onDownload]
  );

  return (
    <Section
      icon={faFile}
      title={
        <div className={styles.title}>
          <TextWithInfo
            info={intl.formatMessage(
              {
                id: "component.activityAnnotation.filesSection.tooltip",
                defaultMessage:
                  "We support most images, pdfs, documents and videos up to {fileLimit, number, ::K}MB",
                description: "Files info tooltip text",
              },
              {
                fileLimit: RECORD_FILE_UPLOAD_LIMIT / 1024 / 1024,
              }
            )}
          >
            {intl.formatMessage({
              id: "component.activityAnnotation.filesSection.title",
              defaultMessage: "Files",
              description: "Files - Section Title",
            })}
          </TextWithInfo>
          <AddOrCreateButton
            disabled={!activity.accessStatus?.update}
            label={intl.formatMessage({
              id: "component.activityAnnotation.filesSection.addButton",
              defaultMessage: "Add",
              description: "Title of a button to add a file to a activity",
            })}
            onClick={startAddingFileProcess}
            tooltip={
              !activity.accessStatus?.update
                ? intl.formatMessage({
                    id: "component.activityAnnotation.filesSection.addButton.uneditable",
                    defaultMessage:
                      "You do not have permission to use this feature. Please contact your admin to request a change.",
                    description: "Tooltip for an Add file Button when user has no edit rights",
                  })
                : undefined
            }
          />
        </div>
      }
    >
      {files && files.length > 0 ? (
        <div>
          <FileContent
            accessStatus={activity.accessStatus}
            files={files}
            onDelete={onDeleteFile}
            onDownload={handleDownload}
            onFetchThumbnail={onFetchThumbnail}
            required={filesSchema?.required}
            size="small"
          />
        </div>
      ) : (
        <span className={styles.noFiles}>
          {intl.formatMessage({
            id: "component.activityAnnotation.filesSection.noFiles",
            defaultMessage: "No Files Yet",
            description: "No files yet",
          })}
        </span>
      )}
      {isAddingFile && (
        <ExtendedAnalytics keyPath="Activity Annotation">
          <FileUploaderModal
            fileUploading={fileUploading}
            onDelete={onDeleteFile}
            onHide={stopAddingFileProcess}
            onUpload={onUpload}
          />
        </ExtendedAnalytics>
      )}
    </Section>
  );
};

const mapStateToProps = (state: RootState) => ({
  fileUploading: areFilesUploading(state),
});

const mapDispatchToProps = {
  onDeleteFile: deleteActivityFile.request,
  onDownload: downloadFile,
  onFetchThumbnail: fetchThumbnail,
  onUpload: uploadActivityFiles.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(FilesSection);
