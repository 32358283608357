import { defineMessages } from "react-intl";

const messages = defineMessages({
  "ui.address.autoCompleteAddress.placeholder": {
    id: "ui.address.autoCompleteAddress.placeholder",
    defaultMessage: "Type to search",
    description: "Placeholder text for Auto Complete Address input",
  },
  "ui.address.findMe.tooltip": {
    id: "ui.address.findMe.tooltip",
    defaultMessage: "Find my location",
    description: "Find my location button in a Auto Complete Address input",
  },
  "ui.autoComplete.address.manualButton": {
    id: "ui.autoComplete.address.manualButton",
    defaultMessage: "Enter address manually",
    description: "Manual Address Button",
  },
  "ui.autoComplete.address.noResult": {
    id: "ui.autoComplete.address.noResult",
    defaultMessage: "No results",
    description: "No results label",
  },
  "ui.avatar.tooltip.open": {
    id: "ui.avatar.tooltip.open",
    defaultMessage: "Open {name}",
    description: "Open user/team tooltip message",
  },
  "ui.avatar.userDisplayNameWithInfo": {
    id: "ui.avatar.userDisplayNameWithInfo",
    defaultMessage:
      "{username}{isDeleted, select, true { (Deleted)} other {}}{isDeactivated, select, true { (Deactivated)} other {}}",
    description: "Full username with meta-info about its delete or deactivated status",
  },
  "ui.colorComponents.changeColor": {
    id: "ui.colorComponents.changeColor",
    defaultMessage: "Change Color",
    description: "Change Color message in a ColorComponent",
  },
  "ui.common.teamItem.noTeam": {
    id: "ui.common.teamItem.noTeam",
    defaultMessage: "No Team",
    description: "No Team item label in the User Menu",
  },
  "ui.common.teamItem.owners": {
    id: "ui.common.teamItem.owners",
    defaultMessage: "Owners",
    description: "Owners item label in the User Menu",
  },
  "ui.editableText.cancel.tooltip": {
    id: "ui.editableText.cancel.tooltip",
    defaultMessage: "Cancel",
    description: "Tooltip of the cancel button in EditableText field",
  },
  "ui.editableText.save.tooltip": {
    id: "ui.editableText.save.tooltip",
    defaultMessage: "Save",
    description: "Tooltip of the save button in EditableText field",
  },
  "ui.editableText.save.tooltip.error.required": {
    id: "ui.editableText.save.tooltip.error.required",
    defaultMessage: "Non-empty value is required",
    description:
      "Tooltip of the save button in EditableText field when non-empty value is required",
  },
  "ui.editableText.tooltip": {
    id: "ui.editableText.tooltip",
    defaultMessage: "Edit",
    description: "Tooltip for the EditableText field",
  },
  "ui.emailBody.attachedFiles.fileSize": {
    id: "ui.emailBody.attachedFiles.fileSize",
    defaultMessage: "{size, number, ::K}MB",
  },
  "ui.emailBody.imageModal.description": {
    id: "ui.emailBody.imageModal.description",
    defaultMessage:
      "Alt text is a brief image description that helps visually impaired users via screen readers and appears if images don't load.",
    description: "Email image alt description",
  },
  "ui.emailBody.imageModal.editAlt": {
    id: "ui.emailBody.imageModal.editAlt",
    defaultMessage: "Edit alt text",
    description: "Email image edit alt label",
  },
  "ui.emailBody.imageModal.input": {
    id: "ui.emailBody.imageModal.input",
    defaultMessage: "Description",
    description: "Email image description label",
  },
  "ui.emailBody.imageModal.ok": {
    id: "ui.emailBody.imageModal.ok",
    defaultMessage: "Okay",
    description: "Email image modal ok",
  },
  "ui.emailBody.imageModal.remove": {
    id: "ui.emailBody.imageModal.remove",
    defaultMessage: "Remove",
    description: "Email image remove action label",
  },
  "ui.emailBody.imageModal.size.bestFit": {
    id: "ui.emailBody.imageModal.size.bestFit",
    defaultMessage: "Best Fit",
    description: "Email image best fit action label",
  },
  "ui.emailBody.imageModal.size.original": {
    id: "ui.emailBody.imageModal.size.original",
    defaultMessage: "Original Size",
    description: "Email image original size action label",
  },
  "ui.emailBody.imageModal.size.small": {
    id: "ui.emailBody.imageModal.size.small",
    defaultMessage: "Small",
    description: "Email image small size action label",
  },
  "ui.emailBody.imageModal.title": {
    id: "ui.emailBody.imageModal.title",
    defaultMessage: "Alt Text",
    description: "Email image alt text title",
  },
  "ui.emailBody.toolbar.actions.insertLink": {
    id: "ui.emailBody.toolbar.actions.insertLink",
    defaultMessage: "Insert link",
    description: "Insert Link button text",
  },
  "ui.inlineInput.unknown": {
    id: "ui.inlineInput.unknown",
    defaultMessage: "Unknown",
    description: "Inline inputs Unknown value placeholder",
  },
  "ui.input.richtext.mentionsDropdown.noResult": {
    id: "ui.input.richtext.mentionsDropdown.noResult",
    defaultMessage: "No results",
    description: "text Area With Mentions - No results",
  },
  "ui.manualAddress.city": {
    id: "ui.manualAddress.city",
    defaultMessage: "City",
    description: "City address field",
  },
  "ui.manualAddress.country": {
    id: "ui.manualAddress.country",
    defaultMessage: "Country",
    description: "Country address field",
  },
  "ui.manualAddress.postalCode": {
    id: "ui.manualAddress.postalCode",
    defaultMessage: "Postal Code",
    description: "Postal code address field",
  },
  "ui.manualAddress.regionProvince": {
    id: "ui.manualAddress.regionProvince",
    defaultMessage: "State/Province",
    description: "State/Province field on the Create Company modal",
  },
  "ui.manualAddress.saveAllFields": {
    id: "ui.manualAddress.saveAllFields",
    defaultMessage: "Save all address fields",
    description: "Save all fields",
  },
  "ui.manualAddress.searchGoogle": {
    id: "ui.manualAddress.searchGoogle",
    defaultMessage: "Search Addresses via Google",
    description: "Search with Google button for auto-complete address field",
  },
  "ui.manualAddress.street": {
    id: "ui.manualAddress.street",
    defaultMessage: "Street",
    description: "Street Address field",
  },
  "ui.selectField.placeholder": {
    id: "ui.selectField.placeholder",
    defaultMessage: "Select",
    description: "Select field placeholder",
  },
  "ui.subjectInput.placeholder": {
    id: "ui.subjectInput.placeholder",
    defaultMessage: "Subject",
    description: "SubjectInput field's placeholder",
  },
  "ui.teamMultiSelect.placeholder": {
    id: "ui.teamMultiSelect.placeholder",
    defaultMessage: "Click or type to select teams",
    description: "Placeholder displayed in a TeamMultiSelectField component",
  },
  "ui.teamPicker.deselectAll": {
    id: "ui.teamPicker.deselectAll",
    defaultMessage: "Deselect All",
    description: "Deselect all teams button",
  },
  "ui.teamPicker.search.placeholder": {
    id: "ui.teamPicker.search.placeholder",
    defaultMessage: "Search teams",
    description: "Search teams placeholder",
  },
  "ui.teamPicker.selectAll": {
    id: "ui.teamPicker.selectAll",
    defaultMessage: "Select All",
    description: "Select all teams button",
  },
  "ui.textField.error.limit": {
    id: "ui.textField.error.limit",
    defaultMessage: "Character limit reached",
    description: "Character limit reached text on text input",
  },
  "ui.userPicker.deselectAll": {
    id: "ui.userPicker.deselectAll",
    defaultMessage: "Deselect All",
    description: "Deselect all users button",
  },
  "ui.userPicker.search.placeholder": {
    id: "ui.userPicker.search.placeholder",
    defaultMessage: "Search users",
    description: "Search users placeholder",
  },
  "ui.userPicker.selectAll": {
    id: "ui.userPicker.selectAll",
    defaultMessage: "Select All",
    description: "Select all users button",
  },
});

export default messages;
