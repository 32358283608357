import { ActionType, createAction } from "typesafe-actions";

import LongLat from "@mapmycustomers/shared/types/base/LongLat";
import { ModifiedMapFilterModel } from "@mapmycustomers/shared/types/viewModel/internalModel/MapFilterModel";
import MapViewState from "@mapmycustomers/shared/types/viewModel/MapViewState";

export const openOnMapPage = createAction("map/openOnMapPage")<{
  mapViewState: Partial<MapViewState>;
  openOnNewTab?: boolean;
  viewport?: Partial<{ center: LongLat; zoom: number }>;
}>();

export const setOfferedFilters = createAction("map/filtering/setOfferedFilter")<
  ModifiedMapFilterModel | undefined
>();

export type Actions = ActionType<typeof openOnMapPage | typeof setOfferedFilters>;
