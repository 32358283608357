enum ClientType {
  PUBLIC_API = "Public API",
  WEB = "Web",
  MOBILE = "Mobile",
  IMPORTS = "Imports",
  INTEGRATIONS = "Integrations",
  WORKFLOWS = "Workflows",
  OTHERS = "others",
}

export default ClientType;
