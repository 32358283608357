import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";

import Button from "antd/es/button";
import Form from "antd/es/form";
import { useForm } from "antd/es/form/Form";
import Tooltip from "antd/es/tooltip";
import { useIntl } from "react-intl";

import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import { Company, Deal, EntityType, Person } from "@mapmycustomers/shared/types/entity";
import Funnel from "@mapmycustomers/shared/types/entity/deals/Funnel";
import Stage from "@mapmycustomers/shared/types/entity/deals/Stage";
import FormLayout from "@mapmycustomers/shared/types/layout/FormLayout";
import User from "@mapmycustomers/shared/types/User";
import { isDefined } from "@mapmycustomers/shared/util/assert";
import { FormItem, LoadingSpinner, NumberField } from "@mapmycustomers/ui";

import ConditionalFormField, {
  ConditionalFormFieldRenderProps,
} from "@app/component/ConditionalFormField";
import {
  getAssociationsState,
  isCreating,
  isEntityRelating,
  isEntityRelatingSuccessful,
  isFilesDeleting,
  isGlobalAddDeal,
  isInitializing,
} from "@app/component/createEditEntity/CreateDealModal/store";
import {
  changeAssociatedEntities,
  clearAllUploadedDealFiles,
  createDeal,
  initialize,
  relateUnrelateEntities,
} from "@app/component/createEditEntity/CreateDealModal/store/actions";
import FormFields from "@app/component/FormFields";
import CompanyRelationshipField from "@app/component/FormFields/components/Associations/CompanyRelationshipField";
import PersonRelationshipField from "@app/component/FormFields/components/Associations/PersonRelationshipField";
import DealLossCommentField from "@app/component/FormFields/components/DealLossCommentField";
import DealLossReasonField from "@app/component/FormFields/components/DealLossReasonField";
import FunnelStageField from "@app/component/FormFields/components/FunnelStageField";
import FormLayoutSelector from "@app/component/FormLayoutSelector";
import FormNoLayoutAlert from "@app/component/FormNoLayoutAlert";
import DatePicker from "@app/component/input/DatePicker";
import BaseModal from "@app/component/modal/BaseModal";
import { showGlobalPostCreationModal } from "@app/component/PostCreationModal/store/actions";
import PreviewModeIndicator from "@app/component/PreviewModeIndicator";
import { DealPayload } from "@app/store/api/ApiService";
import AssociationsState from "@app/store/associations/AssociationsState";
import { getFunnelStages } from "@app/store/deal";
import { getCurrentUserId } from "@app/store/iam";
import { RootState } from "@app/store/rootReducer";
import { AnalyticsService } from "@app/util/analytic/AnalyticsService";
import { MAX_DEAL_SCORE } from "@app/util/consts";
import { ActivityFieldName } from "@app/util/fieldModel/ActivityFieldModel";
import dealFieldModel, { DealFieldName } from "@app/util/fieldModel/DealFieldModel";
import { formatDate } from "@app/util/formatters";
import useFormChangeTracker from "@app/util/hook/form/useFormChangeTracker";
import useCustomFieldValidation from "@app/util/hook/useCustomFieldValidation";
import useErroredFieldsScroller from "@app/util/hook/useErroredFieldsScroller";
import { dealLayoutModel } from "@app/util/layout/impl";

import RelatedEntitiesMatching from "../../RelatedEntitiesMatching";

import File from "./component/File";
import styles from "./CreateDealModal.module.scss";
import FormValues from "./types/FormValues";

type FunnelStageValue = {
  funnelId?: Funnel["id"];
  stageId?: Stage["id"];
};

interface OwnProps {
  analyticIssuer?: AnalyticsService;
  className?: string;
  defaultClosingDate?: Date;
  defaultFunnelId?: Funnel["id"];
  defaultStageId?: Stage["id"];
  fixedCompany?: Company;
  fixedPerson?: Person;
  okText?: ReactNode;
  onHide: (newDeal?: Deal) => void;
  previewLayout?: FormLayout;
}

interface Props extends OwnProps {
  associations: AssociationsState;
  clearAllUploadedFiles: () => void;
  createDeal: typeof createDeal.request;
  creating: boolean;
  currentUserId: User["id"];
  dealStageExtra?: ReactNode;
  entityRelatingSuccess: boolean;
  funnelStages: Record<Funnel["id"], Stage[]>;
  initialize: typeof initialize.request;
  initializing: boolean;
  isEntityRelating: boolean;
  isFilesDeleting: boolean;
  isGlobalAddDeal?: boolean;
  onChangeAssociatedEntities: typeof changeAssociatedEntities.request;
  onShowGlobalPostCreationModal: typeof showGlobalPostCreationModal;
  relateUnrelateEntities: typeof relateUnrelateEntities.request;
  showVariantWarning?: boolean;
  variantStageName?: string;
}

const CreateDealModal: React.FC<Props> = ({
  analyticIssuer,
  associations,
  className,
  clearAllUploadedFiles,
  createDeal,
  creating,
  currentUserId,
  dealStageExtra,
  defaultClosingDate,
  defaultFunnelId,
  defaultStageId,
  entityRelatingSuccess,
  fixedCompany,
  fixedPerson,
  funnelStages,
  initialize,
  initializing,
  isEntityRelating,
  isFilesDeleting,
  isGlobalAddDeal,
  okText,
  onChangeAssociatedEntities,
  onHide,
  onShowGlobalPostCreationModal,
  previewLayout,
  relateUnrelateEntities,
}) => {
  const intl = useIntl();

  const [formLayout, setFormLayout] = useState(dealLayoutModel.defaultFormLayout);
  const [formContainer, handleFinishFail] = useErroredFieldsScroller();

  useEffect(() => {
    initialize({ fixedCompany, fixedPerson });
  }, [fixedCompany, fixedPerson, initialize]);

  const [form] = useForm<FormValues>();
  const [isFormChanged, handleFormChanges] = useFormChangeTracker(form);

  const defaultDealName = useMemo(() => {
    const parentName = fixedCompany?.name ?? fixedPerson?.name;
    return parentName
      ? intl.formatMessage(
          {
            id: "createDealModal.name.defaultName",
            defaultMessage: "{parentName}: New Deal",
          },
          { parentName }
        )
      : "";
  }, [intl, fixedCompany, fixedPerson]);

  const handleCancelClick = useCallback(() => {
    clearAllUploadedFiles();
    onHide();
  }, [clearAllUploadedFiles, onHide]);
  const handleOkClick = useCallback(() => form.submit(), [form]);

  const handleVisibility = useCallback(
    (deal?: Deal) => {
      if (isGlobalAddDeal && deal) {
        onShowGlobalPostCreationModal({ entity: deal, entityType: EntityType.DEAL });
      }
      onHide(deal);
    },
    [isGlobalAddDeal, onHide, onShowGlobalPostCreationModal]
  );

  const handleCustomFieldValidate = useCustomFieldValidation(form, handleFinishFail, true);

  const handleCreateDeal = useCallback(() => {
    const layoutId = formLayout?.id;

    if (layoutId) {
      const values = form.getFieldsValue();

      const deal: DealPayload = {
        account: values.companyId ? { id: values.companyId } : undefined,
        amount: values.amount,
        closingDate: values.closingDate ? formatDate(values.closingDate, "yyyy-MM-dd") : null,
        contact: values.personId ? { id: values.personId } : undefined,
        dealLossComment: values.dealLossComment,
        dealLossReason: values.dealLossReasonId ? { id: values.dealLossReasonId } : undefined,
        funnel: values.funnelId ? { id: values.funnelId } : undefined,
        name: values.name,
        score: values.score,
        stage: values.stageId ? { id: values.stageId } : undefined,
        user: values.user ? { id: values.user } : undefined,
      };

      createDeal({
        callback: handleVisibility,
        customFieldsValues: values.customFields
          ? Object.values(values.customFields).filter(isDefined)
          : [],
        customFieldsValueValidateCallback: handleCustomFieldValidate,
        deal,
        groupsIdsToAdd: values.groups ? Array.from(values.groups) : [],
        layoutId,
      });

      analyticIssuer?.feature(["Deal Created"], { deal });
    }
  }, [
    formLayout?.id,
    form,
    createDeal,
    handleVisibility,
    handleCustomFieldValidate,
    analyticIssuer,
  ]);

  const handleFormFinish = useCallback(() => {
    handleCreateDeal();
  }, [handleCreateDeal]);

  const isPreviewMode = !!previewLayout;

  const currentLayout = isPreviewMode ? previewLayout : formLayout;

  const submitButtonDisabled =
    creating || initializing || isFilesDeleting || isPreviewMode || !currentLayout;

  const funnelStageDefaultValues = useMemo(() => {
    const values: FunnelStageValue = {};

    if (defaultFunnelId) {
      return { funnelId: defaultFunnelId, stageId: defaultStageId };
    }

    currentLayout?.schema.forEach((schemaField) => {
      if (schemaField.field === "funnelId") {
        values.funnelId = schemaField.defaultValue?.[0] as Funnel["id"];
      } else if (schemaField.field === "dealStageId") {
        const defaultStageId = schemaField.defaultValue?.[0] as Stage["id"];
        const funnelIdString = Object.keys(funnelStages).find((funnelId) =>
          funnelStages[parseInt(funnelId) as Funnel["id"]].some(({ id }) => id === defaultStageId)
        );
        if (defaultStageId && funnelIdString) {
          values.stageId = defaultStageId;
          values.funnelId = parseInt(funnelIdString);
        }
      }
    });
    return values;
  }, [currentLayout, defaultFunnelId, defaultStageId, funnelStages]);

  return (
    <>
      <BaseModal
        borderedHeader
        className={className}
        entityTypeName="Deal"
        extra={
          <RelatedEntitiesMatching
            associatedCompany={associations.associatedCompany}
            associatedPerson={associations.associatedPerson}
            entityRelatingSuccess={entityRelatingSuccess}
            isEntityRelating={isEntityRelating}
            relateUnrelateEntities={relateUnrelateEntities}
          />
        }
        footer={
          <>
            <Tooltip
              placement="topRight"
              title={
                isPreviewMode
                  ? intl.formatMessage({
                      id: "createDealModal.footer.previewMode.tooltip",
                      defaultMessage: "Please exit Preview Mode to create deal",
                      description: "Create Deal modal - OK button tooltip in preview mode",
                    })
                  : null
              }
              trigger={isPreviewMode ? ["hover"] : []}
            >
              <Button
                disabled={submitButtonDisabled}
                loading={creating}
                onClick={handleOkClick}
                type="primary"
              >
                {okText ??
                  intl.formatMessage({
                    id: "createDealModal.footer.createDeal",
                    defaultMessage: "Create Deal",
                    description: "Create button title on Create Deal modal",
                  })}
              </Button>
            </Tooltip>
          </>
        }
        onCancel={handleCancelClick}
        onOk={handleOkClick}
        showConfirm={!isPreviewMode && isFormChanged}
        title={
          <>
            {intl.formatMessage({
              id: "createDealModal.header",
              defaultMessage: "Create Deal",
              description: "Header of Create Deal modal",
            })}

            {isPreviewMode ? (
              <PreviewModeIndicator />
            ) : (
              <FormLayoutSelector
                disabled={creating || initializing}
                entityType={EntityType.DEAL}
                layout={formLayout}
                onChange={setFormLayout}
              />
            )}
          </>
        }
      >
        {initializing ? (
          <LoadingSpinner />
        ) : currentLayout ? (
          <div ref={formContainer}>
            <Form
              form={form}
              initialValues={{
                ...funnelStageDefaultValues,
                closingDate: defaultClosingDate,
                companyId: fixedCompany?.id,
                name: defaultDealName,
                personId: fixedPerson?.id,
                user: currentUserId,
              }}
              layout="vertical"
              onFinish={handleFormFinish}
              onFinishFailed={handleFinishFail}
              onValuesChange={handleFormChanges}
              preserve
            >
              <FormFields
                entityType={EntityType.DEAL}
                fileComponent={File}
                isCreateForm
                layout={currentLayout}
              >
                <ConditionalFormField feature={FieldFeature.FUNNEL_FIELD}>
                  {({ disabled, isCreateForm, required }: ConditionalFormFieldRenderProps) => (
                    <>
                      <FunnelStageField
                        autoSelectFunnel={false}
                        defaultFunnelId={funnelStageDefaultValues.funnelId}
                        defaultStageId={funnelStageDefaultValues.stageId}
                        disabled={disabled}
                        isCreateForm={isCreateForm}
                        required={required}
                      />
                      {dealStageExtra}
                    </>
                  )}
                </ConditionalFormField>

                <ConditionalFormField fieldName={DealFieldName.DEAL_LOSS_REASON}>
                  <DealLossReasonField />
                </ConditionalFormField>

                <ConditionalFormField fieldName={DealFieldName.DEAL_LOSS_COMMENT}>
                  <DealLossCommentField />
                </ConditionalFormField>

                <ConditionalFormField fieldName={DealFieldName.SCORE}>
                  {({ disabled, label, required }: ConditionalFormFieldRenderProps) => (
                    <FormItem
                      label={label}
                      name={DealFieldName.SCORE}
                      required={required}
                      rules={[{ required }]}
                    >
                      <NumberField
                        disabled={disabled}
                        inputClassName={styles.full}
                        label={label}
                        max={MAX_DEAL_SCORE}
                        min={0}
                        placeholder={intl.formatMessage(
                          {
                            id: "createDealModal.field.score.placeholder",
                            defaultMessage: "0-{max}",
                            description: "Score field's placeholder on the Create Deal modal",
                          },
                          { max: MAX_DEAL_SCORE }
                        )}
                        precision={0}
                        required={required}
                      />
                    </FormItem>
                  )}
                </ConditionalFormField>

                <ConditionalFormField fieldName={DealFieldName.CLOSING_DATE}>
                  {({ disabled, label, required }: ConditionalFormFieldRenderProps) => (
                    <Form.Item
                      label={label}
                      name={DealFieldName.CLOSING_DATE}
                      required={required}
                      rules={[{ required }]}
                    >
                      <DatePicker
                        allowClear
                        className={styles.full}
                        disabled={disabled}
                        format={"PP"}
                        size="large"
                      />
                    </Form.Item>
                  )}
                </ConditionalFormField>

                <ConditionalFormField fieldName={ActivityFieldName.COMPANY}>
                  {({ disabled, required }: ConditionalFormFieldRenderProps) => (
                    <CompanyRelationshipField
                      allowAdd={!(disabled && (fixedCompany || fixedPerson))}
                      associatedCompany={associations.associatedCompany}
                      associatedDeal={associations.associatedDeal}
                      associatedPerson={associations.associatedPerson}
                      disabled={disabled || !!(fixedCompany || fixedPerson)}
                      entityType={EntityType.DEAL}
                      field={dealFieldModel.getByName(DealFieldName.COMPANY)!}
                      onChangeAssociatedEntities={onChangeAssociatedEntities}
                      relateEntities={relateUnrelateEntities}
                      required={required}
                      suggestedCompanies={associations.availableCompanies}
                    />
                  )}
                </ConditionalFormField>

                <ConditionalFormField fieldName={ActivityFieldName.PERSON}>
                  {({ disabled, required }: ConditionalFormFieldRenderProps) => (
                    <PersonRelationshipField
                      allowAdd={!(disabled && fixedPerson)}
                      associatedCompany={associations.associatedCompany}
                      associatedDeal={associations.associatedDeal}
                      associatedPerson={associations.associatedPerson}
                      disabled={disabled || !!fixedPerson}
                      field={dealFieldModel.getByName(DealFieldName.PERSON)!}
                      onChangeAssociatedEntities={onChangeAssociatedEntities}
                      relateEntities={relateUnrelateEntities}
                      required={required}
                      suggestedPeople={associations.availablePeople}
                    />
                  )}
                </ConditionalFormField>
              </FormFields>
            </Form>
          </div>
        ) : (
          <FormNoLayoutAlert />
        )}
      </BaseModal>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  associations: getAssociationsState(state),
  creating: isCreating(state),
  currentUserId: getCurrentUserId(state)!,
  entityRelatingSuccess: isEntityRelatingSuccessful(state),
  funnelStages: getFunnelStages(state),
  initializing: isInitializing(state),
  isEntityRelating: isEntityRelating(state),
  isFilesDeleting: isFilesDeleting(state),
  isGlobalAddDeal: isGlobalAddDeal(state),
});

const mapDispatchToProps = {
  clearAllUploadedFiles: clearAllUploadedDealFiles,
  createDeal: createDeal.request,
  initialize: initialize.request,
  onChangeAssociatedEntities: changeAssociatedEntities.request,
  onShowGlobalPostCreationModal: showGlobalPostCreationModal,
  relateUnrelateEntities: relateUnrelateEntities.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateDealModal);
