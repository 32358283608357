import { useCallback } from "react";

import { FormInstance } from "antd/es/form";
import { useIntl } from "react-intl";

import { CustomFieldError } from "@mapmycustomers/shared/types/customField/CustomFieldValuesUpsertResponse";

import getErrorMessageByCode from "../errorHandling/platformErrorCodes";

const useCustomFieldValidation = (
  form: FormInstance,
  handleFinishFail?: () => void,
  isCreateForm?: boolean
) => {
  const intl = useIntl();
  const handleCustomFieldValidate = useCallback(
    (customFieldErrors: CustomFieldError[]) => {
      const fields = customFieldErrors.map(({ customField, error }) => {
        return {
          errors: error.map(({ code, message }) => getErrorMessageByCode(intl, code) ?? message),
          // FIXME: use esKey directly when BE is fixed
          name: ["customFields", isCreateForm ? String(customField.id) : `cf.${customField.esKey}`],
        };
      });
      form.setFields(fields);
      handleFinishFail?.();
    },
    [form, handleFinishFail, intl, isCreateForm]
  );

  return handleCustomFieldValidate;
};

export default useCustomFieldValidation;
