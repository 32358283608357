import { ColDef } from "@ag-grid-community/core";
import isNil from "lodash-es/isNil";

import CustomFieldDataType from "@mapmycustomers/shared/enum/CustomFieldDataType";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import FilterOption from "@mapmycustomers/shared/enum/fieldModel/FilterOption";
import NumberFormatType from "@mapmycustomers/shared/enum/NumberFormatType";
import { AnyCustomFieldValue } from "@mapmycustomers/shared/types/customField";
import CustomFieldType from "@mapmycustomers/shared/types/customField/CustomField";
import CustomFieldValue from "@mapmycustomers/shared/types/customField/CustomFieldValue";
import {
  CustomFieldValueType,
  UnwrappedCustomFieldValueType,
} from "@mapmycustomers/shared/types/customField/CustomFieldValueType";
import MonetaryValue from "@mapmycustomers/shared/types/customField/MonetaryValue";
import OptionCustomFieldValue from "@mapmycustomers/shared/types/customField/OptionCustomFieldValue";
import { EntitySupportingCustomFields } from "@mapmycustomers/shared/types/entity";
import FormProperties from "@mapmycustomers/shared/types/fieldModel/FormProperties";
import { IHumanReadableFilterConfig } from "@mapmycustomers/shared/types/fieldModel/IFilterConfig";
import ImportProperties from "@mapmycustomers/shared/types/fieldModel/ImportProperties";
import {
  FilterCondition,
  SimpleCondition,
} from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import PlatformFilterModel, {
  PlatformFilterCondition,
} from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";
import { isDefined } from "@mapmycustomers/shared/util/assert";

import defaultFilters, { DEFAULT_EMPTINESS_OPERATOR } from "@app/util/filters/defaultFilters";
import OptionFilter, {
  MULTI_OPTION_FILTER_OPERATORS,
  SINGLE_OPTION_FILTER_OPERATORS,
} from "@app/util/filters/OptionFilter";
import loggingService from "@app/util/logging";
import { parseApiDateWithTz, parseTime } from "@app/util/parsers";
import {
  isCombinedCondition,
  isCombinedPlatformCondition,
  isSimplePlatformCondition,
} from "@app/util/viewModel/assert";
import {
  getCustomFieldConditionValue,
  platformCombineOperatorToFilterCombineOperator,
} from "@app/util/viewModel/convertFromPlatformFilterModel";
import {
  filterCombineOperatorToPlatformCombineOperator,
  getCustomFieldPlatformConditionValue,
} from "@app/util/viewModel/convertToPlatformFilterModel";

import EmptyFilter from "../../filters/EmptyFilter";

import Field, { FieldProperties, FieldValueFormatter } from "./Field";
import {
  booleanFormatter,
  currencyFormatter,
  dateFormatter,
  percentFormatter,
  timeFormatter,
} from "./fieldUtil";

const customFieldPlatformFiltersGetter = (): PlatformFilterModel => ({ includeCustomFields: true });

export default class CustomField extends Field {
  private readonly _customFieldData: CustomFieldType;
  private readonly _optionMap: Map<number, string>;
  private readonly _reverseOptionMap: Map<string, number>;

  /**
   * Creates new Custom Field object
   * @param {CustomFieldType} customFieldData custom field data as it is returned by the backend
   * @param {FieldProperties} additionalProperties any additional field properties, e.g. to override default ones if necessary
   */
  constructor(
    customFieldData: CustomFieldType,
    additionalProperties: Partial<Omit<FieldProperties, "extraPlatformFiltersGetter">> = {}
  ) {
    let valueFormatter: FieldValueFormatter | undefined;
    let type = FieldType.STRING;
    let formProperties: FormProperties | undefined;
    const features = [FieldFeature.CUSTOM_FIELD, FieldFeature.SORTABLE];
    if (customFieldData.isCalculated) {
      features.push(FieldFeature.CALCULATED_FIELD);
      formProperties = { fullWidth: true };
    }
    /* eslint-disable no-fallthrough */
    switch (customFieldData.dataType) {
      case CustomFieldDataType.DATE:
        type = FieldType.DATE;
        features.push(FieldFeature.FILTERABLE);
        features.push(FieldFeature.FILTERABLE_ON_MAP);
        valueFormatter = dateFormatter;
        break;
      case CustomFieldDataType.TIME:
        type = FieldType.DATE; // really?
        valueFormatter = timeFormatter;
        break;
      case CustomFieldDataType.MONETARY:
        type = FieldType.OBJECT;
        features.push(FieldFeature.FILTERABLE);
        features.push(FieldFeature.FILTERABLE_ON_MAP);
        features.push(FieldFeature.NUMERIC);
        features.push(FieldFeature.DATA_VIEW);
        features.push(FieldFeature.MONETARY_VALUE);
        valueFormatter = currencyFormatter;
        break;
      case CustomFieldDataType.BOOLEAN:
        valueFormatter = booleanFormatter;
        break;
      case CustomFieldDataType.INTEGER:
        features.push(FieldFeature.EMAIL_DYNAMIC_VAR);
      case CustomFieldDataType.DECIMAL:
        type = FieldType.NUMBER;
        features.push(FieldFeature.NUMERIC);
        features.push(FieldFeature.FILTERABLE);
        features.push(FieldFeature.FILTERABLE_ON_MAP);
        features.push(FieldFeature.DATA_VIEW);
        if (customFieldData.displayType === NumberFormatType.PERCENTAGE) {
          valueFormatter = percentFormatter;
        }
        break;
      case CustomFieldDataType.SINGLE_OPTION:
        features.push(FieldFeature.EMAIL_DYNAMIC_VAR);
      case CustomFieldDataType.MULTI_OPTION:
        type = FieldType.LIST;
        features.push(FieldFeature.FILTERABLE);
        features.push(FieldFeature.FILTERABLE_ON_MAP);
        features.push(FieldFeature.SUPPORTS_SET_FILTER);
        features.push(FieldFeature.DATA_VIEW);
        break;
      case CustomFieldDataType.TEXT:
        features.push(FieldFeature.EMAIL_DYNAMIC_VAR);
      case CustomFieldDataType.ADDRESS:
      case CustomFieldDataType.LARGE_TEXT:
      case CustomFieldDataType.PHONE:
        type = FieldType.STRING;
        features.push(FieldFeature.FILTERABLE);
        features.push(FieldFeature.FILTERABLE_ON_MAP);
        break;
    }
    /* eslint-disable no-fallthrough */

    super({
      displayName: customFieldData.displayName,
      extraPlatformFiltersGetter: customFieldPlatformFiltersGetter,
      features,
      formProperties,
      name: customFieldData.esKey,
      platformFilterName: customFieldData.esKey,
      type,
      valueFormatter,
      ...additionalProperties,
    });

    this._filter = this.processInitialFilterConfig(
      this.buildCustomFieldFilterConfig(customFieldData)
    );
    this._customFieldData = customFieldData;
    this._optionMap = new Map(
      (customFieldData.options || []).map(({ displayName, value }) => [value, displayName])
    );
    this._reverseOptionMap = new Map(
      (customFieldData.options || []).map(({ displayName, value }) => [displayName, value])
    );
  }

  /**
   * @override
   */
  gridProperties(forClientSideGrid?: boolean): ColDef {
    let gridProperties: ColDef = this.buildGridProperties(forClientSideGrid);
    gridProperties = this.enhanceGridProperties(gridProperties);
    return Object.assign(gridProperties, this._customGridProperties);
  }

  private buildCustomFieldFilterConfig(
    customFieldData: CustomFieldType
  ): Partial<IHumanReadableFilterConfig> | undefined {
    let result: Partial<IHumanReadableFilterConfig> | undefined;

    if (
      [CustomFieldDataType.MULTI_OPTION, CustomFieldDataType.SINGLE_OPTION].includes(
        customFieldData.dataType
      )
    ) {
      result = {
        defaultInstance: "optionsFilter",
        filterInstances: {
          empty: EmptyFilter,
          optionsFilter: {
            instance: OptionFilter,
            options: {
              [FilterOption.OPTIONS_LIST]: customFieldData.options.sort((a, b) =>
                a.displayName.localeCompare(b.displayName, undefined, { numeric: true })
              ),
            },
          },
        },
        operators: [
          ...(customFieldData.dataType === CustomFieldDataType.MULTI_OPTION
            ? MULTI_OPTION_FILTER_OPERATORS
            : SINGLE_OPTION_FILTER_OPERATORS),
          ...DEFAULT_EMPTINESS_OPERATOR,
        ],
      };
    } else if (customFieldData.dataType === CustomFieldDataType.MONETARY) {
      // monetary field is filtered by amount only
      result = defaultFilters[FieldType.NUMBER];
    }

    return result;
  }

  private enhanceGridProperties(gridProperties: ColDef): ColDef {
    let result = gridProperties;
    if (
      [CustomFieldDataType.MULTI_OPTION, CustomFieldDataType.SINGLE_OPTION].includes(
        this._customFieldData.dataType
      )
    ) {
      result = {
        ...result,
        ...(this._customFieldData.dataType === CustomFieldDataType.MULTI_OPTION
          ? { cellRenderer: "multiOptionCustomFieldCellRenderer" }
          : null),
      };
    }

    return result;
  }

  /**
   * @override
   */
  get integrationName(): string {
    return this.customFieldData.crmPropertyKey;
  }

  /**
   * @returns {boolean}
   */
  get isSingleOptionField() {
    return this._customFieldData.dataType === CustomFieldDataType.SINGLE_OPTION;
  }

  /**
   * @returns {boolean}
   */
  get isMultiOptionField() {
    return this._customFieldData.dataType === CustomFieldDataType.MULTI_OPTION;
  }

  /**
   * @returns {boolean}
   */
  get isMonetaryField() {
    return this._customFieldData.dataType === CustomFieldDataType.MONETARY;
  }

  /**
   * @returns {boolean}
   */
  get isOptionField() {
    return this.isSingleOptionField || this.isMultiOptionField;
  }

  /**
   * @returns {*}
   */
  get customFieldData() {
    return this._customFieldData;
  }

  /**
   * @returns {boolean}
   */
  get isUniqueField() {
    return !!this.customFieldData.isUnique;
  }

  /**
   * @override
   */
  get teamIds(): null | number[] | undefined {
    return this._customFieldData.teamId;
  }

  /**
   * @override
   */
  getValueFor(entity: unknown) {
    // @ts-ignore
    const customFieldValue: CustomFieldValue = (
      (entity as EntitySupportingCustomFields).customFields ?? []
    ).find(
      (fieldValue: AnyCustomFieldValue) => fieldValue.customField.id === this._customFieldData.id
    );

    // could use this._customFieldData.defaultValue if there's no customFieldValue,
    // but our UI doesn't allow setting default values now, thus we can't use this field
    const rawValue: CustomFieldValueType = customFieldValue ? customFieldValue.value : undefined;

    // type coercion is not precise, but is fixed in a conditional below
    let value = rawValue as UnwrappedCustomFieldValueType;

    if (!this.isOptionField) {
      value = Array.isArray(value) && value.length ? value[0] : undefined;

      // Special handling for raw composite response value of monetary CF
      if (this.isMonetaryField && isDefined(customFieldValue?.responseValue)) {
        const monetaryValue = customFieldValue.responseValue as MonetaryValue;
        if (isDefined(monetaryValue.currencyId) && isDefined(monetaryValue.value)) {
          value = monetaryValue;
        }
      }
    }
    if (isNil(value)) {
      return value;
    }

    switch (this._customFieldData.dataType) {
      case CustomFieldDataType.DATE:
        return parseApiDateWithTz(value as string);
      case CustomFieldDataType.TIME:
        return parseTime(value as string);
      case CustomFieldDataType.DECIMAL:
      case CustomFieldDataType.INTEGER:
        return value;
      case CustomFieldDataType.MONETARY:
        return {
          currencyId: (value as MonetaryValue).currencyId,
          value: (value as MonetaryValue).value,
        };
      case CustomFieldDataType.MULTI_OPTION:
      case CustomFieldDataType.SINGLE_OPTION:
        return {
          optionMap: this._optionMap,
          values: value,
        } as OptionCustomFieldValue;
    }

    return value;
  }

  /**
   * @override
   */
  formatValue(entity: EntitySupportingCustomFields, value: unknown): string {
    if (isNil(value)) {
      return "";
    }
    if (this.isOptionField) {
      if (!value) {
        return ""; // no value
      }
      const optionFieldValue = value as OptionCustomFieldValue;
      try {
        // Checking if value is an array should not be required, but we did face some old records
        // where the value was a string, instead of being an array of numbers. So we're just
        // making it a bit more compatible with such records.
        const formattedValue = (
          Array.isArray(optionFieldValue.values)
            ? optionFieldValue.values
            : [optionFieldValue.values]
        )
          .map((optionId) => optionFieldValue.optionMap.get(optionId))
          .filter(isDefined);

        return this.isSingleOptionField ? formattedValue[0] ?? "" : formattedValue.join(", ");
      } catch (e) {
        console.error("!!!! eee", e, this, optionFieldValue);
        throw e;
      }
    }
    return this._valueFormatter ? this._valueFormatter(entity, value) : String(value ?? "");
  }

  private transformFilterValue(filterCondition: SimpleCondition): SimpleCondition {
    if (!this.isOptionField || !Array.isArray(filterCondition.value)) {
      return filterCondition;
    }

    // ES-platform takes names instead of ids
    return {
      ...filterCondition,
      value: (filterCondition.value as number[]).map((id) => this._optionMap.get(id)),
    };
  }

  /**
   * @override
   */
  convertToPlatformCondition(filterCondition: FilterCondition): PlatformFilterCondition {
    if (isCombinedCondition(filterCondition)) {
      const operator = filterCombineOperatorToPlatformCombineOperator[filterCondition.operator];
      return {
        // FIXME: fix to be in a same manner as in convertToPlatformCondition
        [this._platformFilterName]: {
          [operator]: filterCondition.conditions.map((simpleCondition) => ({
            ...getCustomFieldPlatformConditionValue(
              this,
              this.transformFilterValue(simpleCondition)
            ),
          })),
        },
      };
    } else {
      return {
        [this._platformFilterName]: {
          ...getCustomFieldPlatformConditionValue(this, this.transformFilterValue(filterCondition)),
        },
      };
    }
  }

  private parseFilterValue(
    filterCondition: SimpleCondition | undefined
  ): SimpleCondition | undefined {
    if (!filterCondition || !this.isOptionField || !Array.isArray(filterCondition.value)) {
      return filterCondition;
    }

    // ES-platform uses names instead of ids, so here we try to parse value back in ids
    const value = (filterCondition.value as string[])
      .map((displayName) => {
        const id = this._reverseOptionMap.get(displayName);
        if (id === undefined) {
          loggingService.warning(
            `Failed to parse custom field ("${
              this.name
            }") value from displayName "${displayName}" to id: no such value anymore: ${JSON.stringify(
              Array.from(this._optionMap)
            )}`
          );
        }
        return id;
      })
      .filter(isDefined);
    return value.length ? { ...filterCondition, value } : undefined;
  }

  /**
   * @override
   */
  convertFromPlatformCondition(
    filterCondition: PlatformFilterCondition
  ): FilterCondition | undefined {
    if (isCombinedPlatformCondition(filterCondition)) {
      const platformOperator = "$and" in filterCondition ? "$and" : "$or";
      const operator = platformCombineOperatorToFilterCombineOperator[platformOperator];
      const conditions = filterCondition[platformOperator]!.filter(isSimplePlatformCondition) // we do not support nested combined conditions
        .map((simpleCondition) =>
          this.parseFilterValue(getCustomFieldConditionValue(this, simpleCondition))
        )
        .filter(isDefined);

      return { conditions, operator };
    } else {
      return this.parseFilterValue(getCustomFieldConditionValue(this, filterCondition));
    }
  }

  /**
   * @override
   */
  get sortName(): string {
    return this.customFieldData.esKey;
  }

  /**
   * @override
   */
  get importProperties(): ImportProperties | undefined {
    return { name: this.displayName, required: this.isSystemRequired };
  }
}
