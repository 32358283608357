import React, { useMemo } from "react";

import { bem } from "@react-md/utils";
import Select, { SelectProps } from "antd/es/select";
import Tooltip from "antd/es/tooltip";
import cn from "classnames";

import Team from "@mapmycustomers/shared/types/Team";
import { nameComparator } from "@mapmycustomers/shared/util/comparator";
import defaultFilterOption from "@mapmycustomers/shared/util/input/defaultFilterOption";

import { useConfigProvider } from "../ConfigProvider";
import SelectField from "../input/SelectField";

const block = bem("mmc-team-multiselect-field");

export const messages = {
  "ui.teamMultiSelect.placeholder": "Click or type to select teams",
} as const;

export interface TeamMultiSelectFieldProps
  extends Omit<
    SelectProps<Array<Team["id"]>>,
    "filterOption" | "maxTagCount" | "mode" | "tagRender"
  > {
  oneLiner?: boolean;
  teams: Team[];
}

const TeamMultiSelectField: React.FC<TeamMultiSelectFieldProps> = ({
  className,
  oneLiner,
  placeholder,
  teams,
  ...props
}) => {
  const sortedTeams = useMemo(() => teams.sort(nameComparator), [teams]);
  const configProvider = useConfigProvider();

  return (
    <SelectField<Array<Team["id"]>>
      {...props}
      className={cn(block(), className)}
      filterOption={defaultFilterOption}
      maxTagCount={oneLiner ? "responsive" : undefined}
      mode="multiple"
      placeholder={placeholder ?? configProvider.formatMessage("ui.teamMultiSelect.placeholder")}
    >
      {sortedTeams.map((team) => (
        <Select.Option key={team.id} label={team.name} value={team.id}>
          <Tooltip title={team.name}>
            <span>{team.name}</span>
          </Tooltip>
        </Select.Option>
      ))}
    </SelectField>
  );
};

export default TeamMultiSelectField;
