import { defineMessages, IntlShape } from "react-intl";

import RecordSource from "@mapmycustomers/shared/enum/RecordSource";
import { EntitySupportingSourceTracking } from "@mapmycustomers/shared/types/entity";

export const getRecordSourceType = (entity: EntitySupportingSourceTracking) => {
  return entity.sourceCreated?.source;
};

const messages = defineMessages({
  [RecordSource.IMPORTS]: {
    id: "recordSourceType.import",
    defaultMessage: "Import",
    description: "import record source type",
  },
  [RecordSource.INTEGRATIONS]: {
    id: "recordSourceType.integration",
    defaultMessage: "Integration",
    description: "integration record source type",
  },
  [RecordSource.LEAD_GEN]: {
    id: "recordSourceType.leadfinder",
    defaultMessage: "Lead Finder",
    description: "lead finder record source type",
  },
  [RecordSource.MOBILE]: {
    id: "recordSourceType.mobile",
    defaultMessage: "Mobile",
    description: "mobile record source type",
  },
  [RecordSource.PUBLIC_API]: {
    id: "recordSourceType.publicapi",
    defaultMessage: "Public API",
    description: "direct api record source type",
  },
  [RecordSource.WEB]: {
    id: "recordSourceType.web",
    defaultMessage: "Web",
    description: "web record source type",
  },
  [RecordSource.WORKFLOWS]: {
    id: "recordSourceType.workflows",
    defaultMessage: "Workflow",
    description: "Workflow record source type",
  },
  [RecordSource.ZAPIER]: {
    id: "recordSourceType.zapier",
    defaultMessage: "Zapier",
    description: "Zapier record source type",
  },
});

export const getRecordSourceTypeDisplayName = (
  intl: IntlShape,
  recordSource: null | RecordSource
) => {
  if (!recordSource) {
    return intl.formatMessage({
      id: "recordSourceType.undefined",
      defaultMessage: "Unknown",
      description: "Undefined record source type",
    });
  }
  return messages[recordSource as RecordSource]
    ? intl.formatMessage(messages[recordSource as RecordSource])
    : recordSource;
};
