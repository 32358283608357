import notification from "antd/es/notification";
import browserLocale from "browser-locale";
import { defineMessage } from "react-intl";
import { all, call, put, select, takeLatest } from "redux-saga/effects";

import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import { GeocodeLimits, type GeocodeResult } from "@mapmycustomers/shared/types/base/Located";
import CumulDashboard from "@mapmycustomers/shared/types/cumul/CumulDashboard";
import { EntityTypesSupportedColumnSelection } from "@mapmycustomers/shared/types/entity";
import Funnel from "@mapmycustomers/shared/types/entity/deals/Funnel";
import RawFile from "@mapmycustomers/shared/types/File";
import Organization, { OrganizationMetaData } from "@mapmycustomers/shared/types/Organization";
import PersistentLastUsedLayoutData from "@mapmycustomers/shared/types/persistent/PersistentLastUsedLayoutData";
import Setting from "@mapmycustomers/shared/types/Setting";
import { GeoPoint } from "@mapmycustomers/shared/types/shapes";
import User from "@mapmycustomers/shared/types/User";
import SavedFilter from "@mapmycustomers/shared/types/viewModel/SavedFilter";
import { isDefined } from "@mapmycustomers/shared/util/assert";
import geoService, {
  convertCoordinatesToGeoPoint,
} from "@mapmycustomers/shared/util/geo/GeoService";

import i18nService from "@app/config/I18nService";
import localSettings from "@app/config/LocalSettings";
import OrganizationSetting from "@app/enum/OrganizationSetting";
import { callApi } from "@app/store/api/callApi";
import { handleError } from "@app/store/errors/actions";
import { changeLocale } from "@app/store/locale/actions";
import { fetchTeams } from "@app/store/members/actions";
import Iam from "@app/types/Iam";
import analyticsService from "@app/util/analytic/AnalyticsService";
import getValidationErrors from "@app/util/errorHandling/getValidationErrors";
import getFieldModelByEntityType from "@app/util/fieldModel/getByEntityType";
import getLayoutModelByEntityType from "@app/util/layout/impl";
import LayoutModel from "@app/util/layout/LayoutModel";
import loggingService from "@app/util/logging";

import {
  addFavoriteFilter,
  changePassword,
  deleteProfilePhoto,
  fetchGeocodingLimit,
  fetchMe,
  findMe,
  getUserCountry,
  removeFavoriteFilter,
  setCumulPromotionalUiVisited,
  toggleFavoriteFunnel,
  togglePinnedDashboard,
  updateDefaultColumns,
  updateLastUsedLayout,
  updateMe,
  updateMetadata,
  updateOrganization,
  updateOrganizationMetadata,
  updateOrganizationSetting,
  updateSetting,
  uploadProfilePhoto,
} from "./actions";
import {
  getCurrentUser,
  getFavoriteFilters,
  getFavoriteFunnels,
  getLastUsedLayout,
  getOrganization,
  getOrganizationId,
  getPinnedDashboardIds,
  getPosition,
} from "./selectors";

const passwordChangeSuccessfullyMessage = defineMessage({
  id: "iam.changePassword.success",
  defaultMessage: "Password Successfully Updated",
  description: "Notification to show when user password is successfully updated",
});

const addFunnelBookmarkMessage = defineMessage({
  id: "iam.funnelBookmark.add",
  defaultMessage: "Funnel bookmarked",
  description: "Funnel bookmarked message",
});

const deleteFunnelBookmarkMessage = defineMessage({
  id: "iam.funnelBookmark.delete",
  defaultMessage: "Funnel bookmark removed",
  description: "Funnel bookmark removed message",
});

export function* onFetchMe() {
  try {
    const me: Iam = yield callApi("fetchMe");
    yield put(fetchMe.success(me));
  } catch (error) {
    yield put(fetchMe.failure(error));
    // error is handled in the onInitializeApp saga
  }
}

export function* onFindMe() {
  try {
    const position: GeolocationPosition = yield call(geoService.getCurrentPosition);
    yield put(findMe.success(position));
  } catch {
    yield put(findMe.failure());
  }
}

export function* onGetUserCountry() {
  try {
    const position: GeolocationPosition | undefined = yield select(getPosition);
    if (!position) {
      yield put(getUserCountry.failure());
      return;
    }

    const point: GeoPoint = yield call(convertCoordinatesToGeoPoint, position.coords);
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const result: GeocodeResult = yield callApi("reverseGeocodeAddress", orgId, point);

    if (result.status === "OK" && result.address.countryCode) {
      yield put(getUserCountry.success(result.address.countryCode));
    } else {
      yield put(getUserCountry.failure());
    }
  } catch {
    yield put(getUserCountry.failure());
  }
}

export function* onChangePassword({ payload }: ReturnType<typeof changePassword.request>) {
  try {
    const changedPassword: void = yield callApi(
      "changePassword",
      payload.password,
      payload.currentPassword
    );
    yield put(changePassword.success(changedPassword));
    analyticsService.completed("Change password");
    notification.success({
      message: i18nService.getIntl()?.formatMessage(passwordChangeSuccessfullyMessage),
    });
  } catch (error) {
    // check error and show it either in the change pws modal (changePassword.failure) or globally (handleError)
    const validationErrors = getValidationErrors(error);
    if (validationErrors.length) {
      yield put(changePassword.failure(error));
    } else {
      yield put(handleError({ error }));
    }
  }
}

export function* onUploadProfilePhoto({
  payload: file,
}: ReturnType<typeof uploadProfilePhoto.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const uploadedFile: RawFile = yield callApi("createFile", organization.id, file, true);
    const currentUser: User = yield select(getCurrentUser);
    yield callApi("updateMe", {
      id: currentUser.id,
      profilePhoto: uploadedFile,
    });
    // due to a platform bug, profile photo is not updated in the response of updateMe api call
    // thus we have to call fetchMe
    const updatedProfile: Iam = yield callApi("fetchMe");
    yield put(uploadProfilePhoto.success(updatedProfile));
  } catch (error) {
    yield put(uploadProfilePhoto.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onDeleteProfilePhoto() {
  try {
    const currentUser: User = yield select(getCurrentUser);
    yield callApi("updateMe", {
      id: currentUser.id,
      profilePhoto: null,
    });
    // due to a platform bug, profile photo is not updated in the response of updateMe api call
    // thus we have to call fetchMe
    const updatedProfile: Iam = yield callApi("fetchMe");
    yield put(deleteProfilePhoto.success(updatedProfile));
  } catch (error) {
    yield put(deleteProfilePhoto.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onUpdateMe({ payload: { callback, me } }: ReturnType<typeof updateMe.request>) {
  try {
    yield callApi("updateMe", me);
    const updatedProfile: Iam = yield callApi("fetchMe");
    if (callback) {
      yield call(callback, updatedProfile);
    }
    yield put(fetchTeams.request());
    yield put(updateMe.success(updatedProfile));
  } catch (error) {
    yield put(updateMe.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onUpdateOrganization({
  payload: { callback, organization, settings },
}: ReturnType<typeof updateOrganization.request>) {
  try {
    yield callApi("updateOrganization", organization);
    if (settings && settings.length > 0) {
      yield all(
        settings.map((setting) => callApi("updateOrganizationSetting", organization.id, setting))
      );
      const currencySetting = settings.find(({ key }) => key === OrganizationSetting.CURRENCY);
      if (currencySetting) {
        localSettings.setCurrencyCode(currencySetting.value ?? "USD");
      }
    }
    const updatedProfile: Iam = yield callApi("fetchMe");
    callback?.();
    yield put(updateOrganization.success(updatedProfile));
  } catch (error) {
    yield put(updateOrganization.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onUpdateOrganizationSetting({
  payload: { callback, setting },
}: ReturnType<typeof updateOrganizationSetting.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    yield callApi("updateOrganizationSetting", orgId, setting);
    if (setting.key === OrganizationSetting.CURRENCY) {
      localSettings.setCurrencyCode(setting.value ?? "USD");
    }
    const updatedProfile: Iam = yield callApi("fetchMe");
    if (callback) {
      yield call(callback);
    }
    yield put(updateOrganizationSetting.success(updatedProfile));
  } catch (error) {
    yield put(updateOrganizationSetting.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onUpdateSetting({
  payload: { callback, orgId, setting, userId },
}: ReturnType<typeof updateSetting.request>) {
  try {
    const updatedSetting: Setting = yield callApi("updateUserSetting", orgId, userId, setting);
    callback?.(updatedSetting);
    yield put(updateSetting.success(updatedSetting));

    if (setting.key === "language") {
      const locale = setting.value ? String(setting.value) : browserLocale();
      yield put(changeLocale.request(locale));
    }
  } catch (error) {
    yield put(updateSetting.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onUpdateMetadata({ payload }: ReturnType<typeof updateMetadata.request>) {
  try {
    const user: User = yield select(getCurrentUser);
    const updatedUser: Iam = yield callApi("updateMe", {
      id: user.id,
      metaData: payload,
    });
    yield put(updateMetadata.success(updatedUser.metaData));
  } catch (error) {
    yield put(updateMetadata.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onUpdateOrganizationMetadata({
  payload,
}: ReturnType<typeof updateOrganizationMetadata.request>) {
  try {
    const { callback, metaData } = payload;
    const organization: Organization = yield select(getOrganization);
    const updatedOrganization: Organization = yield callApi("updateOrganization", {
      ...organization,
      metaData: { ...(organization.metaData ?? {}), ...metaData },
    });
    if (callback) {
      yield call(callback, updatedOrganization.metaData);
    }
    yield put(updateOrganizationMetadata.success(updatedOrganization));
  } catch (error) {
    yield put(updateOrganizationMetadata.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onRemoveFavoriteFilter({
  payload,
}: ReturnType<typeof removeFavoriteFilter.request>) {
  try {
    const user: User = yield select(getCurrentUser);
    const favoriteFilters: SavedFilter["id"][] = yield select(getFavoriteFilters);
    if (favoriteFilters.includes(payload)) {
      const updatedUser: User = yield callApi("updateMe", {
        id: user.id,
        metaData: {
          quickFilters: favoriteFilters.filter((filterId) => filterId !== payload),
        },
      });
      yield put(addFavoriteFilter.success(updatedUser));
    }
  } catch (error) {
    yield put(addFavoriteFilter.failure());
    yield put(handleError({ error }));
  }
}

export function* onAddFavoriteFilter({ payload }: ReturnType<typeof addFavoriteFilter.request>) {
  try {
    const user: User = yield select(getCurrentUser);
    const favoriteFilters: SavedFilter["id"][] = yield select(getFavoriteFilters);
    if (!favoriteFilters.includes(payload)) {
      const updatedUser: User = yield callApi("updateMe", {
        id: user.id,
        metaData: { quickFilters: [...favoriteFilters, payload] },
      });
      yield put(addFavoriteFilter.success(updatedUser));
    }
  } catch (error) {
    yield put(addFavoriteFilter.failure());
    yield put(handleError({ error }));
  }
}

export function* onSetCumulPromotionalUIVisited() {
  try {
    const user: User = yield select(getCurrentUser);
    const updatedUser: User = yield callApi("updateMe", {
      id: user.id,
      metaData: { ...user.metaData, isCumulDemoVisited: true },
    });

    yield put(setCumulPromotionalUiVisited.success(updatedUser));
  } catch (error) {
    yield put(setCumulPromotionalUiVisited.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onTogglePinnedDashboard({
  payload,
}: ReturnType<typeof togglePinnedDashboard.request>) {
  try {
    const user: User = yield select(getCurrentUser);
    const pinnedDashboardIds: CumulDashboard["id"][] = yield select(getPinnedDashboardIds);
    let updatedPinnedDashboards = [];
    const isDashboardAlreadyPinned = pinnedDashboardIds.includes(payload);
    if (isDashboardAlreadyPinned) {
      updatedPinnedDashboards = pinnedDashboardIds.filter((dashboard) => dashboard !== payload);
    } else {
      updatedPinnedDashboards = [...pinnedDashboardIds, payload];
    }
    const updatedUser: User = yield callApi("updateMe", {
      id: user.id,
      metaData: { pinnedDashboardIds: updatedPinnedDashboards },
    });
    yield put(togglePinnedDashboard.success(updatedUser));
  } catch (error) {
    yield put(handleError({ error }));
    yield put(togglePinnedDashboard.failure(error));
  }
}

export function* onUpdateFavoriteFunnel({
  payload,
}: ReturnType<typeof toggleFavoriteFunnel.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const favoriteFunnels: Funnel["id"][] = yield select(getFavoriteFunnels);

    const bookmarkExists = favoriteFunnels.some((funnelId) => funnelId === payload.funnelId);
    if (payload.deletingFunnel) {
      if (bookmarkExists) {
        yield callApi("updateOrganization", {
          ...organization,
          metaData: {
            ...organization.metaData,
            favoriteFunnels: favoriteFunnels.filter((funnelId) => funnelId !== payload.funnelId),
          },
        });
        notification.success({ message: i18nService.formatMessage(deleteFunnelBookmarkMessage) });
      }
      const me: Iam = yield callApi("fetchMe");
      yield put(toggleFavoriteFunnel.success(me));
      return;
    }

    yield callApi("updateOrganization", {
      ...organization,
      metaData: bookmarkExists
        ? {
            ...organization.metaData,
            favoriteFunnels: favoriteFunnels.filter((funnelId) => funnelId !== payload.funnelId),
          }
        : { ...organization.metaData, favoriteFunnels: [...favoriteFunnels, payload.funnelId] },
    });
    notification.success({
      message: i18nService.formatMessage(
        bookmarkExists ? deleteFunnelBookmarkMessage : addFunnelBookmarkMessage
      ),
    });
    const me: Iam = yield callApi("fetchMe");
    yield put(toggleFavoriteFunnel.success(me));
  } catch (error) {
    yield put(toggleFavoriteFunnel.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onFetchGeocodingLimit() {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const limits: GeocodeLimits = yield callApi("fetchGeocodeLimits", orgId);
    yield put(
      fetchGeocodingLimit.success({
        geocodingMmcLimitReached: limits.MMC.limit <= limits.MMC.used,
        geocodingOrgLimit: limits.organization.limit,
        geocodingOrgLimitReached: limits.organization.limit <= limits.organization.used,
      })
    );
  } catch (error) {
    yield put(fetchGeocodingLimit.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onUpdateLastUsedLayout({ payload }: ReturnType<typeof updateLastUsedLayout>) {
  try {
    const { entityType, layoutId } = payload;
    const lastUsedLayout: PersistentLastUsedLayoutData = yield select(getLastUsedLayout);
    yield put(
      updateMetadata.request({ lastUsedLayout: { ...lastUsedLayout, [entityType]: layoutId } })
    );
    const layoutModel: LayoutModel<typeof entityType> = yield call(
      getLayoutModelByEntityType,
      entityType
    );
    layoutModel.setRecentLayoutId(layoutId);
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* onUpdateDefaultColumns({ payload }: ReturnType<typeof updateDefaultColumns>) {
  try {
    const { callback, defaultColumns } = payload;
    yield put(
      updateOrganizationMetadata.request({
        callback: () => {
          callback?.();
        },
        metaData: { defaultColumns },
      })
    );
    if (!defaultColumns) {
      return;
    }

    Object.keys(defaultColumns).forEach((entityType) => {
      const fieldModel = getFieldModelByEntityType(
        entityType as EntityTypesSupportedColumnSelection
      );
      if (!fieldModel) {
        loggingService.debug("Invalid entity type in default columns", entityType);
        return;
      }

      const defaultFields =
        defaultColumns[
          entityType as keyof Exclude<OrganizationMetaData["defaultColumns"], undefined>
        ]
          ?.map((platformFieldName) => fieldModel.getByPlatformName(platformFieldName))
          .filter(isDefined)
          .map((field) => field.name) ?? [];

      if (defaultFields.length) {
        fieldModel.fields.forEach((field) => {
          field.toggleFeature(
            FieldFeature.VISIBLE_BY_DEFAULT,
            field.hasFeature(FieldFeature.ALWAYS_VISIBLE) || defaultFields.includes(field.name)
          );
        });
      }
    });
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* iamSaga() {
  yield takeLatest(fetchMe.request, onFetchMe);
  yield takeLatest(findMe.request, onFindMe);
  yield takeLatest(getUserCountry.request, onGetUserCountry);
  yield takeLatest(changePassword.request, onChangePassword);
  yield takeLatest(uploadProfilePhoto.request, onUploadProfilePhoto);
  yield takeLatest(deleteProfilePhoto.request, onDeleteProfilePhoto);
  yield takeLatest(updateSetting.request, onUpdateSetting);
  yield takeLatest(updateMetadata.request, onUpdateMetadata);
  yield takeLatest(updateOrganizationMetadata.request, onUpdateOrganizationMetadata);
  yield takeLatest(addFavoriteFilter.request, onAddFavoriteFilter);
  yield takeLatest(togglePinnedDashboard.request, onTogglePinnedDashboard);
  yield takeLatest(setCumulPromotionalUiVisited.request, onSetCumulPromotionalUIVisited);
  yield takeLatest(removeFavoriteFilter.request, onRemoveFavoriteFilter);
  yield takeLatest(toggleFavoriteFunnel.request, onUpdateFavoriteFunnel);
  yield takeLatest(updateMe.request, onUpdateMe);
  yield takeLatest(updateOrganization.request, onUpdateOrganization);
  yield takeLatest(updateOrganizationSetting.request, onUpdateOrganizationSetting);
  yield takeLatest(fetchGeocodingLimit.request, onFetchGeocodingLimit);
  yield takeLatest(updateLastUsedLayout, onUpdateLastUsedLayout);
  yield takeLatest(updateDefaultColumns, onUpdateDefaultColumns);
}
