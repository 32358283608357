import { EntityType, EntityTypesSupportedByMapsPage } from "@mapmycustomers/shared/types/entity";
import MapFilterModel from "@mapmycustomers/shared/types/viewModel/internalModel/MapFilterModel";
import PlatformFilterModel from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";

import getFieldModelByEntityType from "../fieldModel/getByEntityType";
import universalFieldsFieldModel from "../fieldModel/universalFieldsFieldModel";

import { convertFromPlatformFilterModel } from "./convertFromPlatformFilterModel";

const isEntitiesMap = (
  entities: PlatformFilterModel["entities"]
): entities is Partial<Record<EntityTypesSupportedByMapsPage, PlatformFilterModel>> => {
  return typeof entities === "object" && !Array.isArray(entities) && !!entities;
};

const convertMapPlatformFilterToMapFilterModel = (filter: PlatformFilterModel): MapFilterModel => {
  const result: MapFilterModel = {};
  const { entities, ...restFilter } = filter;
  if (isEntitiesMap(entities)) {
    ([EntityType.COMPANY, EntityType.PERSON, EntityType.DEAL] as const).forEach((entityType) => {
      if (entities[entityType]) {
        result[entityType] = convertFromPlatformFilterModel(
          entities[entityType]!,
          getFieldModelByEntityType(entityType)
        );
      }
    });
  }

  if (restFilter && Object.keys(restFilter).length) {
    result.universal = convertFromPlatformFilterModel(restFilter, universalFieldsFieldModel);
  }

  return result;
};

export default convertMapPlatformFilterToMapFilterModel;
