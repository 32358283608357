import React from "react";
import { connect } from "react-redux";

import { defineMessage, useIntl } from "react-intl";
import { Redirect } from "react-router-dom";

import ApiError from "@mapmycustomers/shared/util/api/ApiError";

import Path from "@app/enum/Path";
import {
  getError,
  isForgotPasswordLoading,
  isForgotPasswordSuccess,
  isSignedIn,
} from "@app/store/auth";
import { forgotPassword } from "@app/store/auth/actions";
import { RootState } from "@app/store/rootReducer";
import getErrorMessage from "@app/util/errorHandling/getErrorMessage";

import AuthLayout from "../AuthLayout";

import ForgotPasswordForm from "./ForgotPasswordForm";
import ForgotPasswordSuccessScreen from "./ForgotPasswordSuccessScreen";

const genericErrorMessage = defineMessage({
  id: "auth.forgotPassword.error.unknown",
  defaultMessage: "Server issue, please try again later or contact support",
  description: "A generic forgot Password failed error message text",
});

interface Props {
  error?: ApiError;
  forgotPassword: typeof forgotPassword.request;
  isForgotPasswordSuccess: boolean;
  isLoading: boolean;
  isSignedIn: boolean | undefined;
}

export const ForgotPasswordPage: React.FC<Props> = ({
  error,
  forgotPassword,
  isForgotPasswordSuccess,
  isLoading,
  isSignedIn,
}) => {
  const intl = useIntl();
  // go to home to proceed when already signed in
  if (isSignedIn) {
    return <Redirect to={Path.DASHBOARD} />;
  }

  let message = undefined;
  if (error) {
    message = getErrorMessage(error, {}, genericErrorMessage);
    if (message.startsWith("Invalid grant")) {
      message = intl.formatMessage({
        id: "auth.forgotPassword.error.invalidCredentials",
        defaultMessage: "This username entered is incorrect, please try again",
        description: "An invalid credentials error message text",
      });
    } else {
      message = intl.formatMessage(genericErrorMessage);
    }
  }

  return (
    <AuthLayout>
      {isForgotPasswordSuccess ? (
        <ForgotPasswordSuccessScreen />
      ) : (
        <ForgotPasswordForm
          errorMessage={message}
          isLoading={isLoading}
          onResetPassword={forgotPassword}
        />
      )}
    </AuthLayout>
  );
};

const mapStateToProps = (state: RootState) => ({
  error: getError(state),
  isForgotPasswordSuccess: isForgotPasswordSuccess(state),
  isLoading: isForgotPasswordLoading(state),
  isSignedIn: isSignedIn(state),
});

const mapDispatchToProps = {
  forgotPassword: forgotPassword.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);
