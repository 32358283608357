import { createSelector } from "reselect";

import { RootState } from "@app/store/rootReducer";

const restorableRecordsState = (state: RootState) => state.scene.settings.restorableRecords;

export const getDeletedEntities = createSelector(
  restorableRecordsState,
  ({ entities }) => entities
);

export const isLoading = createSelector(restorableRecordsState, ({ loading }) => loading);
export const getTotalDeletedEntities = createSelector(restorableRecordsState, ({ total }) => total);
