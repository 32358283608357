import memoize from "fast-memoize";
import { createSelector } from "reselect";

import CumulAddOnServiceVariation from "@mapmycustomers/shared/enum/CumulAddOnServiceVariation";
import Feature from "@mapmycustomers/shared/enum/Feature";
import LeadFinderSubscriptionPlan, {
  LEAD_FINDER_ENABLED_PLANS,
} from "@mapmycustomers/shared/enum/LeadFinderSubscriptionPlan";
import BaseMapStyle from "@mapmycustomers/shared/enum/map/BaseMapStyle";
import MarkerIcon from "@mapmycustomers/shared/enum/MarkerIcon";
import MarkerSize from "@mapmycustomers/shared/enum/MarkerSize";
import PinLegendCreationVisibility from "@mapmycustomers/shared/enum/PinLegendCreationVisibility";
import RoleEnum from "@mapmycustomers/shared/enum/Role";
import Version from "@mapmycustomers/shared/enum/Version";
import { OrganizationMetaData } from "@mapmycustomers/shared/types/Organization";
import Role from "@mapmycustomers/shared/types/Role";
import Setting from "@mapmycustomers/shared/types/Setting";
import User from "@mapmycustomers/shared/types/User";

import localSettings from "@app/config/LocalSettings";
import CheckInVerifiedVisibility from "@app/enum/CheckInVerifiedVisibility";
import { CumulRole } from "@app/enum/CumulRole";
import OrganizationSetting from "@app/enum/OrganizationSetting";
import { getActivityTypes } from "@app/store/activity";
import { getUsers } from "@app/store/members";
import { RootState } from "@app/store/rootReducer";

import ImportRestrictionValue from "../../enum/ImportRestrictionValue";

import { isOwner as isOwnerPredicate } from "./util";

const iamState = (state: RootState) => state.iam;

export const isLoading = createSelector(iamState, ({ loading }) => loading);
export const getError = createSelector(iamState, ({ error }) => error);

export const getMe = createSelector(iamState, ({ me }) => me);

// returns only current user's details, without org info and other fields
export const getCurrentUser = createSelector(getMe, (me) => {
  if (!me) {
    return me;
  }
  const { accessRights, addOns, cumulRole, layouts, organization, settings, ...myUser } = me;
  return myUser;
});

export const getCurrentUserId = createSelector(getMe, (me) => me?.id);
export const getUserMetaData = createSelector(getMe, (me) => me?.metaData ?? {});

export const getRole = createSelector(getMe, (me) => me?.role);
export const isCurrentUserOwner = createSelector(getRole, (role) => role?.key === RoleEnum.OWNER);
export const isCurrentUserManager = createSelector(
  getRole,
  (role) => role?.key === RoleEnum.MANAGER
);
export const isCurrentUserMember = createSelector(getRole, (role) => role?.key === RoleEnum.MEMBER);
export const isCurrentUserReadOnly = createSelector(
  getRole,
  (role) => role?.key === RoleEnum.READ_ONLY
);

export const getUserSettings = createSelector(getMe, (me) => me?.settings);

export const getUserSetting = createSelector(getUserSettings, (settings) =>
  memoize((settingName: string): Setting | undefined =>
    settings?.find(({ key }) => key === settingName)
  )
);

export const getUserSettingValue = createSelector(getUserSettings, (settings) =>
  memoize(<T = any>(settingName: string, defaultValue?: T): T => {
    const setting = settings?.find(({ key }) => key === settingName);
    return setting ? setting.value : defaultValue;
  })
);

export const isSettingUpdating = createSelector(iamState, ({ settingUpdating }) => settingUpdating);

// collect map settings from Iam instance
// currently reads view settings from user metadata and userSettings (in this order)
export const getLegacyMapSettings = createSelector(getMe, getUserSettingValue, (me, getSetting) => {
  const metadata = me?.metaData;
  return {
    markerIcon: metadata?.markerIcon ?? getSetting("markerIcon") ?? MarkerIcon.PIN,
    markerSize: metadata?.markerSize ?? getSetting("markerSize") ?? MarkerSize.LARGE,
  };
});

// collect modern map settings from Iam instance
// currently reads view settings from user metadata and userSettings (in this order)
export const getMapSettings = createSelector(getMe, getUserSettingValue, (me, getSetting) => {
  const metadata = me?.metaData;
  return {
    markerSize: metadata?.markerSize ?? getSetting("markerSize") ?? MarkerSize.LARGE,
    showEntityIcons: metadata?.showEntityIcons ?? true,
    style: metadata?.mapSettings?.style ?? BaseMapStyle.STANDARD,
  };
});

export const getPinnedDashboardIds = createSelector(
  getMe,
  (me) => me?.metaData?.pinnedDashboardIds ?? []
);

export const getFavoriteFilters = createSelector(
  getUserMetaData,
  (metaData) => metaData.quickFilters ?? []
);

export const getHiddenTerritoriesIds = createSelector(
  getUserMetaData,
  (metaData) => metaData.hiddenTerritoriesIds ?? []
);
export const isCumulPromotionalUIVisited = createSelector(
  getUserMetaData,
  (metaData) => metaData.isCumulDemoVisited
);

export const getTeamDashboardAlertDismissedAt = createSelector(
  getUserMetaData,
  (metaData) => metaData.teamDashboardAlertDismissedAt
);

export const getOrganization = createSelector(getMe, (me) => me?.organization);

export const getOrganizationId = createSelector(
  getOrganization,
  (organization) => organization?.id
);
export const getFavoriteFunnels = createSelector(
  getOrganization,
  (organization) => organization?.metaData?.favoriteFunnels ?? []
);
export const getOrganizationName = createSelector(
  getOrganization,
  (organization) => organization?.name
);
export const getOrganizationSettings = createSelector(
  getOrganization,
  (organization) => organization?.settings
);
export const getOrganizationSetting = createSelector(getOrganizationSettings, (settings) =>
  memoize((settingName: string): Setting | undefined =>
    settings?.find(({ key }) => key === settingName)
  )
);
export const getOrganizationSettingValue = createSelector(getOrganizationSettings, (settings) =>
  memoize(<T = any>(settingName: string, defaultValue?: T): T => {
    const setting = settings?.find(({ key }) => key === settingName);
    return setting ? setting.value : defaultValue;
  })
);

export const getOrganizationOwnerId = createSelector(
  getOrganization,
  (organization) => organization?.owner.id
);

export const getOrganizationAddOnServices = createSelector(
  getOrganization,
  (organization) => organization?.addOnServices
);

export const doesOrganizationSupportDupeCheck = createSelector(
  getOrganizationAddOnServices,
  (addOnServices) => !!addOnServices?.dupeCheck
);

export const doesOrganizationSupportTerritories = createSelector(
  getOrganizationAddOnServices,
  (addOnServices) => !!addOnServices?.territories
);

export const doesOrganizationSupportHipaa = createSelector(
  getOrganizationAddOnServices,
  (addOnServices) => !!addOnServices?.hipaaEnabled
);

export const doesOrganizationSupportDemographicData = createSelector(
  getOrganizationAddOnServices,
  (addOnServices) => !!addOnServices?.demographicData
);

export const getLeadGenPlan = createSelector(
  getOrganizationAddOnServices,
  (addOnServices) => addOnServices?.leadGen
);

export const isActiveCheckInsEnabled = createSelector(
  getOrganizationAddOnServices,
  (addOnServices) => !!addOnServices?.activeCheckIn
);

export const isLeadFinderEnabled = createSelector(
  getLeadGenPlan,
  (plan) => !!plan && LEAD_FINDER_ENABLED_PLANS.includes(plan)
);

export const isLeadFinderBasic = createSelector(
  getLeadGenPlan,
  (plan) => plan === LeadFinderSubscriptionPlan.BASIC
);

export const isLeadFinderSaveEnabled = createSelector(
  getLeadGenPlan,
  (plan) => plan === LeadFinderSubscriptionPlan.PRO
);

export const isLeadFinderPro = createSelector(
  getLeadGenPlan,
  (plan) => plan === LeadFinderSubscriptionPlan.PRO
);

export const isSsoEnabled = createSelector(
  getOrganization,
  (organization) => !!organization?.domain
);

export const getPlan = createSelector(getOrganization, (organization) => organization?.plan);

export const getColors = createSelector(
  getOrganization,
  (organization) => organization?.plan.colors
);

export const getPosition = createSelector(iamState, ({ position }) => position);
export const getUserCountry = createSelector(iamState, ({ userCountry }) => userCountry);

//Change Password Selectors
export const isChangePasswordLoading = createSelector(
  iamState,
  ({ changePasswordLoading }) => changePasswordLoading
);
export const getChangePasswordError = createSelector(
  iamState,
  ({ changePasswordError }) => changePasswordError
);

export const isChangePasswordModalVisible = createSelector(
  iamState,
  ({ changePasswordModalVisible }) => changePasswordModalVisible
);
// Edit Profile Photo Selectors
export const isConfirmDeleteProfilePhotoModalVisible = createSelector(
  iamState,
  ({ confirmDeleteProfileModalVisible }) => confirmDeleteProfileModalVisible
);
export const isDeleteProfilePhotoLoading = createSelector(
  iamState,
  ({ deleteProfilePhotoLoading }) => deleteProfilePhotoLoading
);

export const isUploadProfilePhotoLoading = createSelector(
  iamState,
  ({ uploadProfilePhotoLoading }) => uploadProfilePhotoLoading
);
//onboarding

export const isFinishingOnboarding = createSelector(
  iamState,
  ({ finishOnboardingLoading }) => finishOnboardingLoading
);

export const isOnTrialPlan = createSelector(getOrganization, (org) => org?.plan.trialing);

export const getOrganizationMetaData = createSelector(
  getOrganization,
  (org) => org?.metaData ?? {}
);
// assuming all users are on v2-beta already, so we can use it as a default one even when webVersion is not
// explicitly set for user.
const DEFAULT_VERSION = Version.V2_BETA;

export const getVersion = createSelector(
  getOrganizationMetaData,
  (metaData) => metaData?.webVersion ?? DEFAULT_VERSION
);
const DEFAULT_FEATURES: OrganizationMetaData["features"] = {
  [Feature.DISALLOW_COLOR]: { enabled: false },
  [Feature.MAP]: { enabled: false },
};

export const getFeatures = createSelector(
  getOrganizationMetaData,
  (metaData) => metaData?.features ?? DEFAULT_FEATURES
);

export const getDefaultColumns = createSelector(
  getOrganizationMetaData,
  (metaData) => metaData?.defaultColumns ?? {}
);

export const getPinLegendCreationVisibility = createSelector(
  getOrganizationMetaData,
  (metaData) => metaData?.pinLegendsCreation ?? PinLegendCreationVisibility.PRIVATE
);

export const getDefaultColorKeys = createSelector(
  getOrganizationMetaData,
  (metaData) => metaData?.defaultColorKey
);

export const getDefaultShapeKeys = createSelector(
  getOrganizationMetaData,
  (metaData) => metaData?.defaultShapeKey
);

export const areCheckInFieldsVisible = createSelector(
  getRole,
  getOrganizationSettings,
  (role: Role | undefined, orgSettings: Setting[] | undefined) => {
    const checkInVisibility =
      orgSettings?.find(({ key }) => key === OrganizationSetting.CHECK_IN_VERIFIED_VISIBILITY)
        ?.value ?? CheckInVerifiedVisibility.OWNER;

    if (role?.key === RoleEnum.OWNER) {
      return true; // owners have access to everything
    }
    if (role?.key === RoleEnum.MANAGER) {
      // managers have access to everything too except when visibility is set to OWNER
      return checkInVisibility !== CheckInVerifiedVisibility.OWNER;
    }
    // for other users it is only accessible if visibility is set to public
    return checkInVisibility === CheckInVerifiedVisibility.PUBLIC;
  }
);
//Change Me Selectors
export const isMeUpdating = createSelector(iamState, ({ meUpdating }) => meUpdating);

export const isOrganizationUpdating = createSelector(
  iamState,
  ({ organizationUpdating }) => organizationUpdating
);
export const getGeocodeOrgLimit = createSelector(
  iamState,
  ({ geocodingOrgLimit }) => geocodingOrgLimit
);
export const isGeocodeOrgLimitReached = createSelector(
  iamState,
  ({ geocodingOrgLimitReached }) => geocodingOrgLimitReached
);

export const isGeocodeMmcLimitReached = createSelector(
  iamState,
  ({ geocodingMmcLimitReached }) => geocodingMmcLimitReached
);
export const isBigOrganization = createSelector(iamState, ({ bigOrganization }) => bigOrganization);
export const getOrganizationCumulSupport = createSelector(
  getOrganizationAddOnServices,
  (addOnServices) => addOnServices?.cumul
);
export const doesOrganizationSupportCumul = createSelector(
  getOrganizationCumulSupport,
  (cumul) =>
    (cumul === CumulAddOnServiceVariation.SKELETON_KEY_ONLY &&
      localSettings.wasSkeletonKeyUsed()) ||
    cumul === true
);

export const doesOrganizationSupportEmailService = createSelector(
  getOrganizationAddOnServices,
  (addOnServices) => !!addOnServices?.emailService
);

export const isEmailServiceSupported = createSelector(
  getMe,
  doesOrganizationSupportEmailService,
  getActivityTypes,
  (me, organizationSupport, activityTypes) =>
    !!me?.addOns.emailService &&
    organizationSupport &&
    activityTypes.some(({ active, name }) => active && name === "Email")
);

export const isCumulEnabledForLoggedInUser = createSelector(getMe, (me) => me?.addOns.cumul);

export const isCumulEnabled = createSelector(
  doesOrganizationSupportCumul,
  isCumulEnabledForLoggedInUser,
  (orgSupportsCumul, cumulEnabled) => cumulEnabled && orgSupportsCumul
);
// cumul role is entered manually in the db, correct casing is not always guaranteed, so we need
// to use .toLowerCase in order to ensure our comparison is correct

export const getCumulRole = createSelector(getMe, (me) => {
  if (
    !me?.cumulRole ||
    !Object.values(CumulRole).includes(me.cumulRole.toLowerCase() as CumulRole)
  ) {
    return;
  }
  return me.cumulRole.toLowerCase() as CumulRole;
});

export const getLastUsedLayout = createSelector(
  iamState,
  ({ me }) => me?.metaData?.lastUsedLayout ?? {}
);
export const getOrganizationOwnerIds = createSelector(
  getUsers,
  getOrganizationOwnerId,
  (users: User[], ownerId?: User["id"]) => [
    ...users.filter(isOwnerPredicate).map(({ id }) => id),
    ...(ownerId ? [ownerId] : []),
  ]
);

export const doesUserHaveAccessToDupeCheck = createSelector(
  isCurrentUserOwner,
  doesOrganizationSupportDupeCheck,
  (isOwner: boolean, dupeCheckSupported: boolean) => {
    return isOwner && dupeCheckSupported;
  }
);

export const doesUserHaveAccessToImports = createSelector(
  getRole,
  getOrganizationSettings,
  (role: Role | undefined, orgSettings: Setting[] | undefined) => {
    const importRestriction =
      orgSettings?.find(({ key }) => key === OrganizationSetting.IMPORT_ALLOWED)?.value ??
      ImportRestrictionValue.OWNER;

    if (role?.key === RoleEnum.OWNER) {
      return true;
    }
    if (role?.key === RoleEnum.MANAGER) {
      return importRestriction !== ImportRestrictionValue.OWNER;
    }
    return importRestriction === CheckInVerifiedVisibility.PUBLIC;
  }
);
