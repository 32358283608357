import { createReducer } from "typesafe-actions";

import { EntitySupportingRecordRestoration } from "@mapmycustomers/shared/types/entity";

import { Actions, fetchDeletedEntities, restoreRecords } from "./actions";

export interface RestorableRecordsState {
  entities?: EntitySupportingRecordRestoration[];
  loading: boolean;
  total: number;
}

const initialState: RestorableRecordsState = {
  loading: false,
  total: 0,
};

const restorableRecords = createReducer<RestorableRecordsState, Actions>(initialState)
  .handleAction(fetchDeletedEntities.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(fetchDeletedEntities.success, (state, { payload }) => ({
    ...state,
    entities: payload.entities,
    loading: false,
    total: payload.total,
  }))
  .handleAction(fetchDeletedEntities.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(restoreRecords.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(restoreRecords.success, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(restoreRecords.failure, (state) => ({
    ...state,
    loading: false,
  }));

export * from "./selectors";
export type RestorableRecordsActions = Actions;
export default restorableRecords;
