import type Dated from "@mapmycustomers/shared/types/base/Dated";

import { parseApiDate } from "./parseApiDate";

const parseDatedFields = <T extends Dated<string>>(
  item: T
): Omit<T, "createdAt" | "deletedAt" | "updatedAt"> & Dated<Date> => ({
  ...item,
  createdAt: parseApiDate(item.createdAt),
  deletedAt: item.deletedAt ? parseApiDate(item.deletedAt) : undefined,
  updatedAt: parseApiDate(item.updatedAt),
});

export default parseDatedFields;
